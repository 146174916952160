import * as React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  ThemeButton,
  yupEmail,
  NoDataAvailable,
  RenderTextField,
} from "@karkhanaui/react";
import { RiDeleteBin6Line } from "react-icons/ri";

interface sharedWithProps {
  name: string;
  email: string;
  img: string;
}
interface shareCardProps {
  sharedWith?: sharedWithProps[];
  duplicateUser: any;
  showAddUserConfirmation: Function;
  showDeleteUserConfirmation: Function;
}
export const ShareCard = ({
  sharedWith,
  duplicateUser,
  showAddUserConfirmation,
  showDeleteUserConfirmation,
}: shareCardProps) => {
  const basicValidation = Yup.object().shape({
    email: yupEmail,
  });

  const share = async (event: any) => {
    let index: any = sharedWith?.findIndex(
      (user: any) => user.email === event.email
    );
    if (index < 0) {
      showAddUserConfirmation(event.email);
    } else {
      duplicateUser();
    }
  };

  return (
    <Box mb="2.5rem">
      <Formik
        enableReinitialize={true}
        initialValues={{ email: "" }}
        validationSchema={basicValidation}
        onSubmit={share}
        validator={() => ({})}
      >
        {(formik: any) => {
          return (
            <Form>
              <Flex
                alignItems="flex-end"
                justifyContent="space-between"
                gap="9px"
              >
                <Box width="100%">
                  <RenderTextField
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="name@company.com"
                    isMandatory={true}
                  />
                </Box>
                <ThemeButton children="Share" type="submit" />
              </Flex>
            </Form>
          );
        }}
      </Formik>
      <Box>
        <Text textStyle="primary.text" mt="1.5rem">
          Shared with
        </Text>
        {sharedWith ? (
          <Box mt="1rem">
            {sharedWith?.map((each: any) => (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="8px"
                key={each.email}
              >
                <Flex justifyContent="flex-start" gap="0.75rem">
                  <Text textStyle="primary.text">{each.email}</Text>
                </Flex>
                <RiDeleteBin6Line
                  size="20px"
                  cursor="pointer"
                  onClick={() => {
                    showDeleteUserConfirmation(each.email);
                  }}
                />
              </Flex>
            ))}

            {sharedWith.length == 0 && <NoDataAvailable />}
          </Box>
        ) : (
          <Text textStyle="primary.secondaryText" mt="1rem" ml="1rem">
            Not yet shared
          </Text>
        )}
      </Box>
    </Box>
  );
};
