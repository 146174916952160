import React, { useEffect } from "react";
import { Box, useToast } from "@chakra-ui/react";

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ERROR_MSG,
  SUCCESS_MSG,
  INFO_MSG,
} from "@karkhanaui/react";

export const Toastr = (toastMsg: any) => {
  const toast = useToast();

  useEffect(() => {
    switch (toastMsg?.toastMsg.message?.case) {
      case LOGIN_FAIL:
        triggerToast(toastMsg.toastMsg.message.data, "error");
        break;
      case LOGIN_SUCCESS:
        triggerToast(toastMsg.toastMsg.message.data, "success");
        break;
      case REGISTER_SUCCESS:
        triggerToast(toastMsg.toastMsg.message.data, "success");
        break;
      case REGISTER_FAIL:
        triggerToast(toastMsg.toastMsg.message.data, "error");
        break;
      case ERROR_MSG:
        triggerToast(toastMsg.toastMsg.message.data, "error");
        break;
      case SUCCESS_MSG:
        triggerToast(toastMsg.toastMsg.message.data, "success");
        break;
      case INFO_MSG:
        triggerToast(toastMsg.toastMsg.message.data, "info");
        break;
    }
  }, [toastMsg]);

  const triggerToast = (message: string, status: any) => {
    toast.closeAll();
    toast({
      title: message,
      status: status,
      isClosable: true,
      position: "top-right",
    });
  };
  return <Box></Box>;
};
