import { Box, Text, Flex } from "@chakra-ui/react";
import * as React from "react";
import { IconType } from "react-icons";
import { RiErrorWarningFill } from "react-icons/ri";
import { FileProps, File } from "@karkhanaui/react";

export interface InfoProps {
  type: "info" | "success" | "danger";
  message: string;
  moreInfo?: string;
  Icon?: IconType;
  active: boolean;
  files?: FileProps[];
}

const types = {
  info: {
    textStyle: "primary.info",
    color: "hold.10",
    iconColor: "#FDB913",
  },
  success: {
    textStyle: "primary.success",
    color: "success.10",
    iconColor: "#50B848",
  },
  danger: {
    textStyle: "primary.danger",
    color: "danger.10",
    iconColor: "#EF6F6C",
  },
};

export const Info = ({
  type,
  message,
  moreInfo,
  Icon,
  active = false,
  files,
}: InfoProps) => {
  let border = "1px solid transparent";
  if (active) {
    border = "1px solid #0087EE";
  }
  return (
    <Box bg={types[type].color} p="10px" borderRadius="4px" border={border}>
      {message && (
        <Flex alignItems="center" gap=".3rem">
          <RiErrorWarningFill size="18px" color={types[type].iconColor} />
          <Text textStyle={types[type].textStyle} my="2px" fontWeight="bold">
            {message}
          </Text>
        </Flex>
      )}
      {moreInfo && <Text textStyle="primary.secondaryText">{moreInfo}</Text>}
      {files && (
        <Flex
          justifyContent="space-between"
          gap="10px"
          alignItems="center"
          mt="1.75rem"
          overflowX="scroll"
          flexWrap="wrap"
        >
          {files.map((each, index) => (
            <Box cursor="pointer" key={index} flex="1 1 0px">
              <File
                key={index}
                active={each.active}
                date={each.date}
                fileName={each.fileName}
                fileSize={each.fileSize}
                imgSrc={each.imgSrc}
                thumbnail={each.thumbnail}
                allowDownload={true}
              />
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  );
};
