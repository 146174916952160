import axios from "axios";

export const getManuProcess = (data) => {
  return axios.get("/api/resource/Manufacturing Process", data);
};

export const getFinishingCapability = (data) => {
  return axios.get("/api/resource/Finishing Capabilities", data);
};

export const getSupplierCapability = (userEmail: string) => {
  return axios.get(`/api/resource/Supplier/${userEmail}`);
};

export const createSupplierCapability = (data) => {
  return axios.post(`/api/resource/Supplier`, data.fields);
};

export const updateSupplierCapability = (data) => {
  return axios.put(`/api/resource/Supplier/${data.name}`, data.fields);
};

export const getManuSubProcess = (data) => {
  return axios.get("/api/resource/Manufacturing Sub Process", data);
};
export const getMaterialGrade = () => {
  return axios.get(
    "/api/method/supplier_capabilities.api.get_doc.material_grade"
  );
};

export const getTypeOfMachines = () => {
  return axios.get(
    "/api/method/supplier_capabilities.api.get_doc.machine_capabilities"
  );
};

export const getAvailableCertificates = (data) => {
  return axios.get("/api/resource/Available Certifications", data);
};

export const uploadCertificate = (data) => {
  return axios.post(
    "/api/method/supplier_capabilities.api.certificates.upload_certificate",
    data
  );
};

export const getUploadedCertificateList = (user) => {
  return axios.post(
    "/api/method/supplier_capabilities.api.certificates.read_certificates"
  );
};

export const deleteCertificate = (data) => {
  return axios.post(
    "/api/method/supplier_capabilities.api.certificates.delete_certificate",
    data
  );
};

export const UploadFilesToSupplierCapability = (data, onUploadProgress) => {
  return axios.post(
    "/api/method/supplier_capabilities.api.supplier_capabilities.upload_portfolio",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) =>
        onUploadProgress(ProgressEvent, data),
    }
  );
};
export const DeleteSupplierCapabilityPortfolio = (data) => {
  return axios.post("/api/method/supplierv2.api.utils.delete_doc", data);
};

export const GetSupplierCapabilityPortfolios = (data) => {
  return axios.post(
    "/api/method/supplier_capabilities.api.supplier_capabilities.read_portfolio",
    data
  );
};

export const deleteMachine = (id: string) => {
  return axios.post(
    "/api/method/supplier_capabilities.api.supplier_capabilities.delete_machine_capability",
    {
      capability_id: id,
      supplier: localStorage.getItem("user_email"),
    }
  );
};
