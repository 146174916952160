import axios from "axios";

export const getUserDetailsService = () => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/customer_portal_v2.api.customer.get_customerdetials"
  );
};

export const updateUserDetailsService = (data: any) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/customer_portal_v2.api.customer.update_customer",
    data
  );
};
