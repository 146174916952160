import * as React from "react";
import { Box, Image, Link, Flex, Center, VStack, HStack } from "@chakra-ui/react";
import { BsArrowRightCircle } from "react-icons/bs";
interface cardDetails {
  link: string;
  imgUrl: string;
  path: any;
}
export const Smallcards = ({ link, imgUrl, path }: cardDetails) => {
  return (
      <Flex flexDirection="column" width="100%" height="100%" alignItems="center">
        <Box width="50%" height="50%">
          <Image src={imgUrl} height="100%" width="100%" />
        </Box>

        <HStack>
          <Link
            href={path}
            gap="0.5rem"
            textStyle="primary.text"
            fontSize="16yarpx"
            color="#0087EE"
            fontWeight="bold"
            
          >
            {link}
          </Link>
          <BsArrowRightCircle color="#0087EE" />
        </HStack>
      </Flex>
  );
};
