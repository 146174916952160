import * as React from "react";
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { ThemeButton } from "@karkhanaui/react";

interface confirmShareProps {
  onCloseFunction: Function;
  onConfirmFunction: Function;
  shareButtonLoading: boolean;
}
export const ConfirmShare = ({
  onCloseFunction,
  onConfirmFunction,
  shareButtonLoading,
}: confirmShareProps) => {
  return (
    <Box>
      <Text>
        On confirming, the entered user will be able to view all the current and
        upcoming activities of this RFQ/Order.
        <br></br>
        <br></br>
        This can be cancelled at any stage by deleting that user email id from
        the share button.
      </Text>
      <Flex float="right" gap="1.5rem" mt="2rem">
        <Button onClick={() => onCloseFunction()}>Cancel</Button>
        <ThemeButton
          isLoading={shareButtonLoading}
          bg="#0087ee"
          color="#fff"
          children="Confirm"
          onClick={() => {
            onConfirmFunction();
          }}
        />
      </Flex>
    </Box>
  );
};
