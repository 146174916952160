import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
  } from "@chakra-ui/react";
  import React from "react";
  
  export const BasicAccordion: React.FC<any> = (props) => {
    return (
      <Accordion
        data-container={props.title?.replaceAll(" ", "")}
        defaultIndex={props.openIndex}
        allowMultiple
        borderRadius={10}
        bg="#FFFFFF"
        mb={2}
      >
        <AccordionItem
          sx={{
            border: "0px solid lightgray",
            borderRadius: "4px",
            borderBottom: "none",
            boxShadow: "0px 4px 35px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box as="h2">
            <AccordionButton
              _hover={{ bg: "#E2F1EE" }}
              _expanded={{
                bg: "#E2F1EE",
                color: "black",
                fontWeight: "bold",
                borderRadius: "4px",
              }}
              _focus={{ boxShadow: "none" }}
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                color="#4BA18A"
                fontWeight="600"
              >
                {props.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Box>
          <AccordionPanel p={0}>
            <Box p={4}>{props.content}</Box>
            {props.footer}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };
  
  // export default BasicAccordion;
  