
export const checkDuplicateAndReturnFiles = (
	files: Array<any>,
	newFiles: Array<any>,
	loader: boolean = false
  ) => {
	// get duplicate files based on file name
	const duplicateCheck: any = getFileDuplicate(files, newFiles);
	const defaultFile = {
	  loader: loader,
	  progress: 0,
	  uploadingMessage: "Uploading...",
	};
	let duplicateData = { open: false, title: "", content: "" };
	let isThereDuplicate: boolean = false;
	if (duplicateCheck.length) {
	  isThereDuplicate = true;
	  duplicateData = {
		open: true,
		title: "Duplicate file exists",
		content: 'File "' + duplicateCheck.join(",") + '" already exists!',
	  };
	}
	let uniqueFiles = newFiles.filter((el) => !duplicateCheck.includes(el.name));
	//  new files with loader, progress, upload msg as default
	let uploadedFiles = uniqueFiles.map((file: any) => {
	  Object.keys(defaultFile).forEach((key) => {
		file[key] = defaultFile[key];
	  });
	  return file;
	});
	return {
	  isThereDuplicate,
	  duplicateData,
	  files: [...files, ...uploadedFiles],
	  uniqueFiles,
	};
  };
  