export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const API_LOADING = "API_LOADING";
export const INFO_MSG = "INFO_MSG";
export const ERROR_MSG = "ERROR_MSG";
export const SUCCESS_MSG = "SUCCESS_MSG";
export const PAGE_LOADING = "PAGE_LOADING";
export const RFQ_FILE_UPLOAD_BEGIN = "RFQ_FILE_UPLOAD_BEGIN";
export const RFQ_FILE_UPLOAD_SUCCESS = "RFQ_FILE_UPLOAD_SUCCESS";
export const RFQ_FILE_UPLOAD_FAILED = "RFQ_FILE_UPLOAD_FAILED";
export const RFQ_CREATION_BEGIN = "RFQ_CREATION_BEGIN";
export const RFQ_CREATION_SUCCESS = "RFQ_CREATION_SUCCESS";
export const RFQ_CREATION_FAILED = "RFQ_CREATION_FAILED";
export const RFQ_FETCH_BEGIN = "RFQ_FETCH_BEGIN";
export const RFQ_FETCH_SUCCESS = "RFQ_FETCH_SUCCESS";
export const RFQ_FETCH_FAILED = "RFQ_FETCH_FAILED";
export const CUSTOMER_FETCH_BEGIN = "CUSTOMER_FETCH_BEGIN";
export const CUSTOMER_FETCH_SUCCESS = "CUSTOMER_FETCH_SUCCESS";
export const CUSTOMER_FETCH_FAILED = "CUSTOMER_FETCH_FAILED";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const QUOTE_FETCH_BEGIN = "QUOTE_FETCH_BEGIN";
export const QUOTE_FETCH_SUCCESS = "QUOTE_FETCH_SUCCESS";
export const QUOTE_FETCH_FAILED = "QUOTE_FETCH_FAILED";
export const UPDATE_SIGNUP_STATE = "UPDATE_SIGNUP_STATE";
export const UPDATE_ONBOARD_STATUS = "UPDATE_ONBOARD_STATUS";
export const SET_DASHBOARD_COUNT = "SET_DASHBOARD_COUNT";
export const SET_OPEN_RFQS = "SET_OPEN_RFQS";
export const SET_PROFILE_PERCENT = "SET_PROFILE_PERCENT";
export const USERID_FETCH_SUCCESS = "USERID_FETCH_SUCCESS";
