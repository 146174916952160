import axios from "axios";

export const childRowRfqService = async (
  doctype: string,
  docname: string,
  childTable: { childField: string; key: string; data: any }[]
) => {
  const doc = (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL +
        `/api/resource/${doctype}/${docname}`
    )
  ).data.data;

  for (let childRow of childTable) {
    const _childTable = doc[childRow.childField];

    doc[childRow.childField] = _childTable.map((each) => {
      if (each.item_code == childRow.key) {
        return {
          ...each,
          ...childRow.data,
        };
      }
      return each;
    });
  }

  await axios.put(
    process.env.REACT_APP_AUTH_LIVE_URL + `/api/resource/${doctype}/${docname}`,
    doc
  );
};
