import { Box, Flex, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { BsDownload } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";

// import capabilityService from "../services/capability.service";
import { DeletePrompt ,deleteCertificate} from "@karkhanaui/react"

export const CertificateFileCard = ({
  url,
  certificate_name,
  file_doc,
  setCertificateList,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  function deleteFile() {
    
      deleteCertificate({
        supplier: localStorage.getItem("user_email"),
        certificate_name: certificate_name,
      })
      .then(() =>
        setCertificateList((prev) =>
          prev?.filter((cert) => cert?.certificate_name !== certificate_name)
        )
      )
      .catch((e) => console.log(e));
  }

  return (
    <Box
      p={2}
      border="1px solid rgba(19, 27, 24, 0.1)"
      borderRadius="4px"
      display="flex"
      flexDirection="column"
      bg={"rgb(237, 247, 237)"}
      mb={2}
      minW="150px"
      maxW={"12rem"}
    >
      <Flex justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box>
            <Tooltip label={certificate_name}>
              <Text fontSize="sm" w={"max-content"}>
                {certificate_name?.slice(0, 20)}
              </Text>
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" ml={3} gap={1}>
          <BsDownload
            size={20}
            cursor="pointer"
            onClick={() => {
              window.open(process.env.REACT_APP_AUTH_LIVE_URL + url);
            }}
          />
          <MdDeleteOutline size={20} cursor="pointer" onClick={onOpen} />
        </Box>
      </Flex>
      <DeletePrompt
        onClose={onClose}
        isOpen={isOpen}
        deleteFn={deleteFile}
        deleteText={certificate_name + " certification"}
      />
    </Box>
  );
};

// export default CertificateFileCard;
