import axios from "axios";

export const writeToUsService = (data: any) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Write to us",
    data
  );
};

export const getAllWriteToUsService = async (params: any) => {
  return (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL +
        "/api/resource/Write to us?order_by=modified%20desc",
      {
        params: {
          ...(params.filters && { filters: JSON.stringify(params.filters) }),
          fields: JSON.stringify(params.fields)
            ? JSON.stringify(params.fields)
            : '"*"',
          ...(params.limit_start && {
            limit_start: JSON.stringify(params.limit_start),
          }),
          ...(params.order_by && {
            order_by: JSON.stringify(params.order_by),
          }),
        },
      }
    )
  );
};

export const getWriteToUsService = (query: string) => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Write to us/" + query
  );
};
