import axios from "axios";

export const documentCountService = async (data: any) => {
  return await (
    await axios.post(
      process.env.REACT_APP_AUTH_LIVE_URL +
        "/api/method/frappe.desk.reportview.get_count",
      data
    )
  );
};
