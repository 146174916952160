import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";
import { datadogRum } from "@datadog/browser-rum";

export const formatErrorMessage = (error: any) => {
  if (process.env.NODE_ENV === "production") {
    Sentry.configureScope((scope) => {
      scope.setLevel(Sentry?.Severity?.Error);
      // scope.setLevel("fatal");
      scope.setUser({
        email: String(localStorage.getItem("user_email")),
        username: String(Cookies.get("full_name")),
      });
      scope.setTag("error type", "API error");
      Sentry.captureException(error.response);
    });

    datadogRum.addError({
      message: "An error occurred while processing the user request",
      source: "UserRequestHandler",
      stack: error,
      custom: {
        userId: Cookies.get("full_name") || "Guest User",
        userEmail: Cookies.get("user_id") || "Guest Email",
      },
    });
  }

  if (
    error.response &&
    error.response.data &&
    error.response.data._server_messages
  ) {
    let errordata = JSON.parse(error.response.data._server_messages);
    let errorMessage = JSON.parse(errordata);
    return errorMessage.message;
  }

  if (error.response && error.response?.data?.message) {
    return error.response.data.message;
  }

  if (error.data && error.data._server_messages) {
    let errordata = JSON.parse(error.data._server_messages);
    let errorMessage = JSON.parse(errordata);
    return errorMessage.message;
  }

  if (error.message) {
    return error.message;
  }

  return error.toString();
};
