import axios from "axios";

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

export const authRegisterService = (data: any) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/customer_portal_v2.api.auth.signup",
    data
  );
};

export const authLoginService = (username: string, password: string) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/method/login",
    JSON.stringify({ usr: username, pwd: password }),
    config
  );
};

export const authLogoutService = () => {
  return axios
    .post(process.env.REACT_APP_AUTH_LIVE_URL + "/api/method/logout")
    .then((response) => {
      return response.data;
    });
};

export const getIndustryList = () => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/customer_portal_v2.api.auth.get_industry_type"
  );
};

export const getKnowAboutUsList = () => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/customer_portal_v2.api.auth.get_know_us"
  );
};

export const resetPasswordRequestService = (emailId: string) => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      `/api/method/customer_portal_v2.api.auth.reset_password?email=${emailId}`
  );
};

export const resetPassword = (pwd: string, key: string) => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      `/api/method/customer_portal_v2.api.auth.set_new_password?reset_key=${key}&new_password=${pwd}`
  );
};
