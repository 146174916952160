import React, { useState, useEffect } from "react";
import { Box, Text, Flex, Image, Grid, GridItem } from "@chakra-ui/react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  getFileType,
  File,
  normalDate,
  ThreedViewer,
  Thumbnail,
} from "@karkhanaui/react";
import { MdFullscreen } from "react-icons/md";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import * as PDFJS from "pdfjs-dist";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export interface filesExplorerProps {
  files: any[];
  allowDownload: boolean;
  allowDelete: boolean;
  environmentVariable: string;
  deleteAttachedFile?: Function;
}
export const FilesExplorer = ({
  files,
  allowDownload,
  allowDelete,
  environmentVariable,
  deleteAttachedFile,
}: filesExplorerProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [activeFile, setActiveFile] = useState<number>(0);
  const [currentFile, setCurrentFile] = useState<string>("");
  const handle = useFullScreenHandle();
  const [filesList, setFilesList] = useState<any>([]);
  const todaysData = new Date();

  const transformToDocs = (data: any) => {
    return data?.map((each: any) => ({
      fileId: each.name,
      active: true,
      date: normalDate(each.creation || todaysData),
      fileName: each.file_name,
      fileSize: `${Math.round(each.file_size || each.size / 1024)}KB`,
      imgSrc: environmentVariable + each.file_url,
      allowDownload: allowDownload,
      thumbnail: (
        <Thumbnail
          fileUrl={environmentVariable + (each.file_url || each.name)}
          isPrivateFile={true}
        />
      ),
      isPrivateFile: each.isPrivateFile,
    }));
  };

  const setData = async (filesData: any) => {
    const docs = await transformToDocs(filesData);
    setFilesList(docs);
  };

  useEffect(() => {
    if (files) {
      setData(files);
    }
  }, [files]);

  const displayFile = (file: any, isPrivate: boolean) => {
    switch (getFileType(file)) {
      case "image":
        return isPrivate ? (
          <Flex alignItems="center" justifyContent="center">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load image.
            </Text>
          </Flex>
        ) : (
          <Image
            src={file}
            alt={file.split("/").pop()}
            width="100%"
            height={handle.active ? "100%" : "50vh"}
            borderRadius=".25rem"
          />
        );
      case "pdf":
        return isPrivate ? (
          <Flex alignItems="center" justifyContent="center" p="1em">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load file.
            </Text>
          </Flex>
        ) : (
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        );
      case "CAD_File":
        return <ThreedViewer modelURLs={[file]} externalLibLocation="/libs" />;
      default:
        return (
          <Flex alignItems="center" justifyContent="center" p="1em">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load file.
            </Text>
          </Flex>
        );
        break;
    }
  };

  const deleteFile = async () => {
    const res: number = await deleteAttachedFile(currentFile);
  };

  return (
    <Box>
      <Grid
        templateColumns={{
          md: "repeat(2,1fr)",
          lg: "repeat(3,minmax(0, 1fr))",
        }}
        gap={{ xs: "2rem", md: "0.5rem" }}
      >
        <GridItem colSpan={{ lg: 2 }}>
          <Box
            objectFit="contain"
            width="100%"
            height={handle.active ? "100%" : "50vh"}
            bg="#D9DBE0"
            borderRadius=".75rem"
          >
            {filesList?.length && (
              <Box position="relative">
                <FullScreen handle={handle}>
                  <Box
                    width="100%"
                    height={handle.active ? "100%" : "50vh"}
                    overflowY="scroll"
                  >
                    {displayFile(
                      filesList[activeFile]?.imgSrc,
                      filesList[activeFile]?.isPrivateFile
                    )}
                  </Box>
                </FullScreen>

                <Box
                  borderRadius="6px"
                  bg="#8BC8B8"
                  right="0.5rem"
                  bottom="0.4rem"
                  position="absolute"
                  w="50px"
                  h="50px"
                  cursor="pointer"
                  onClick={handle.enter}
                >
                  <MdFullscreen size="50px" />
                </Box>
              </Box>
            )}
          </Box>
        </GridItem>
        <GridItem pl={{ md: "1.5rem" }}>
          <Box maxH="55vh" overflowY="scroll">
            {filesList?.map((file: any, index: number) => (
              <Box
                key={index}
                mt="1em"
                mb=".75rem"
                cursor="pointer"
                onClick={() => {
                  setCurrentFile(file.fileId);
                  setActiveFile(index);
                }}
              >
                <File
                  active={index === activeFile ? true : false}
                  date={file.date}
                  fileName={file.fileName}
                  fileSize={file.fileSize}
                  imgSrc={file.imgSrc}
                  allowDownload={allowDownload}
                  thumbnail={file.thumbnail}
                  allowDelete={allowDelete}
                  deleteFile={deleteFile}
                  colorTheme="green"
                />
              </Box>
            ))}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};
