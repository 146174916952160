import { chakra } from "@chakra-ui/react";
import { ReactElement } from "react";

export const IconBtn = ({
  children,
  ariaLabel,
  className = "",
  ...rest
}: {
  children: ReactElement | (ReactElement | null)[];
  ariaLabel: string;
  [x: string]: any;
}) => {
  return (
    <chakra.button
      {...rest}
      aria-label={ariaLabel}
      className={`icon-btn ${className}`}
    >
      {children}
    </chakra.button>
  );
};
