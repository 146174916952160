import * as React from "react";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
import { DarkButton, normalDate } from "@karkhanaui/react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";

interface ItemCardProps {
  itemName: string;
  lastUpdated: string;
  isOrdered: boolean;
  selectItem: any;
  image: string;
}

export const ItemCard = ({
  itemName,
  lastUpdated,
  isOrdered = false,
  selectItem,
  image,
}: ItemCardProps) => {
  return (
    <Flex
      w="21.875rem"
      h="9.375rem"
      bg="#fff"
      borderRadius="6px"
      boxShadow="0px 2px 35px rgba(0, 0, 0, 0.05)"
      padding="10px"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={image}
        width="7.5rem"
        height="7.5rem"
        borderRadius="6px"
        mr="15px"
        flexShrink="0"
      />
      <Box flexGrow="1" width="100%" overflow="hidden">
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <Text
              textStyle="primary.bold"
              noOfLines={1}
              w="100%"
              wordBreak="break-all"
              textOverflow="ellipsis"
            >
              {itemName}
            </Text>
            <Text textStyle="primary.text">
              Update Date: {normalDate(new Date(lastUpdated))}
            </Text>
          </Box>
          <BsThreeDotsVertical cursor="pointer" />
        </Flex>
        {isOrdered ? (
          <DarkButton
            w="100%"
            mt="20px"
            rightIcon={<AiOutlineCheck />}
            onClick={selectItem}
          >
            Item Selected
          </DarkButton>
        ) : (
          <DarkButton w="100%" mt="20px" variant="outline" onClick={selectItem}>
            Order Item
          </DarkButton>
        )}
      </Box>
    </Flex>
  );
};
