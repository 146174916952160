import axios from "axios";

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

export const authRegisterServiceProcurement = (data: any) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/procurement.api.auth.signup",
    data
  );
};

export const authLoginServiceProcurement = (
  username: string,
  password: string
) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/method/login",
    JSON.stringify({ usr: username, pwd: password }),
    config
  );
};

export const authLogoutServiceProcurement = () => {
  return axios
    .post(process.env.REACT_APP_AUTH_LIVE_URL + "/api/method/logout")
    .then((response) => {
      return response.data;
    });
};

export const resetPasswordRequestProcurement = (emailId: string) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
    `/api/method/procurement.api.auth.reset_password`,
    {
      "payload":{
          "email": emailId
      }
    }
  );
};

// export const resetPasswordProcurement = (pwd: string, key: string) => {
//   return axios.get(
//     process.env.REACT_APP_AUTH_LIVE_URL +
//       `/update-password?reset_key=${key}&new_password=${pwd}`
//   );
// };

export const resetPasswordProcurement = (pwd: string, key: string) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
    '/api/method/procurement.api.auth.set_new_password',
    {
      "payload":{
        "reset_key": key,
        "new_password": pwd
    }
    }
  );
};


export const getIndustryListProcurement = () => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/procurement.api.auth.get_industry_type"
  );
};

export const getKnowAboutUsListProcurement = () => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/procurement.api.auth.get_know_us"
  );
};
