import axios from 'axios';


// Function to fetch dashboard count
export const fetchDashboardCount = async (emailId: string) => {
  	return await axios.get(process.env.REACT_APP_AUTH_LIVE_URL+ 
		`/api/method/supplierv2.api.dashboard.dashboard_count?email_id=${emailId}`
		);
  };

  
// Function to fetch open RFQs
export const fetchOpenRFQs = async (emailId: any) => {
	return await axios.get(process.env.REACT_APP_AUTH_LIVE_URL+ 
		`/api/method/supplierv2.api.dashboard.get_open_rfq?email_id=${emailId}`
		);	
  };

// Function to fetch profile percentage
export const fetchProfilePercent = async (emailId: any) => {
	return await axios.get(process.env.REACT_APP_AUTH_LIVE_URL+
		 `/api/method/supplierv2.api.dashboard.get_profile_percent?email_id=${emailId}`
	); 
  };
