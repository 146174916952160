//This component is for the comment section in track order page's order update and order document tabs

import * as React from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  Textarea,
  Button,
  Spacer,
} from "@chakra-ui/react";
import { ThemeButton } from "@karkhanaui/react";
import { FaRegCommentDots } from "react-icons/fa";
import { RiAttachment2 } from "react-icons/ri";

interface eachComment {
  name: string;
  imgSrc?: string;
  time: string;
  comment: string;
}
interface commentsProps {
  commentList?: eachComment[];
  addComment?: any;
}
export const OrderComments = ({ commentList, addComment }: commentsProps) => {
  const [newComment, setNewComment] = React.useState<string>("");
  const [commentValidation, setCommentValidation] =
    React.useState<boolean>(false);
  return (
    <Box padding="1em">
      <Textarea
        placeholder="Add a comment..."
        isInvalid={commentValidation ? true : false}
        bg="#F3F6F9"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      />
      <Flex justifyContent="space-between" mt="1rem" mb="1.5rem">
        <Button variant="link" colorScheme="blue">
          <RiAttachment2 />
          &nbsp; Add Attachments
        </Button>
        <ThemeButton
          children="Comment"
          onClick={() => {
            if (newComment !== "") {
              addComment(newComment);
              setNewComment("");
              setCommentValidation(false);
            } else {
              setCommentValidation(true);
            }
          }}
        />
      </Flex>
      {commentList && commentList?.length >= 1 ? (
        <Box maxHeight="60vh" overflowY="scroll">
          <Text fontSize="xl" mb="1.5rem">
            All Comments
          </Text>
          {commentList?.map((item: any, index: number) => (
            <Flex gap="1rem" key={index}>
              <Flex flexDirection="column" alignItems="center">
                <Image
                  src={item.imgSrc}
                  alt="img"
                  width="2rem"
                  height="2rem"
                  border="1px solid black"
                  borderRadius="50%"
                />
                <Box
                  width="1px"
                  height="100%"
                  minHeight="3em"
                  bgColor="black"
                ></Box>
              </Flex>
              <Box
                padding="0"
                maxWidth="90%"
                minWidth="70%"
                ml="0px"
                borderRadius="0.8em"
              >
                <Box
                  bgColor="rgba(0, 135, 238, 0.1)"
                  padding="1em"
                  borderRadius="0.8em"
                >
                  <Flex>
                    <Text
                      textStyle="primary.contentTitle"
                      display="flex"
                      alignItems="flex-end"
                      gap="1rem"
                    >
                      {item.name}
                    </Text>
                    <Spacer />
                    <Text textStyle="primary.secondaryText">
                      {item.time} hours ago
                    </Text>
                  </Flex>
                  <Text mt=".75rem" textStyle="primary.paragraph">
                    {item.comment}
                  </Text>
                </Box>
                <Box width="100%" height="1em" bgColor="white"></Box>
              </Box>
            </Flex>
          ))}
        </Box>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          gap="1.5rem"
          flexDirection="column"
          margin="5rem"
        >
          <FaRegCommentDots color="rgba(0, 135, 238, 0.2)" size="6rem" />
          <Text textStyle="primary.largeSecondaryText">No comments added</Text>
        </Flex>
      )}
    </Box>
  );
};
