import axios from "axios";

export const getFiles = async (params: any) => {
  return await (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/File",
      {
        params: {
          ...(params.filters && { filters: JSON.stringify(params.filters) }),
          fields: params.fields ? JSON.stringify(params.fields) : '"*"',
        },
      }
    )
  );
};

export const uploadRegularFileService = async (params: any) => {
  const data = new FormData();

  for (var key in params) {
    data.append(key, params[key]);
  }

  return (
    await axios.post(
      process.env.REACT_APP_AUTH_LIVE_URL + "/api/method/upload_file",
      data
    )
  );
};
