export const base64Encoder = (str: string) => {
  // Convert string to a Uint8Array using TextEncoder
  const utf8Bytes = new TextEncoder().encode(str);

  // Convert the byte array to a base64 string
  const base64String = btoa(String.fromCharCode(...utf8Bytes));

  return base64String;
};

export const base64Decoder = (base64Str: string) => {
  // Decode the base64 string back to binary data
  const binaryStr = atob(base64Str);

  // Convert binary string to a Uint8Array
  const utf8Bytes = new Uint8Array(
    [...binaryStr].map((char) => char.charCodeAt(0))
  );

  // Decode the byte array back to a string using TextDecoder
  const decodedStr = new TextDecoder().decode(utf8Bytes);

  return decodedStr;
};
