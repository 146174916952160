import * as React from "react";
import {
  Box,
  Text,
  Flex,
  Center,
  Button,
  Image,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { RiFileUploadFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import {
  FaFilePdf,
  FaFileExcel,
  FaFileWord,
  FaFileImage,
} from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import {
  AppDispatch,
  getFileType,
  File,
  normalDate,
  get_doc,
  SET_MESSAGE,
  ERROR_MSG,
  formatErrorMessage,
  getFiles,
  ThreedViewer,
  Dxfviewer,
} from "@karkhanaui/react";
import { MdFullscreen } from "react-icons/md";
import { BsGear } from "react-icons/bs";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import * as PDFJS from "pdfjs-dist";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";


const fonts = ["/libs/fonts/Roboto-LightItalic.ttf", "/libs/fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf", "/libs/fonts/HanaMinA.ttf", "/libs/fonts/NanumGothic-Regular.ttf"];

export const FileViewCard = (props: any) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [activeFile, setActiveFile] = React.useState<number>(0);
  const [currentFile, setCurrentFile] = React.useState<string>("");
  const handle = useFullScreenHandle();
  const [filesList, setFilesList] = React.useState<any>([]);
  const dispatch = useDispatch<AppDispatch>();

  const onDrop = React.useCallback(
    async (acceptedFiles: any) => {
      let res: number = await props.addNewFiles(acceptedFiles);
      if (res === 200) {
        fetchData();
      }
      // setFiles(acceptedFiles);
    },
    [setFiles]
  );
  const { open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: true,
  });

  const getThumbnail = (fileUrl: string, isPrivateFile: boolean) => {
    switch (getFileType(fileUrl)) {
      case "image":
        if (isPrivateFile) {
          return <FaFileImage opacity="0.2" size="52px" />;
        } else {
          return (
            <Image src={fileUrl} w="52px" height="52px" borderRadius="6px" />
          );
        }
      case "pdf":
        return <FaFilePdf opacity="0.2" size="52px" />;
      case "spreadSheet":
        return <FaFileExcel opacity="0.2" size="52px" />;
      case "word":
        return <FaFileWord opacity="0.2" size="52px" />;
      case "CAD_File":
        return <BsGear opacity="0.2" size="52px" />;
      default:
      // return <Image src={alternativeImg} w="52px" />;
    }
  };

  const transformToDocs = (data: any) => {
    return data?.map((each: any) => ({
      fileId: each.name,
      active: true,
      date: normalDate(each.creation),
      fileName: each.file_name,
      fileSize: `${Math.round(each.file_size / 1024)}KB`,
      imgSrc: props.environmentVariable + each.file_url,
      allowDownload: true,
      thumbnail: getThumbnail(
        props.environmentVariable + each.file_url,
        each.is_private
      ),
      isPrivateFile: each.is_private,
    }));
  };

  const fetchFiles = async () => {
    try {
      const files: any = await get_doc({
        doc: "File",
        fields: ["*"],
        limit: 0,
        filters: [
          ["attached_to_doctype", "=", props.documentName || "Item"],
          ["attached_to_name", "=", props.item_id],
        ],
      });
      if ((files.status = 200)) {
        return files.data.data;
      }
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          case: ERROR_MSG,
          data: formatErrorMessage(error),
        },
      });
    }
  };

  const fetchData = () => {
    fetchFiles().then((res: any) => {
      if (res) {
        const docs = transformToDocs(res);
        setFilesList(docs);
      }
    });
  };

  React.useEffect(() => {
    if (props.item_id) {
      fetchData();
    }
  }, [props.item_id]);

  const displayFile = (file: any, isPrivate: boolean) => {
    switch (getFileType(file)) {
      case "image":
        return isPrivate ? (
          <Flex alignItems="center" justifyContent="center">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load image.
            </Text>
          </Flex>
        ) : (
          <Image
            src={file}
            alt={file.split("/").pop()}
            width="100%"
            height={handle.active ? "100%" : "50vh"}
            borderRadius=".25rem"
          />
        );
      case "pdf":
        return isPrivate ? (
          <Flex alignItems="center" justifyContent="center" p="1em">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load file.
            </Text>
          </Flex>
        ) : (
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        );

      case "DXF_FILE":
        return <Dxfviewer dxfUrl={file} fonts={fonts} />;

      case "CAD_File":
        return <ThreedViewer modelURLs={[file]} externalLibLocation="/libs" />;
      default:
        return (
          <Flex alignItems="center" justifyContent="center" p="1em">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load file.
            </Text>
          </Flex>
        );
        break;
    }
  };

  const deleteFile = async () => {
    const res: number = await props.deleteAttachedFile(currentFile);
    if (res === 200) {
      fetchData();
    }
  };

  return (
    <Box>
      <Grid
        templateColumns={{
          md: "repeat(2,1fr)",
          lg: "repeat(3,minmax(0, 1fr))",
        }}
        gap={{ xs: "2rem", md: "0.5rem" }}
      >
        <GridItem colSpan={{ lg: 2 }}>
          <Box
            objectFit="contain"
            width="100%"
            height={handle.active ? "100%" : "50vh"}
            bg="#D9DBE0"
            borderRadius=".75rem"
          >
            {filesList?.length && (
              <Box position="relative">
                <FullScreen handle={handle}>
                  <Box
                    width="100%"
                    height={handle.active ? "100%" : "50vh"}
                    overflowY="scroll"
                  >
                    {displayFile(
                      filesList[activeFile]?.imgSrc,
                      filesList[activeFile]?.isPrivateFile
                    )}
                  </Box>
                </FullScreen>

                <Box
                  borderRadius="6px"
                  bg="rgba(0, 135, 238, 0.5)"
                  right="0.5rem"
                  bottom="0.4rem"
                  position="absolute"
                  w="50px"
                  h="50px"
                  cursor="pointer"
                  onClick={handle.enter}
                >
                  <MdFullscreen size="50px" />
                </Box>
              </Box>
            )}
          </Box>
        </GridItem>
        <GridItem pl={{ md: "1.5rem" }}>
          <Box maxH="55vh" overflowY="scroll">
            <Box
              display="flex"
              flexDir={"column"}
              justifyContent={"space-between"}
              gap="1em"
              mb="1rem"
            >
              {props.isStepT ? null : (
                <Button
                  bg="rgba(0, 135, 238, 0.1)"
                  border="1px dashed #0087ee"
                  borderRadius="md"
                  // width="247px"
                  maxWidth="20rem"
                  onClick={open}
                >
                  <Center>
                    <RiFileUploadFill color="#0087ee" size={20} />
                    <Text
                      textStyle="primary.header"
                      fontSize="16px"
                      color="#0087ee"
                    >
                      Upload
                    </Text>
                  </Center>
                </Button>
              )}
            </Box>

            {filesList?.map((file: any, index: number) => (
              <Box
                key={index}
                mt="1em"
                mb=".75rem"
                cursor="pointer"
                onClick={() => {
                  setCurrentFile(file.fileId);
                  setActiveFile(index);
                }}
              >
                <File
                  active={index === activeFile ? true : false}
                  date={file.date}
                  fileName={file.fileName}
                  fileSize={file.fileSize}
                  imgSrc={file.imgSrc}
                  allowDownload={file.allowDownload}
                  thumbnail={file.thumbnail}
                  allowDelete={props.isDelete ? true : false}
                  deleteFile={deleteFile}
                />
              </Box>
            ))}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};
