import * as React from "react";
import {
  Box,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor
} from "@chakra-ui/react";

import {
  TbMessage,
  TbClock,
  TbChevronDown,
  TbEye,
  TbShare,
  TbPencil,
  TbDownload,
  TbCopy,
} from "react-icons/tb";
import { MdPersonAddAlt1 } from "react-icons/md";

import { BsFilePdf, BsCalendarPlus } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { ThemeButton } from "@karkhanaui/react";
interface GenericStatusBarProps {
  POName: string;
  status: string;
  action?: any;
  handleMessage?: any;
  handleActivityLog?: any;
  handleShareBtn?: any;
  handlePreview?: any;
  handleUpload?: any;
  handleClosePurchaseOrder?: any;
  handleSupplierShare?: any;
  isCancelled?: boolean;
  content? : any,
  isExpired? : boolean
}

export const GenericStatusBar = ({
  POName,
  status,
  action,
  handleMessage,
  handleActivityLog,
  handleShareBtn,
  handlePreview,
  handleUpload,
  handleClosePurchaseOrder,
  handleSupplierShare,
  isCancelled,
  content,
  isExpired
}: GenericStatusBarProps) => {
  let styles = {};
  if (status === "Awarded") {
    styles = {
      backgroundColor: "#E8FFF3",
      color: "#50B848",
    };
  } else if (status === "Draft") {
    styles = {
      backgroundColor: "rgba(253, 185, 19, 0.2)",
      color: "rgba(235, 167, 0, 1)",
    };
  } else if (status === "Live") {
    styles = {
      backgroundColor: "rgba(245, 239, 255, 1)",
      color: "rgba(169, 117, 255, 1)",
    };
  } else if (status === "Cancelled") {
    styles = {
      backgroundColor: "rgba(239, 111, 108, 0.2)",
      color: "#EF6F6C",
    };
  } else {
    styles = {
      backgroundColor: "rgba(0, 135, 238, 0.1)",
      color: "rgba(0, 135, 238, 1)",
    };
  }

  const iconStyle = { size: "24px", strokeWidth: "1" };
  let iconsOptionsMap = new Map([
    ["ammend", <TbPencil style={iconStyle} />],
    ["edit", <TbPencil style={iconStyle} />],
    ["pdf view", <BsFilePdf style={iconStyle} />],
    ["download po", <TbDownload style={iconStyle} />],
    ["download rfq", <TbDownload style={iconStyle} />],
    ["duplicate", <TbCopy style={iconStyle} />],
    ["extend expiry date", <BsCalendarPlus style={iconStyle} />],
    ["extend rfq date", <BsCalendarPlus style={iconStyle} />],
    ["cancel rfq", <RxCross2 style={iconStyle} />],
  ]);

  return (
    <Box
      width="100%"
      height="4.5em"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="#fff"
      padding="1em 2em"
      boxSizing="border-box"
      fontSize="14px"
      fontFamily="rubik"
      boxShadow="md"
    >
      <Box height="100%" display="flex" gap="1em" alignItems="center">
        <p>RFQ Name: {POName}</p>
        <p>
          Status:{" "}
          <Box
            style={styles}
            padding="0.2em 0.6em"
            height="100%"
            display="inline-block"
            borderRadius="sm"
          >
            {status}
          </Box>
        </p>
      </Box>
      <Box height="100%" display="flex" gap="1em" alignItems="center">
        {isCancelled != true && action !== undefined && action !== null && (
          <Menu isLazy>
            <MenuButton
              as={Button}
              rightIcon={<TbChevronDown />}
              border="none"
              fontSize="14px"
              height="80%"
              backgroundColor="white"
              cursor="pointer"
            >
              Action
            </MenuButton>
            <MenuList>
              {action.map((item: any) =>
                iconsOptionsMap.has(item.option.toLowerCase()) ? (
                  <MenuItem
                  key={item}
                    onClick={() => {
                      item.func();
                    }}
                    border="none"
                  >
                    {iconsOptionsMap.get(item.option.toLowerCase())}&nbsp;{" "}
                    {item.option}
                  </MenuItem>
                ) : (
                  <MenuItem
                  key={item}
                    onClick={() => {
                      item.func();
                    }}
                    border="none"
                  >
                    {item.option}
                  </MenuItem>
                )
              )}
            </MenuList>
          </Menu>
        )}
        {handleUpload && (
          <Button
            fontSize="14px"
            bgColor="white"
            color="#0087EE"
            height="80%"
            border="1px solid #0087EE"
            cursor="pointer"
            onClick={handleUpload}
          >
            Upload PO Manually
          </Button>
        )}
        {handlePreview && (
          <Button
            border="none"
            fontSize="14px"
            bgColor="white"
            color="#000"
            height="80%"
            cursor="pointer"
            onClick={handlePreview}
          >
            <TbEye />
            &nbsp; Preview
          </Button>
        )}
        {handleClosePurchaseOrder && (
          <Button
            border="none"
            fontSize="14px"
            color="white"
            bg="#0087EE"
            height="80%"
            cursor="pointer"
            onClick={handleClosePurchaseOrder}
          >
            Close Purchase Order
          </Button>
        )}
        {handleMessage && (
          <TbMessage
            size="24px"
            strokeWidth="1"
            onClick={handleMessage}
            cursor="pointer"
          />
        )}
        {handleActivityLog && (
          <Popover>
            <PopoverTrigger>
             <span>
             <TbClock
            // onClick={handleActivityLog}
            cursor="pointer"
            size="24px"
            strokeWidth="1"
          />
             </span>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverHeader fontWeight="semibold">
               Activity Log
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
               {content}
              </PopoverBody>
            </PopoverContent>
          </Popover>
          
        )}
        {handleShareBtn !== undefined && (
          <TbShare
            onClick={handleShareBtn}
            cursor="pointer"
            size="24px"
            strokeWidth="1"
            // isDisabled={isCancelled ||isExpired}
            style={{
              // Disable the button using CSS if either condition is met
              pointerEvents: isCancelled || isExpired ? 'none' : 'auto',
              opacity: isCancelled || isExpired ? 0.5 : 1,
            }}
          />
        )}

        {!isCancelled || handleSupplierShare !== undefined ? (
          <ThemeButton
            leftIcon={<MdPersonAddAlt1 />}
            bg="#0078ee"
            color="#fff"
            size="sm"
            children="Add new Supplier"
            onClick={handleSupplierShare}
            isDisabled={isCancelled ||isExpired}
          />
        ) : null}
      </Box>
    </Box>
  );
};
