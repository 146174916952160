import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
} from "@chakra-ui/react";
import React from "react";

export const BasicDialog: React.FC<any> = (props) => {
  const cancelRef: any = React.useRef();

  return (
    <Box>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={props.onClose}
        isOpen={props.isOpen}
        isCentered
        closeOnOverlayClick={true}
        size={props.size}
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{props.header}</AlertDialogHeader>
          {props.showCloseButton && <AlertDialogCloseButton />}
          {props.header && <hr />}
          <AlertDialogBody>{props.content}</AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
};
