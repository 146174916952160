import * as React from "react";
import { Box, Text, Input } from "@chakra-ui/react";
import { useField, ErrorMessage } from "formik";
import Select from "react-select";
export const SearchDropdown = ({
  label,
  values,
  value,
  placeholder,
  disabled,
  isMandatory,
  defaultValue,
  onChange,
  ...props
}: any) => {
  const [field, meta, helpers] = useField(props.name);
  const selectedOption = values.find(
    (option: any) => option.value === field.value
  );

  return (
    <Box>
      <Text
        textStyle="primary.text"
        sx={{ marginBottom: "0.3rem" }}
        fontSize={props.labelsize}
        fontWeight={props.labelweight}
      >
        {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
      </Text>
      <Select
        {...field}
        {...props}
        options={values}
        value={selectedOption}
        onChange={(selectedOption: any) => {
          helpers.setValue(selectedOption.value); // set the value of the selected option
          onChange({
            target: { name: field.name, value: selectedOption.value },
          }); //Note: call the onChange function in the parent with the value of the selected option
        }}
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </Box>
  );
};
