import {
  CUSTOMER_FETCH_BEGIN,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_FETCH_FAILED,
  
} from "../actions/types";

const customer = {
  customer: "",
  loading: false,
  error: null,
};

export const getUser = (state = customer, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case CUSTOMER_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CUSTOMER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: payload.customer,
      };
    case CUSTOMER_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: payload?.error,
        customer: "",
      };
    default:
      return state;
  }
};
