import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";

export const ChevronProcess: React.FC<any> = ({ title, selected, onChevronClick }) => {
  const selectedColor = selected ? "#000000" : "rgba(0, 0, 0, 0.1)";
  const [isMobile] = useMediaQuery("(max-width: 780px)");
  const borderVal = "23px solid transparent";
  const rightVal = "-46px";

  return (
    <Box
      // w={{xs:"8rem",lg:"100%"}}
      w="100%"
      onClick={() => onChevronClick(title)}
      cursor="pointer"
      bg={selectedColor}
      color={selected ? "white" : "#000000"}
      position="relative"
      // p={{ xs: 1, md: 3 }}
      p="3"
      _after={{
        xs: "none",
        md: {
          content: '" "',
          position: "absolute",
          border: borderVal,
          borderLeftColor: selectedColor,
          top: "0px",
          right: rightVal,
        },
      }}
      // mr={{ xs: 5, md: 10 }}
      mr="10"
    >
      <Text fontSize={{ xs: "8px", md: "sm" }}>{title}</Text>
    </Box>
  );
};

// export default ChevronProcess;
