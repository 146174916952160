import axios from "axios";

export const uploadFileService = (data: any) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/customer_portal_v2.api.file_upload.bulk_upload",
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const saveRFQService = (data: any) => {
  if (data.type === "create")
    return axios.post(
      process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Opportunity",
      data.fields
    );
  else {
    return axios.put(
      process.env.REACT_APP_AUTH_LIVE_URL +
        "/api/resource/Opportunity/" +
        data.name,
      data.fields
    );
  }
};

export const getQuoteService = (quoteID: string) => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Opportunity/" + quoteID
  );
};

export const updateQUote = (quoteID: string, data: any) => {
  return axios.put(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/resource/Opportunity/" +
      quoteID,
    data
  );
};

export const getAllQuote = async (params: any = {}) => {
  
  
  return (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL +
        "/api/resource/Opportunity?order_by=modified%20desc",
      {
        params: {
          ...(params.filters && { filters: JSON.stringify(params.filters) }),
          fields: JSON.stringify(params.fields)
            ? JSON.stringify(params.fields)
            : '"*"',
          ...(params.limit_start && {
            limit_start: JSON.stringify(params.limit_start),
          }),
          
          ...(params.order_by && {
            order_by: JSON.stringify(params.order_by)
            
            
          }),
        },
      }
    )
  );
};
