import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  API_LOADING,
  PAGE_LOADING,
  UPDATE_ONBOARD_STATUS,
} from "../actions/types";

const initialState = {};

const pageLoadingState = {
  loading: false,
  loadingText: "",
};

export const message = function (state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { message: payload };

    case CLEAR_MESSAGE:
      return { message: "" };

    default:
      return state;
  }
};

export const apiLoading = function (state = false, action: any) {
  const { type, payload } = action;

  switch (type) {
    case API_LOADING:
      return payload;
    default:
      return state;
  }
};

export const pageLoading = function (state = pageLoadingState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case PAGE_LOADING:
      return payload;
    default:
      return state;
  }
};

export const onBoardStatus = function (state = true, action: any) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ONBOARD_STATUS:
      return payload;
    default:
      return state;
  }
};