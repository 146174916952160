import axios from "axios";

export const addOpportunityItemService = async (data: any) => {
  return (
    await axios.post(
      process.env.REACT_APP_AUTH_LIVE_URL + `/api/resource/Opportunity Item`,
      data
    )
  ).data;
};
