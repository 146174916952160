import * as React from "react";
import { Box, Alert, AlertIcon, Flex } from "@chakra-ui/react";
import { Button } from "@karkhanaui/react";

interface deleteProps {
  name: string;
  deleteFunction: Function;
  cancelFunction: Function;
}

export const DeletePrompt = (props: deleteProps) => {
  return (
    <Box>
      <Alert status="error" borderRadius="8px">
        <AlertIcon />
        Are you sure you want to delete {props.name}?
      </Alert>
      <Flex
        alignItems="center"
        justifyContent={{base:"space-around", lg:"flex-end"}}
        mt="1.5rem"
        gap="1rem"
        w={{base: "100%", lg: "auto"}}
      >
        <Button
          variant="ghost"
          _hover={{ bg: "#fff" }}
          children="Cancel"
          onClick={() => {
            props.cancelFunction();
          }}
          flexGrow={{base: 1, lg: 0}}
        />
        <Button
          children="Delete"
          bg="#D32E2E"
          color="#fff"
          onClick={() => props.deleteFunction()}
          flexGrow={{base: 1, lg: 0}}
        />
      </Flex>
    </Box>
  );
};
