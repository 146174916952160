import axios from "axios";

export const getAllUsers = () => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/user_management.api.get_users"
  );
};

export const getAllInvitedMembers = () => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      '/api/resource/Invited Team Members?fields="*"'
  );
};

export const getUserInfo = () => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/user_management.api.get_user_info"
  );
};
export const makeUserAdmin = (data: any) => {
  return axios.put(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/user_management.api.make_member_admin",
    {
      payload: {
        member: data,
      },
    }
  );
};
