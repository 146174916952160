import React, { useEffect, useRef, useState } from "react";
import { DxfViewer } from "dxf-viewer";
import * as THREE from "three";
import { AiOutlineWarning } from "react-icons/ai";


const defaultOptions = {
  clearColor: new THREE.Color("#fff"),
  autoResize: true,
  colorCorrection: true,
  sceneOptions: {
    wireframeMesh: true,
  },
};


export const DxfViewerComponent = ({ dxfUrl = null, fonts=null, options = {} }) => {
  const canvasContainerRef = useRef(null);
  const [error, setError] = useState(null);
  const dxfViewer = useRef(null);

  useEffect(() => {
    const mergedOptions = { ...defaultOptions, ...options };
    dxfViewer.current = new DxfViewer(
      canvasContainerRef.current,
      defaultOptions
    );
    try {
      dxfViewer.current &&  dxfViewer.current.Load({
        url: dxfUrl,
        fonts,
        // progressCbk: handleProgress,
        // workerFactory: DxfViewerWorker,
        options: mergedOptions, // Pass merged options to the Load function
      });
    } catch (e) {
      setError(e.toString());
    }

    return () => {
      if (dxfViewer) {
        dxfViewer.current && dxfViewer.current.Destroy();
        dxfViewer.current = null;
      }
    };
  });

  return (
    <div className="" style={{width: "100%", height: "100%"}} ref={canvasContainerRef}>
      {error && (
        <div className="error" title={error}>
          <AiOutlineWarning className="text-red" style={{ fontSize: "4rem" }} />{" "}
          Error occurred: {error}
        </div>
      )}
    </div>
  );
};

