import * as React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { BsFillFileEarmarkFill } from "react-icons/bs";

export const NoDataAvailable = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="1rem"
    >
      <BsFillFileEarmarkFill size="7rem" fillOpacity=".1" />
      <Text textStyle="primary.largeSecondaryText">No Data</Text>
    </Flex>
  );
};
