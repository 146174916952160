import * as React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
interface DashboardCardInterface {
  icon: any;
  text: string;
  number: number;
}
export const DashBoardCardHome: React.FC<any> = ({
  icon,
  text,
  number,
}: DashboardCardInterface) => (
  <Box
    width="100%"
    height={"100%"}
    display={"flex"}
    bg="#fff"
    flexDir={"column"}
    p="1rem"
    boxShadow={"rgba(94, 98, 120, 0.04)"}
    borderRadius=".75rem"
  >
    <Image src={icon} width="34px" height="34px" />
    <Text fontWeight={600} fontSize={"25px"} color="rgba(0, 135, 238, 1)">
      {number}
    </Text>
    <Text size="14px" lineHeight={"16.59px"} fontWeight={500}>
      {text}
    </Text>
  </Box>
);
