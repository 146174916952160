import { Text } from "@chakra-ui/react";
import React from "react";

export const SubTitleText: React.FC<any> = ({ children, ...rest }) => {
  return (
    <Text
      fontSize={{ base: "xs", lg: "sm" }}
      fontWeight="500"
      color="dark.60"
      {...rest}
    >
      {children}
    </Text>
  );
};


