import { Box, Flex, Text, Link } from "@chakra-ui/react";
import { DarkButton } from "@karkhanaui/button";
import { useDropzone } from "react-dropzone";
import * as React from "react";

const bi = `repeating-linear-gradient(0deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(90deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(180deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(270deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px);`;
const bs = `2px 100%, 100% 2px, 2px 100% , 100% 2px;`;
const bp = `0 0, 0 0, 100% 0, 0 100%;`;

export const FileUploadCard = (props: any) => {
  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      props.updateFile(acceptedFiles);
    },
    [props]
  );
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: props.isMultiple,
  });
  return (
    <Box margin="10px" padding="4%" bg="#fff" height="100%">
      <Flex
        flexDirection={"column"}
        alignItems="center"
        justifyContent="space-between"
        gap="2rem"
        py="5rem"
        borderRadius={"5px"}
        backgroundImage={bi}
        backgroundSize={bs}
        backgroundPosition={bp}
        backgroundRepeat="no-repeat"
        {...getRootProps()}
      >
        <Flex lineHeight="35px" alignItems="center" flexDirection="column">
          <Text textStyle="primary.text" my="0px">
            Drag and drop to
          </Text>
          <Text textStyle="primary.title">Upload Design Files</Text>
          <Text textStyle="primary.thin">Or</Text>
          <input {...getInputProps({})} />
          <DarkButton onClick={open}>Browse Files</DarkButton>
        </Flex>
        <Flex alignItems="center" flexDirection="column">
          {/* <Text textStyle="primary.text" mt="-2em"> */}
          <Flex lineHeight="30px" alignItems="center" flexDirection="column">
            <Text textStyle="primary.text" mt="3.75rem">
              <b>File types: </b>
              {props.supportedFiles
                ? props.supportedFiles?.map(
                    (item: string, index: number) => item + ", "
                  )
                : "Any"}
              {/* STEP, STP, IGES, IGS, SLDPRT, 3DM, SAT or X_T FILES */}
            </Text>
            <Text textStyle="primary.text">
              <b>File Size: </b>
              {"<"} 100MB{" "}
            </Text>
            <Text textStyle="primary.text">
              All your files are entirely secured with us{" "}
              <Link
                color="#3E97FF"
                href="https://www.karkhana.io/privacy-policy"
                target="_blank"
              >
                (read our privacy guarantee )
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
