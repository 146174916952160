import {
  UPDATE_SIGNUP_STATE,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USERID_FETCH_SUCCESS,
} from "../actions/types";

import Cookies from "js-cookie";
// import { UPDATE_SIGNUP_STATE } from "@karkhanaui/react";

const localUser = Cookies.get();
const user = localUser.user_id ? localUser.user_id : null;

const initialState =
  user && user !== "Guest"
    ? { isLoggedIn: true, user, uniqueUserId: null }
    : { isLoggedIn: false, user: null, uniqueUserId: null };

export const authSupplier = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case USERID_FETCH_SUCCESS:
      return {
        ...state,
        uniqueUserId: payload.uniqueUserId,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};

const signUp = {
  signUpLoading: false,
  signUpEmail: "",
  signUpPwd: "",
};

export const signUpState = function (state = signUp, action: any) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SIGNUP_STATE:
      return payload;
    default:
      return state;
  }
};
