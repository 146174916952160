import React, { useEffect, useRef } from "react";
import {
	Box,
} from "@chakra-ui/react";

import { motion, AnimatePresence, PanInfo } from "framer-motion";


const useOutsideClick = (ref, callback) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		};

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("touchstart", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("touchstart", handleClickOutside);
		};
	}, [ref, callback]);
};

interface OverlayProps {
	isOpen: any;
	onClose?: any;
	children?: any;
}

export const Overlay = ({ isOpen, onClose, children, ...props }: any) => {
	const overlayRef = React.useRef<HTMLDivElement>(null);
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, onClose);

	return (
		<AnimatePresence >
			{isOpen && (
				<motion.div
					{...props}

					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: .3 }}
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						background: "rgba(0, 0, 0, 0.8)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						overflow: "hidden",
						zIndex: 1000,
					}}
					ref={overlayRef}
				>
					<Box ref={wrapperRef} >{children}</Box>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
