import * as React from "react";
import { Box, Flex, Text, Tag, Button } from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineShareAlt } from "react-icons/ai";
import { BsChatLeftText, BsClock } from "react-icons/bs";
interface Data {
  DocName: string;
  Name: string;
  Status: any;
  isActionPrsent: boolean;
  isSharePresent: boolean;
}
export const StatusBar = ({ DocName, Name, Status, ...props }: Data) => {
  return (
    <Box width="100%" p="1em" bg="#fff" boxShadow={"md"}>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <Text mr="1em">
            {DocName} : {Name}
          </Text>
          <Text>Status :</Text>
          <Tag bg="blue.200" ml="1em">
            {Status}
          </Tag>
        </Flex>
        <Flex justifyContent={"end"} alignItems="center" gap="1em">
          <BsChatLeftText />
          <BsClock />
          <Button leftIcon={<AiOutlineShareAlt />} size="sm" children="Share" />
        </Flex>
      </Flex>
    </Box>
  );
};
