import * as React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { frappeDateFormatter, ThemeButton } from "@karkhanaui/react";

interface cardProps {
  date: string;
  amount: number;
  status: string;
  reference: string;
  reference_file: string;
  buttonAction: any;
  uploadDetails: any;
  term: any;
}

export const PaymentHistoryCard = ({
  date,
  amount,
  status,
  reference,
  reference_file,
  buttonAction,
  uploadDetails,
  term,
}: cardProps) => {
  let text_style = "";
  switch (status) {
    case "Payment Done":
      text_style = "primary.success";
      break;
    case "Payment Overdue":
      text_style = "primary.danger";
      break;
    case "Upcoming Payment":
      text_style = "primary.secondaryText";
      break;
    default:
      break;
  }
  return (
    <Flex
      py=".8rem"
      borderBottom="1px solid gray"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Text textStyle="primary.secondaryText">
          {frappeDateFormatter(date)}
        </Text>
        <Text textStyle="primary.title" mt=".5rem">
          &#8377; {amount}
        </Text>
        <Text textStyle={text_style} mt=".5rem" fontWeight="bold">
          {status}
        </Text>
      </Box>
      <Flex flexDirection="column" alignItems="center">
        <ThemeButton
          minWidth="5.5rem"
          children={status === "Payment Done" ? "View" : "Pay Now"}
          onClick={() =>
            buttonAction(status, amount, date, reference, reference_file, term)
          }
        />
        {status !== "Payment Done" && (
          <Text
            textStyle="primary.link"
            mt=".5rem"
            fontWeight="bold"
            cursor="pointer"
            onClick={() => uploadDetails()}
          >
            Upload Details
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
