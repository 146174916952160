import React, { useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { Box } from "@chakra-ui/react";
import { ErrorMessage, useField } from "formik";

interface Props {
  id: any;
  onContentChange?: any;
  initialContent?: any;
  label ? :any;
  placeholder? : any;
  disabled? : any;
  isMandatory? : any;
  props? : any;
}

export const DocumentEditor: React.FC<Props> = ({
  id,
  onContentChange,
  initialContent,
  label,
  placeholder,
  disabled,
  isMandatory,
  props
}: Props) => {
  const [content, setContent] = useState<string>(initialContent || "");
  const [field, meta] = useField(props);

  const handleEditorChange = (content: string) => {
    setContent(content);
    onContentChange(content);
    // onDataChange(content)
  };

  React.useEffect(() => {
    const editor = new Quill(`#${id}`, {
      modules: {
        toolbar: [
          [{ font: [] }],
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ align: [] }],
        ],
      },
      theme: "snow",
    });

    editor?.on("text-change", () => {
      handleEditorChange(editor.root.innerHTML);
    });

    // const delta = editor.clipboard.convert(initialContent);
    // editor.setContents(delta, 'silent')
  }, []);
  return (
    <Box bg="#fff" borderRadius="md" height="80%">
      <Box id={id} bg="#fff" height={"440px"} color="#087EE" 
       placeholder={placeholder || label}
        {...field}
        {...props}
        isInvalid={meta.touched && meta.error}
        isReadOnly={disabled}
        fontSize={{ xs: "xs", lg: "sm" }}/>
      {/* <Box
        dangerouslySetInnerHTML={{ __html: content }}
        style={{ whiteSpace: "pre-wrap" }}
      /> */}
    </Box>
  );
};
