import { border, Box, Text, Flex } from "@chakra-ui/react";
import * as React from "react";

interface ProcessCardProps {
  title: string;
  subtitle: string;
  active?: boolean;
}

export const Processcard = ({
  title,
  subtitle,
  active = false,
}: ProcessCardProps) => {
  let background = "#fff";
  let border = "1px solid rgba(115, 115, 115, 0.4);";

  if (active) {
    background = "rgba(0, 135, 238, 0.1);";
    border = "1px solid #0087EE;";
  }

  return (
    <Box
      height="100%"
      border={border}
      background={background}
      borderRadius="4px"
      boxSizing="border-box"
      padding="0.625rem"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Text textStyle="primary.title" flex={1} color="#0087EE">
        {title}
      </Text>
      <Text textStyle="primary.paragraph" color="grey" flex={2}>
        {subtitle}
      </Text>
    </Box>
  );
};
