import * as React from 'react'
import {
	Input,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";

import { debounce } from "lodash";
import { SearchIcon } from "@chakra-ui/icons";

export const SearchBar = ({ data, searchKeys, onDataFiltered }: any) => {
	const [searchTerm, setSearchTerm] = React.useState("");

	const handleSearch = debounce((value: any) => {
		setSearchTerm(value);
	}, 300);

	React.useEffect(() => {
		onDataFiltered(
			data.filter((item: any) =>
				searchKeys.some((key: any) =>
					item[key]?.toLowerCase().includes(searchTerm.toLowerCase())
				)
			)
		);
	}, [searchTerm]);

	return (
		<InputGroup>
			<InputLeftElement pointerEvents="none">
				<SearchIcon color="gray.300" />
			</InputLeftElement>
			<Input
				type="text"
				placeholder="Search"
				onChange={(e) => handleSearch(e.target.value)}
			/>
		</InputGroup>
	);
};

