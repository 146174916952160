import {
  Box,
  Text,
  Flex,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  FormControl,
  Modal,
  ModalFooter,
  ModalBody,
  FormLabel,
  Input,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import * as React from "react";
import { BsPencil } from "react-icons/bs";
import { Button } from "@karkhanaui/button";

interface EditChangeDialogProps {
  title: string;
  subtitle?: string;
  onClose: () => void;
  onChange: Function;
  isButtonDisabled?: boolean;
}

export const EditChangeDialog = ({
  title,
  subtitle,
  onChange,
  isButtonDisabled,
}: EditChangeDialogProps) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [message, setmessage] = React.useState("");

  let textStyle = "primary.text";
  if (!subtitle) {
    textStyle = "primary.bold";
  }

  const saveHandler = () => {
    const node = initialRef.current;

    if (node) {
      setmessage(node.value);
      onChange(node.value);
    }

    onClose();
  };

  let [value, setValue] = React.useState(title);

  let handleInputChange = (e: any) => {
    let inputValue = e.target.value;
    setValue(inputValue);
  };

  return (
    <>
      <Box height="100%">
        <Flex alignItems="center">
          <Text
            mr="10px"
            textStyle={textStyle}
            maxW="80px"
            textOverflow="ellipsis"
            noOfLines="1"
          >
            {!subtitle ? (!message ? title : message) : title}
          </Text>{" "}
          {!isButtonDisabled && <BsPencil cursor="pointer" onClick={onOpen} />}
        </Flex>
        {subtitle && (
          <Text textStyle="primary.secondaryText" mt="1rem">
            {message ? message : subtitle}
          </Text>
        )}
      </Box>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit {title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <Textarea
                ref={initialRef}
                value={value}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={saveHandler}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
