import * as React from "react";
import {
  Box,
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";


export const MobileDrawer = ({ isOpen, onClose, header, footer, children, ...props }: any) => {
  return (
    <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent borderTopLeftRadius="1rem" borderTopRightRadius="1rem" p="0" pt="1rem" >
        <DrawerCloseButton />
        <Box
          width="50px"
          height="5px"
          borderRadius="md"
          bgColor="gray.300"
          mx="auto"
          mt="10px"
        ></Box>
        {header !== undefined && (
          <DrawerHeader>{header}</DrawerHeader>
        )}
        <DrawerBody maxHeight="80vh !important"  p="1rem 0 0">
          <Box p={4} {...props}>
            {children}
          </Box>
        </DrawerBody>
        {footer !== undefined && (
          <DrawerFooter>{footer}</DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

