import { Flex, Text } from "@chakra-ui/react";
import * as React from "react";

interface SimpleFile {
  fileName: string;
  link: string;
}

export const SimpleFile = ({ fileName, link }: SimpleFile) => {
  return (
    <Flex cursor="pointer">
      <Text
        textStyle="primary.text"
        onClick={() => window.open(link, "_blank")}
      >
        {fileName} ↗
      </Text>
    </Flex>
  );
};
