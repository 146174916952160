import axios from "axios";

export const getSingleSalesOrder = (id: any) => {
  return axios.get("/api/resource/Sales Order/" + id);
};

export const getSingleOpportunity = (data: any) => {
  return axios.get("/api/resource/Opportunity/" + data.id, {
    params: data.params,
  });
};

export const getFilesList = (data: any) => {
  return axios.get("/api/resource/File", { params: data });
};

export const updateSalesOrder = (id: any, data: any) => {
  return axios.put("/api/resource/Sales Order/" + id, data);
};

export const suppllierCreateOrderUpdate = (data: any) => {
  return axios.post("/api/resource/Client Order Update", data);
};
