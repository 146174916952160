import * as React from "react";
import {
  Box,
  AspectRatio,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ThemeButton, Button } from "@karkhanaui/react";
import Karkhana from "../../../images/Kkonnect.png";

export const SubmitPopup = ({
  text,
  textId,
  buttonName,
  navLink,
  onClick,
  image,
  gif,
  subHeading,
  checkoutButtonText,
}: any) => {
  const handleClick = (buttonText: any) => {
    onClick(buttonText);
  };
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mb="2rem"
      padding="1rem"
    >
      <Image
        src={image !== undefined ? image : Karkhana}
        width="12.75rem"
        mt="2.5rem"
        alt="Karkhana logo"
        height="auto"
      />
      {/* <Image
        src={gif}
        height="173px"
        width="173px"
        mt="2rem"
        ml="auto"
        mr="auto"
      /> */}
      <lottie-player
        src="https://assets7.lottiefiles.com/private_files/lf30_poez9ped.json"
        background="transparent"
        speed="1"
        style={{ width: "200px", height: "200px" }}
        autoplay
      ></lottie-player>
      <Text textStyle="primary.header" mt="2rem" textAlign="center">
        {text}
      </Text>
      <Text
        textStyle={"primary.header"}
        fontSize="14px"
        mt="1em"
        textAlign={"center"}
      >
        {subHeading} : {textId}
      </Text>

      {buttonName && (
        <ThemeButton
          children={buttonName}
          onClick={() => handleClick("purchaseOrder")}
          alignItems={"center"}
          mt="1em"
        />
      )}
      <Button
        mt="1em"
        children={checkoutButtonText}
        variant="ghost"
        border="1px solid #0087EE"
        color="#0087EE"
        _hover={{ opacity: ".8" }}
        onClick={() => handleClick("rfq")}
      />
    </Flex>
  );
};
