import * as React from "react";
import { Box, Text, Flex, Image, Textarea } from "@chakra-ui/react";
import { ThemeButton } from "@karkhanaui/react";
import { FaRegCommentDots } from "react-icons/fa";

interface eachComment {
  name: string;
  imgSrc?: string;
  time: string;
  comment: string;
}
interface commentsProps {
  commentList?: eachComment[];
  addComment?: any;
}
export const Comments = ({ commentList, addComment }: commentsProps) => {
  const [newComment, setNewComment] = React.useState<string>("");
  const [commentValidation, setCommentValidation] =
    React.useState<boolean>(false);
  return (
    <Box padding="10px">
      {commentList && commentList?.length >= 1 ? (
        <Box maxHeight="60vh" overflowY="scroll">
          {commentList?.map((item: any, index: number) => (
            <Flex gap="1rem" mb="1.5rem" key={index}>
              <Image
                src={item.imgSrc}
                alt="img"
                width="2rem"
                height="2rem"
                borderRadius="50%"
              />
              <Box>
                <Text
                  textStyle="primary.contentTitle"
                  display="flex"
                  alignItems="flex-end"
                  gap="1rem"
                >
                  {item.name}
                  <Text textStyle="primary.secondaryText">
                    {item.time} hours ago
                  </Text>
                </Text>
                <Text mt=".75rem" textStyle="primary.paragraph">
                  {item.comment}
                </Text>
              </Box>
            </Flex>
          ))}
        </Box>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          gap="1.5rem"
          flexDirection="column"
          margin="5rem"
        >
          <FaRegCommentDots color="rgba(0, 135, 238, 0.2)" size="6rem" />
          <Text textStyle="primary.largeSecondaryText">No comments added</Text>
        </Flex>
      )}
      <Textarea
        placeholder="Add a comment..."
        isInvalid={commentValidation ? true : false}
        bg="#F3F6F9"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      />
      <Flex justifyContent="right" mt="1rem">
        <ThemeButton
          children="Comment"
          onClick={() => {
            if (newComment !== "") {
              addComment(newComment);
              setNewComment("");
              setCommentValidation(false);
            } else {
              setCommentValidation(true);
            }
          }}
        />
      </Flex>
    </Box>
  );
};
