import * as React from "react";
import { Box, Flex, Text, BoxProps, Grid, GridItem } from "@chakra-ui/react";
import { Button } from "@karkhanaui/react";
import { BsDownload } from "react-icons/bs";

interface KeyValueProps extends BoxProps {
  title: string;
  value: string;
}

const KeyValue = ({ title, value, ...rest }: KeyValueProps) => {
  return (
    <Box {...rest}>
      <Text textStyle="primary.text" color="main.500" fontWeight="bold">
        {title}:
      </Text>
      <Text textStyle="primary.paragraph" mt=".5rem">
        {value}
      </Text>
    </Box>
  );
};

interface OrderMainCardProps {
  summary: {
    key: string;
    value: string;
  }[];
  completePayment: any;
}

export const OrderMainCard = ({
  summary,
  completePayment,
  downloadSalesOrder,
}: OrderMainCardProps) => {
  return (
    <Box bg="#fff" borderRadius="8px" padding="1.25rem">
      <Flex
        flexWrap="wrap"
        gap="1rem"
        // alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          templateColumns={{
            xs: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap="2.2rem"
          flexGrow={3}
        >
          {summary &&
            summary.map((each) => (
              <GridItem key={each.key}>
                <KeyValue key={each.key} title={each.key} value={each.value} />
              </GridItem>
            ))}
        </Grid>
        <Flex
          flexDirection={{ lg: "column" }}
          alignItems={{ lg: "flex-end" }}
          justifyContent="space-between"
          gap="1rem"
        >
          <Flex flexDirection="column" alignItems="center" cursor="pointer">
            <Flex
              width="2.25rem"
              height="2.25rem"
              borderRadius="6px"
              bg="rgba(0, 135, 238, 0.1)"
              alignItems="center"
              justifyContent="center"
              onClick={downloadSalesOrder}
            >
              <BsDownload size="" />
            </Flex>
            <Text textStyle="primary.small" mt="7px" color="main.500">
              Sales Order
            </Text>
          </Flex>
          {/* <Button
            variant="ghost"
            border="1px solid black"
            _hover={{ bg: "#fff" }}
          >
            Order Again
          </Button> */}
        </Flex>
      </Flex>
      {/* <Text
        textStyle="primary.link"
        cursor="pointer"
        as="u"
        onClick={() => completePayment()}
      >
        Complete Payment
      </Text> */}
    </Box>
  );
};
