import * as React from "react";
import { Flex, Text, Image, Box } from "@chakra-ui/react";
import { Dxfviewer, getFileType, ThreedViewer } from "@karkhanaui/react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { MdFullscreen } from "react-icons/md";

import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import * as PDFJS from "pdfjs-dist";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export interface displayFileProps {
  file: string;
  isPrivate?: boolean;
}

const fonts = [
  "/libs/fonts/Roboto-LightItalic.ttf",
  "/libs/fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf",
  "/libs/fonts/HanaMinA.ttf",
  "/libs/fonts/NanumGothic-Regular.ttf",
];

export const DisplayFile = ({ file, isPrivate }: displayFileProps) => {
  const handle = useFullScreenHandle();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const renderFile = () => {
    switch (getFileType(file)) {
      case "image":
        return isPrivate ? (
          <Flex alignItems="center" justifyContent="center">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load image.
            </Text>
          </Flex>
        ) : (
          <Image
            src={file}
            alt={file.split("/").pop()}
            width="100%"
            height={handle.active ? "100%" : "50vh"}
            borderRadius=".25rem"
          />
        );
      case "pdf":
        return isPrivate ? (
          <Flex alignItems="center" justifyContent="center">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load file.
            </Text>
          </Flex>
        ) : (
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        );
      case "CAD_File":
        return isPrivate ? (
          <Flex alignItems="center" justifyContent="center">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load file.
            </Text>
          </Flex>
        ) : (
          <ThreedViewer externalLibLocation="/libs" modelURLs={[file]} />
        );

      case "DXF_FILE":
        return isPrivate ? (
          <Flex alignItems="center" justifyContent="center">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load file.
            </Text>
          </Flex>
        ) : (
          <Dxfviewer dxfUrl={file} fonts={fonts} />
        );
      default:
        return (
          <Flex alignItems="center" justifyContent="center">
            <Text textStyle="primary.danger" fontWeight="bold" fontSize="lg">
              Failed to load file.
            </Text>
          </Flex>
        );
        break;
    }
  };

  return (
    <Box
      borderRadius="6px"
      bg="#D9DBE0"
      width="100%"
      position="relative"
      objectFit="contain"
      overflow="auto"
    >
      <FullScreen handle={handle}>
        <Flex
          alignItems="center"
          justifyContent="center"
          width="100%"
          height={handle.active ? "100%" : { base: "200px", md: "370px" }}
        >
          {renderFile()}
        </Flex>
      </FullScreen>
      <Box
        right="0"
        bottom="0"
        position="absolute"
        w="50px"
        h="50px"
        cursor="pointer"
        onClick={handle.enter}
      >
        <MdFullscreen size="50px" />
      </Box>
    </Box>
  );
};
