import axios from "axios";
import { getFiles } from "@karkhanaui/react";

export const getOrderUpdateService = async (params: any) => {
  const updates = await (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Customer Update",
      {
        params: {
          filters: JSON.stringify(params.filters),
          fields: params.fields ? JSON.stringify(params.fields) : '"*"',
        },
      }
    )
  ).data;

  const allUpdates = updates.data.map((each) => each.name);

  const filesToUpdates = await getFiles({
    filters: [["attached_to_name", "in", allUpdates]],
  });

  return updates.data.map((each) => ({
    update: each,
    files: filesToUpdates.data?.data.filter(
      (file) => file.attached_to_name === each.name
    ),
  }));
};

export const getCustomerUpdateService = async (params: string) => {
  return (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL +
        `/api/resource/Customer Update/${params}`
    )
  );
};
