
import React, { useState } from "react";
import {
	Box,
	Text,
	Flex,
	Divider,
	Tag,
	Grid,
	GridItem,
	OrderedList,
	ListItem,
	Link as ChakraLink,
	Link,
	Spacer,
	chakra,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	FormControl,
	FormLabel,
	Input,
	Select,
	TabIndicator,
	Textarea,
	Button,
} from "@chakra-ui/react";
import OrderStatusBar from "./OrderStatusBar";
import { MdAttachFile } from "react-icons/md";
import { normalDate, BasicDialog, FileViewCard } from "@karkhanaui/react";

interface OrdersUpdateBarProps {
	status: string;
	timestamp: string;
	orderUpdate: string;
	attachmentsLink: string;
}

export const MobileOrderStatusCard: React.FC<OrdersUpdateBarProps> = ({
	status,
	timestamp,
	orderUpdate,
	attachmentsLink,
}) => {
	const [showViewFiles, setShowViewFiles] = useState<boolean>(false);
	return (
		<Box
			width="100%"
			height="auto"
			p=".875rem 1rem"
			borderRadius="1em"
			bg="#fff"
		>
			<Box>
				<Grid templateColumns="auto 1fr" alignItems="center">
					<Text fontWeight="bold">
						<chakra.span color="#4BA18A">Status:&nbsp;</chakra.span>
						{status}
					</Text>
					<Text color="#4BA18A" fontWeight="bold" justifySelf="end">
						{/* future update */}
						{/* {`${timestamp.slice(11, 16)}${
							Number(timestamp.slice(11, 12)) < 12 ? "AM" : "PM"
						} on ${normalDate(timestamp)}`} */}
						{normalDate(timestamp)}
					</Text>
				</Grid>
			</Box>
			<Text mt="1.5rem">{orderUpdate}</Text>
			<Flex
				alignItems="center"
				gap=".5rem"
				justifyContent="flex-end"
				cursor="pointer"
				onClick={() => setShowViewFiles(true)}
			>
				<MdAttachFile size="20px" color="#4BA18A" />
				<Text color="#4BA18A" fontWeight="bold" textDecoration="underline">
					Attachments
				</Text>
			</Flex>

			{/* View attachments pop up */}
			<BasicDialog
				isOpen={showViewFiles}
				onClose={() => setShowViewFiles(false)}
				header="View Attachments"
				showCloseButton={true}
				size="5xl"
				content={
					<FileViewCard
						isStepT={true}
						item_id={attachmentsLink}
						environmentVariable={process.env.REACT_APP_LIVE_URL}
						documentName="Client Order Update"
						
					/>
				}
			/>
		</Box>
	);
};



	


