import * as React from "react";
import {
  Tr,
  Td,
  Text,
  Flex,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  Button,
  BasicDialog,
  Chip,
  Tag,
  TagProps,
  DeletePrompt,
} from "@karkhanaui/react";
import { Link, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical, BsGear } from "react-icons/bs";

interface ItemtableProps {
  quoteId: string;
  name: any;
  itemName: string;
  itemCode: string;
  itemDimensions: string;
  quantity: number;
  total: string;
  action: string;
  metadata: {
    key: string;
    value: string;
  }[];
  image: string;
  chips?: {
    key: string;
    value: string;
    action: string;
  }[];
  tag: TagProps;
  rfqId?: string;
  partId?: string;
  bulkConfigure: boolean;
  handleCheckboxChange: any;
  deleteItem: Function;
  disableButton: boolean;
  st: string
}

export const Itemtable = (props: ItemtableProps) => {
  const navigate = useNavigate();
  const [deleteItemPopUp, setDeleteItemPopUp] = React.useState<boolean>(false);


  return (
    <Tr bg="white" mt="10px" _hover={{ bg: "rgba(0, 135, 238, 0.1)" }}>
      {
        props.st === "Configure" ? <Td borderTopLeftRadius="6px" borderBottomLeftRadius="6px">
        <input
          type="checkbox"
          checked={props.bulkConfigure}
          onChange={(e) =>
            props.handleCheckboxChange(e.target.checked, props.itemCode)
          }
        />
      </Td> : <></>
      }
      
      <Td>
        <Flex flexDirection="column" alignItems="flex-start" gap="10px">
          <Box width="48px" objectFit="contain">
            {!props.image ? (
              <BsGear size="100%" />
            ) : (
              <Image
              height= "75px"
                    src={process.env.REACT_APP_LIVE_URL + props.image}
               
                borderRadius="5px"
              />
            )}
          </Box>
          {props.chips?.map((each) => (
            <Chip title={each.key} count={each.value} key={each.key} />
          ))}
        </Flex>
      </Td>

      <Td>
        <Text textStyle="primary.bold">{props.itemName}</Text>
        <Text textStyle="primary.small">{props.itemDimensions}</Text>
      </Td>
      <Td>
        {props.metadata.map((each) => (
          <Flex alignItems="center" key={each.key}>
            <Text my="2px" textStyle="primary.text">
              {each.key}:&nbsp;
            </Text>
            {each.value === "Configure" ? (
              <Link
                to={
                  props.disableButton
                    ? "#"
                    : `/instantQuote/newQuote/${props.rfqId}/configure/${props.partId}`
                }
              >
                <Text textStyle="primary.link">
                  <b>{each.value}</b>
                </Text>
              </Link>
            ) : (
              <Text my="2px" textStyle="primary.text">
                <b>{each.value}</b>
              </Text>
            )}
          </Flex>
        ))}
      </Td>
      <Td>
        <Text textStyle="primary.text">{props.quantity}</Text>
      </Td>
      <Td>
        <Tag type={props.tag.type} message={props.tag.message} />
      </Td>
      <Td>
        <Text textStyle="primary.large">{props.total}</Text>
      </Td>
      <Td borderTopRightRadius="6px" borderBottomRightRadius="6px">
        <Flex w="110%" alignItems="center" justifyContent="space-between">
          <Button
            onClick={() =>
              navigate(
                `/instantQuote/newQuote/${props.rfqId}/configure/${props.partId}`
              )
            }
          >
            Configure
          </Button>
          <Menu>
            <MenuButton>
              <BsThreeDotsVertical />
            </MenuButton>
            <MenuList>
              <MenuItem
                isDisabled={props.disableButton}
                onClick={() => {
                  setDeleteItemPopUp(true);
                }}
              >
                Delete Part
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Td>
      <BasicDialog
        header="Delete?"
        isOpen={deleteItemPopUp}
        onClose={() => setDeleteItemPopUp(false)}
        showCloseButton={true}
        content={
          <DeletePrompt
            name={props.itemName}
            deleteFunction={() => {
              setDeleteItemPopUp(false);
              props.deleteItem(props.name);
            }}
            cancelFunction={() => setDeleteItemPopUp(false)}
          />
        }
      />
    </Tr>
  );
};
