import * as React from "react";
import {
  Box,
  Flex,
  Text,
  Divider,
  Checkbox,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import {
  Info,
  InfoProps,
  DarkButton,
  BasicDialog,
  ThemeButton,
} from "@karkhanaui/react";
import { useNavigate } from "react-router-dom";

interface hoverTotal {
  key: string;
  value?: string;
}
interface Totals {
  key: string;
  value?: string;
  link?: string;
  tooltip?: hoverTotal;
}

interface PriceCardProps {
  title?: string;
  contextSummary?: Totals[];
  totals: Totals[];
  info?: InfoProps;
  summary?: Totals;
  nextAction?: {
    link: string;
    title: string;
    disabled: boolean;
    nextActionFunction?: any;
  };
  termsAndConditions?: string;
  onChangeTermsAndConditions?: any;
  isButtonLoading?: boolean;
  termAmount?: string;
  terms?: string;
}

export const PriceCard = ({
  title,
  contextSummary,
  totals,
  info,
  summary,
  nextAction,
  termsAndConditions,
  onChangeTermsAndConditions,
  isButtonLoading,
  termAmount,
  terms,
}: PriceCardProps) => {
  const navigate = useNavigate();
  const [showTermsAndConditions, setShowTermsAndConditions] =
    React.useState<boolean>(false);
  const toolTipBody = (content: any) => {
    return content?.map((item: any) => (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        key={item.key}
        gap="12px"
      >
        <Text textStyle="primar.small">{item.key}</Text>
        <Text textStyle="primary.bold">{item.value ? item.value : "-"}</Text>
      </Flex>
    ));
  };

  return (
    <Box bg="#fff" borderRadius="12px" padding="15px" height="100%">
      {!!title && (
        <Text mt="1rem" ml="2%" textStyle="primary.contentTitle">
          {title}
        </Text>
      )}
      {info && <Info {...info} />}
      {contextSummary && (
        <Box px="10px" pb="5px">
          {contextSummary.map((each) => (
            <Flex
              pb="5px"
              alignItems="center"
              justifyContent="space-between"
              key={each.key}
            >
              <Text
                textStyle={each.link ? "primary.link" : "primary.secondaryText"}
                cursor={each.link && "pointer"}
                fontWeight="bold"
                onClick={() => {
                  if (each.link) {
                    navigate(`${each.link}`);
                  }
                }}
              >
                {each.key}
              </Text>
              <Text textStyle="primary.bold">
                {each.value ? each.value : "-"}
              </Text>
            </Flex>
          ))}
          <Divider py="8px" />
        </Box>
      )}
      <Box px="10px" mt={contextSummary ? "0px" : "1.5rem"}>
        {totals.map((each) => (
          <Flex
            pb="5px"
            alignItems="center"
            justifyContent="space-between"
            key={each.key}
          >
            <Tooltip
              hasArrow
              label={toolTipBody(each.tooltip)}
              bg="#fff"
              color="black"
              padding="14px"
              borderRadius="10px"
              boxShadow="2xl"
              placement="top"
            >
              <Text
                textStyle={
                  each.tooltip ? "primary.link" : "primary.secondaryText"
                }
                cursor={each.tooltip && "pointer"}
                fontWeight="bold"
              >
                {each.key}
              </Text>
            </Tooltip>
            <Text textStyle="primary.bold">
              {each.value ? each.value : "-"}
            </Text>
          </Flex>
        ))}
      </Box>
      {summary && <Divider py="8px" />}
      {summary && (
        <Flex
          px="10px"
          alignItems="center"
          justifyContent="space-between"
          pt="8px"
        >
          <Text
            textStyle={termAmount ? "primary.paragraph" : "primary.large"}
            fontWeight="bold"
          >
            {summary.key}
          </Text>
          <Text
            textStyle={termAmount ? "primary.paragraph" : "primary.large"}
            fontWeight="bold"
          >
            ₹ {summary.value ? summary.value : "-"}
          </Text>
        </Flex>
      )}
      {termAmount !== undefined && <Divider py=".5rem" />}
      {termAmount !== undefined && (
        <Flex
          mt=".5rem"
          padding="10px 8px"
          alignItems="center"
          justifyContent="space-between"
          borderRadius=".5rem"
          bg="rgba(0, 135, 238, 0.1)"
        >
          <Flex alignItems="center" gap=".25rem">
            <Text textStyle="primary.large" fontWeight="bold">
              Pay Now
            </Text>
            <Text textStyle="primary.largeSecondaryText">(Advance)</Text>
          </Flex>
          <Text textStyle="primary.large" fontWeight="bold">
            ₹ {termAmount ? termAmount : "0"}
          </Text>
        </Flex>
      )}
      {nextAction && (
        <DarkButton
          isLoading={isButtonLoading}
          w="100%"
          isDisabled={nextAction?.disabled || isButtonLoading}
          mt="1rem"
          onClick={() => {
            nextAction.nextActionFunction
              ? nextAction?.nextActionFunction()
              : navigate(nextAction.link);
          }}
        >
          {nextAction?.title}
        </DarkButton>
      )}
      {termsAndConditions && (
        <Box>
          <Flex alignItems="flex-start" mt="1rem" gap="6px">
            <Checkbox onChange={onChangeTermsAndConditions} />
            <Box>
              <Text textStyle="primary.text">{termsAndConditions}</Text>
              <Text textStyle="primary.text">
                I also agree to the &nbsp;
                <Link
                  color="#3E97FF"
                  onClick={() => setShowTermsAndConditions(true)}
                >
                  Purchase Terms and Conditions
                </Link>
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
      <BasicDialog
        size="2xl"
        isOpen={showTermsAndConditions}
        onClose={() => setShowTermsAndConditions(false)}
        showCloseButton={true}
        header="Purchase Terms and Conditions"
        content={
          <Box padding=".5rem" maxHeight="60vh" overflowY="scroll">
            <Text dangerouslySetInnerHTML={{ __html: terms }}></Text>
            <Flex justifyContent="flex-end">
              <ThemeButton
                children="Close"
                onClick={() => setShowTermsAndConditions(false)}
              />
            </Flex>
          </Box>
        }
      />
    </Box>
  );
};
