import * as React from "react";
import { Image, ImageProps } from "@chakra-ui/react";
import { getFileType } from "@karkhanaui/react";
import {
  FaFilePdf,
  FaFileExcel,
  FaFileWord,
  FaFileImage,
  FaFilePowerpoint,
  FaFileVideo,
} from "react-icons/fa";
import { BsGear } from "react-icons/bs";

export interface thumbailProps {
  fileUrl: string;
  isPrivateFile?: boolean;
  alternativeImg?: ImageProps;
}

export const Thumbnail = ({
  fileUrl,
  isPrivateFile,
  alternativeImg,
}: thumbailProps) => {
  switch (getFileType(fileUrl)) {
    case "image":
      return <FaFileImage opacity="0.2" size="32px" />;
    case "pdf":
      return <FaFilePdf opacity="0.2" size="32px" />;
    case "spreadSheet":
      return <FaFileExcel opacity="0.2" size="32px" />;
    case "word":
      return <FaFileWord opacity="0.2" size="32px" />;
    case "CAD_File":
      return <BsGear opacity="0.2" size="32px" />;
    case "presentation":
      return <FaFilePowerpoint opacity="0.2" size="32px" />;
    case "video":
      return <FaFileVideo opacity="0.2" size="32px" />;
    default:
      return <BsGear opacity="0.2" size="32px" />;
  }
};
