import * as React from "react";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
import { ThemeButton } from "@karkhanaui/react";

interface quoteProps {
  rfqId: string;
  closeAction: Function;
  imgURL: string;
}
export const QuoteSubmitted = (props: quoteProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mb="1.5rem"
    >
      <Box
        width="14.375rem"
        height="14.375rem"
        mt="2rem"
        borderRadius="0.75rem"
        bg="#0087EE"
        objectFit="contain"
      >
        <Image src={props.imgURL} borderRadius="12px" width="100%" />
      </Box>
      <Text textStyle="primary.header" mt="2rem" textAlign="center">
        Your RFQ has been Submitted to karkhana.io
      </Text>
      <Flex alignItems="center" mt="0.75rem">
        <Text textStyle="primary.bold">Quote ID: &nbsp;</Text>
        <Text textStyle="primary.text">{props.rfqId}</Text>
      </Flex>
      <ThemeButton
        children="View RFQ"
        mt="2rem"
        width="14.375rem"
        bg="#0087EE"
        onClick={() => {
          props.closeAction();
        }}
      />
    </Flex>
  );
};
