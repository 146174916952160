import * as React from "react";
import {
  Box,
  Flex,
  Text,
  CircularProgressLabel,
  CircularProgress,
  Button,
  extendTheme,
  ChakraBaseProvider,
  Show,
  Hide,
} from "@chakra-ui/react";

// import {  } from "./circularProgress"

const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

const theme = extendTheme({ breakpoints });

interface SemicircularProgressProps {
  percentage: any;
  colorScheme: string;
  title: string;
  actionTitle?: string;
  actionFunction?: any;
  buttonBgColor?: any;
  buttonHoverColor?: any;
  buttonWidth?: any;
  trackBG?: string;
}

export const SemicircularProgress = ({
  percentage,
  colorScheme,
  title,
  actionTitle,
  actionFunction,
  buttonBgColor,
  buttonHoverColor,
  buttonWidth,
  trackBG,
}: SemicircularProgressProps) => {
  const value = percentage === 100 ? 99 / 2 : percentage / 2;
  return (
    <ChakraBaseProvider theme={theme}>
      <Flex
        flexDirection="column"
        alignItems="center"
        gap="1rem"
        fontFamily="Rubik"
      >
        <Hide below="md">
          <Text fontSize="16px" fontWeight="semibold">
            {title}
          </Text>
        </Hide>
        <Box
          height={"110px"}
          padding={0}
          overflow="hidden"
          position={"relative"}
        >
          <CircularProgress
            value={value}
            size={{ base: "125px", sm: "150px", lg: "200px" }}
            transform="rotate(270deg)"
            capIsRound={true}
            marginTop="0"
            color={percentage <= 30 ? "red.400" : colorScheme}
            position="relative"
            trackColor="transparent"
            // zIndex={99}
            zIndex={2}
          />
          <CircularProgress
            value={value}
            size={{ base: "125px", sm: "150px", lg: "200px" }}
            transform="rotate(270deg)"
            capIsRound={true}
            marginTop="0"
            color={"#E4E6EF"}
            position="absolute"
            trackColor="transparent"
            thickness="1px"
            left="0"
            top="0"
            // zIndex={99999}
            zIndex={3}
          />
          <CircularProgress
            value={99 / 2}
            size={{ base: "125px", sm: "150px", lg: "200px" }}
            transform="rotate(270deg)"
            capIsRound={true}
            marginTop="0"
            color={trackBG || "#E4E6EF"}
            position="absolute"
            trackColor="transparent"
            left="0"
            top="0"
            zIndex={0}
          />
          <CircularProgress
            value={99 / 2}
            size={{ base: "125px", sm: "150px", lg: "200px" }}
            transform="rotate(270deg)"
            capIsRound={true}
            marginTop="0"
            color={"#E3E4E9"}
            position="absolute"
            trackColor="transparent"
            thickness="1px"
            left="0"
            top="0"
            // zIndex={10}
            zIndex={1}
          />

          <CircularProgressLabel
            fontSize={{ base: "13px", sm: "16px", lg: "1.5rem" }}
            position="absolute"
            margin={0}
            top={{ base: "50%", sm: "60%", lg: "80%" }}
            fontWeight={900}
          >
            <Text
              fontSize={{ base: "12px", lg: "13px" }}
              color={colorScheme || "rgba(181, 181, 195, 1)"}
              fontWeight="semibold"
              margin={0}
            >
              Completed
            </Text>
            <Text
              fontWeight={900}
              color="rgba(63, 66, 84, 1)"
              fontSize={{ base: "md", md: "xl", lg: "3xl" }}
              fontFamily="Rubik, san-serif"
            >
              {percentage}%
            </Text>
          </CircularProgressLabel>
        </Box>
        {actionTitle && (
          <Hide below="md">
            <Button
              bg={buttonBgColor ? buttonBgColor : colorScheme}
              color="white"
              marginTop="1rem"
              width={buttonWidth ? buttonWidth : "100%"}
              p="0.5em"
              onClick={actionFunction}
              _hover={{
                backgroundColor: buttonHoverColor
                  ? buttonHoverColor
                  : "#0066B4",
              }}
              fontSize="14px"
              children={actionTitle}
            />
          </Hide>
        )}
      </Flex>
    </ChakraBaseProvider>
  );
};
