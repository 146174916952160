import React, { Children, useEffect } from "react";
import { get_doc, setNotificationAsRead } from "@karkhanaui/react";
import karkhanaLogo from "../img/Kkonnect.png";

import PageLoaderURL from "../img/loader.gif";
import { BiChevronRight } from "react-icons/bi";
import { AppShell } from "../Appshell";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Link,
  Location,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { startCase } from "lodash";

function isMobile() {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}


const makeBreadCrumb = (location: Location) => {
  if(isMobile())
  {
    const _path = location.pathname;
    const _pathLength = _path.split("/").length;

    const breadcrumbData: { link: string; title: string }[] = [];

    if (_pathLength === 2) {
      breadcrumbData.push({
        link: "/",
        title: "Home",
      });
      if (_path.split("/")[1].toLowerCase() === "home") {
        breadcrumbData.slice(0, 1);
      } else {
        breadcrumbData.push({
          link: _path,
          title:
            startCase(_path.split("/")[1]).toLowerCase() === "rfqmain"
              ? "RFQ List"
              : startCase(_path.split("/")[1]),
        });
      }
    } else if (_pathLength > 2) {
      if (_path.startsWith("/rfqpreview")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: "/rfqmain",
            title: "RFQ List",
          },
          {
            link: `/rfqpreview/${maps[2]}`,
            title: maps[2],
          },
          {
            link: `/rfqpreview/${maps[2]}/quotes`,
            title: maps[3],
          }
        );
      } else if (_path.startsWith("/supplierrecords")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: "/supplier",
            title: "Suppliers",
          },
          {
            link: `/supplierrecords/${maps[2]}`,
            title: maps[2],
          }
        );
      } else if (_path.startsWith("/comparequotes")) {
        const maps = _path.split("/");
        breadcrumbData.push({
          link: `/comparequotes/${maps[2]}`,
          title: `Compare Quotes > ${maps[2]}`,
        });
      } else if (_path.startsWith("/item")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: "/items",
            title: "Item Library",
          },
          {
            link: `/item/${maps[2]}`,
            title: maps[2],
          }
        );
      } else if (_path.startsWith("/shareRfq")) {
        const maps = _path.split("/");
        breadcrumbData.push({
          link: `/shareRfq/${maps[2]}`,
          title: `Share RFQ > ${maps[2]}`,
        });
      } else if (
        _path.startsWith("/addDetails") ||
        _path.startsWith("/addrfqdetails") ||
        _path.startsWith("/review&submit")
      ) {
        breadcrumbData.push({
          link: _path.startsWith("/addDetails")
            ? "/addDetails"
            : _path.startsWith("/addrfqdetails")
            ? "/addrfqdetails"
            : "/review&submit",
          title: "Request for Quotation",
        });
      }
    }
    return (
      <Breadcrumb spacing="8px" separator={<BiChevronRight color="gray.500" />}>
        {breadcrumbData.map((each, index) => (
          <BreadcrumbItem key={each.title}>
            <BreadcrumbLink
              fontSize={isMobile() ? "0.725rem" : "16px"}
              fontWeight="bold"
              fontFamily="Rubik"
              letterSpacing="1px"
              href={each.link}
            >
              {each.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    );
  }
  else {
    const _path = location.pathname;
    const _pathLength = _path.split("/").length;

    const breadcrumbData: { link: string; title: string }[] = [];

    if (_pathLength === 2) {
      breadcrumbData.push({
        link: "/",
        title: "Home",
      });
      if (_path.split("/")[1].toLowerCase() === "home") {
        breadcrumbData.slice(0, 1);
      }
      else if (_path.startsWith("/rfq-matching")) {
        breadcrumbData.push(
          {
            link: "/rfq-matching",
            title: "SupplierSage AI", // Correct label
          }
        );
      }
      
      else {
        breadcrumbData.push({
          link: _path,
          title:
            startCase(_path.split("/")[1]).toLowerCase() === "rfqmain"  
              ? "RFQ List" 
              :startCase(_path.split("/")[1]).toLowerCase() === "purchasepage"
              ?  "PO List"
              : startCase(_path.split("/")[1]),
              
        });
      }
    } 
    else if (_pathLength > 2) {
      if (_path.startsWith("/rfqpreview")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: "/rfqmain",
            title: "RFQ List",
          },
          {
            link: `/rfqpreview/${maps[2]}`,
            title: maps[2],
          },
          {
            link: `/rfqpreview/${maps[2]}/quotes`,
            title: maps[3],
          }
        );
      } 
      else if (_path.startsWith("/supplierrecords")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: "/supplier",
            title: "Suppliers",
          },
          {
            link: `/supplierrecords/${maps[2]}`,
            title: maps[2],
          }
        );
      } 

    
      else if (_path.startsWith("/comparequotes")) {
        const maps = _path.split("/");
        breadcrumbData.push({
          link: `/comparequotes/${maps[2]}`,
          title: `Compare Quotes > ${maps[2]}`,
        });
      } 
      else if (_path.startsWith("/item")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: "/items",
            title: "Item Library",
          },
          {
            link: `/item/${maps[2]}`,
            title: maps[2],
          }
        );
      } 
      else if (_path.startsWith("/shareRfq")) {
        const maps = _path.split("/");
        breadcrumbData.push({
          link: `/shareRfq/${maps[2]}`,
          title: `Share RFQ > ${maps[2]}`,
        });
      } 
      else if (
        _path.startsWith("/addDetails") ||
        _path.startsWith("/addrfqdetails") ||
        _path.startsWith("/review&submit")
      ) {
        breadcrumbData.push({
          link: _path.startsWith("/addDetails")
            ? "/addDetails"
            : _path.startsWith("/addrfqdetails")
            ? "/addrfqdetails"
            : "/review&submit",
          title: "Request for Quotation",
        });
      }
      else if (_path.startsWith("/purchasesummary")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: "/purchasepage",
            title: "All POs",
          },
          {
            link: `/purchasepage/${maps[2]}`,
            title: `Summary - ${maps[2]}`,
          },
        );
      }
      else if (_path.startsWith("/trackorder")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: `/trackorder/${maps[2]}`,
            title: `Track Order - ${maps[2]}`,
          },
        );
      }
      else if (_path.startsWith("/addpurchase")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: `/addpurchase/${maps[2]}`,
            title: `Create PO`,
          },
        );
      }
      else if (_path.startsWith("/createpo")) {
        const maps = _path.split("/");
        breadcrumbData.push(
          {
            link: `/createpo/${maps[2]}`,
            title: `Create PO`,
          },
        );
      }

    }
    return (
      <Breadcrumb spacing="8px" separator={<BiChevronRight color="gray.500" />}>
        {breadcrumbData.map((each, index) => (
          <BreadcrumbItem key={each.title}>
            <BreadcrumbLink
              fontSize={isMobile() ? "0.725rem" : "14px"}
              fontWeight="medium"
              fontFamily="Rubik"
              letterSpacing="1px"
              href={each.link}
            >
              {each.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    );
  } 
  
};

const PrivateLayout = ({
  Component,
}: {
  Component: React.LazyExoticComponent<React.FC<any>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile] = useMediaQuery("(max-width: 780px)");
  const [notification, setNotification] = React.useState([]);
  const [bread, setBread] = React.useState();

  useEffect(() => {
    setBread(makeBreadCrumb(location, isMobile));
  }, [location]);

  // useEffect(() => {
  //   fetchNotifications();
  // }, []);
  const fetchNotifications = async () => {
    await get_doc({
      doc: "Notification Log",
      fields: ["*"],
    }).then((s) => {
      setNotification(
        s.data.sort((a: any, b: any) => {
          return new Date(b.creation) - new Date(a.creation);
        })
      );
    });
  };

  const navigateToDoc = async (link: string, notificationId: string) => {
    navigate("/");
    await setNotificationAsRead(notificationId)
      .then((res) => fetchNotifications())
      .catch((error) => console.log(error));
    navigate(link);
  };

  return (
    <AppShell
      children={Component}
      imageURL={karkhanaLogo}
      pageLoaderURL={PageLoaderURL}
      notifications={notification}
      bread={bread}
      navigateToDoc={navigateToDoc}
    />
  );
};

export default PrivateLayout;
