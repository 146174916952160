import { Text, Box, Flex, Tooltip } from "@chakra-ui/react";
import * as React from "react";
import { BsDownload } from "react-icons/bs";
import { BsXLg } from "react-icons/bs";

export interface FileProps {
  active: boolean;
  imgSrc: string;
  fileName: string;
  date?: string;
  fileSize?: string;
  allowDownload?: boolean;
  thumbnail?: any;
  isMaxWidth?: boolean;
  allowDelete?: boolean;
  deleteFile?: Function;
  colorTheme?: string;
}

export const File = (props: FileProps) => {
  let key = "inactive";

  if (props.active) {
    key = "active";
  }

  const activeConfig = {
    active: {
      border:
        props.colorTheme === "green"
          ? "1px solid #4BA18A"
          : "1px solid #0087EE",
      bg: props.colorTheme === "green" ? "#E2F1EE" : "rgba(0, 135, 238, 0.2)",
    },
    inactive: {
      border: "1px solid rgba(0, 0, 0, 0.1);",
      bg: "#fff",
    },
  };

  const getFileName = (fileName: string) => {
    let processedFileName = fileName;
    let fileExtension = fileName.split(".").pop();
    if (fileName.length <= 15) {
      processedFileName = fileName;
    } else {
      processedFileName = `${fileName.slice(0, 15)}...${fileExtension}`;
    }
    return processedFileName;
  };

  return (
    <Flex
      alignItems="center"
      padding="10px 12px"
      border={activeConfig[key].border}
      bg={activeConfig[key].bg}
      borderRadius="6px"
      minWidth="8.5rem"
      maxWidth={props.isMaxWidth ? "100%" : "20rem"}
      minHeight="4.625rem"
    >
      {props.thumbnail && <Box mr="1rem">{props.thumbnail}</Box>}
      <Box>
        <Flex alignItems="center" flexWrap="wrap">
          <Tooltip label={props.fileName}>
            <Text
              textStyle="primary.paragraph"
              mr="7px"
              wordBreak="break-word"
              fontWeight="bold"
            >
              {getFileName(props.fileName)}
            </Text>
          </Tooltip>
          {props.fileSize && (
            <Text textStyle="primary.secondaryText">{props.fileSize}</Text>
          )}
        </Flex>
        {props.date && (
          <Text textStyle="primary.secondaryText">{props.date}</Text>
        )}
      </Box>
      {props.allowDownload && (
        <Box
          bg={
            props.colorTheme === "green" ? "#8BC8B8" : "rgba(0, 135, 238, 0.2)"
          }
          style={{ marginLeft: "auto", marginRight: "2px" }}
          p="5px"
          borderRadius="6px"
          onClick={() => window.open(props.imgSrc)}
          cursor="pointer"
        >
          <BsDownload size="22px" />
        </Box>
      )}
      {props.allowDelete && (
        <Box
          style={{ marginLeft: "auto" }}
          onClick={() => props.deleteFile()}
          cursor="pointer"
        >
          <BsXLg />
        </Box>
      )}
    </Flex>
  );
};
