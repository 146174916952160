import axios from "axios";

export const supplierGetAllQuotationsService = (data: any) => {
  return axios.get("/api/resource/Quotation", { params: data.params });
};

export const supplierCreateQuotationService = (data: any) => {
  return axios.post("/api/resource/Quotation", data);
};

export const supplierGetSingleQuotationService = (quoteId: any) => {
  return axios.get(`/api/resource/Quotation/${quoteId}`);
};

export const supplierUpdateQuotationService = (quoteId: string, data: any) => {
  return axios.put(`/api/resource/Quotation/${quoteId}`, data);
};

export const supplierDeleteQuotationService = async (quoteId: string) => {
  return await axios.delete(`/api/resource/Quotation/${quoteId}`);
};
