
export function formatSize(bytes: number) {
	if (typeof bytes !== "number") {
	  return "";
	}
  
	const KB = 1e3,
	  MB = 1e6,
	  GB = 1e9;
  
	if (bytes < MB) {
	  return (bytes / KB).toFixed(2) + " KB";
	}
  
	if (bytes < GB) {
	  return (bytes / MB).toFixed(2) + " MB";
	}
  
	return (bytes / GB).toFixed(2) + " GB";
  }