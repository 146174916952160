import { Box, Text, Flex } from "@chakra-ui/react";
import * as React from "react";
import {
  File,
  FileProps,
  normalDate,
} from "@karkhanaui/react";
import { FaRegCommentDots } from "react-icons/fa";

interface OrderUpdateCardProps {
  active: boolean;
  index: number;
  datetime: string;
  update: string;
  keyVal: {
    key: string;
    val: string;
  }[];
  files?: FileProps[];
  commentCount?: number;
  showComments: any;
}

export const OrderUpdateCard = ({
  active = false,
  index,
  datetime,
  update,
  keyVal,
  files,
  commentCount,
  showComments,
}: OrderUpdateCardProps) => {
  let key = "inactive";

  if (active) {
    key = "active";
  }

  const activeConfig = {
    active: {
      border: "1px solid #0087EE;",
      boxShadow: "none",
    },
    inactive: {
      border: "none",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
    },
  };

  const [showAllFiles, setShowAllFiles] = React.useState<boolean>(false);

  return (
    <Box
      bg="#fff"
      borderRadius="6px"
      p="1rem"
      border={activeConfig[key].border}
      boxShadow={activeConfig[key].boxShadow}
    >
      <Flex alignItems="flex-start" gap="1rem">
        {/* <Flex
          w="2.25rem"
          h="2.25rem"
          textAlign="center"
          bg="#EFF2F5"
          borderRadius="50%"
          justifyContent="center"
          alignItems="center"
        >
          <Text textStyle="primary.contentTitle">{index}</Text>
        </Flex> */}
        <Box width="75%">
          <Text textStyle="primary.secondaryText">{normalDate(datetime)}</Text>

          {/* <Text textStyle="primary.contentTitle">{update}</Text> */}
          <Text  textStyle="primary.contentTitle" dangerouslySetInnerHTML={{ __html: update }} /> 

          {/* {keyVal && (
            <Flex direction="row" mt="9px" gap="2rem">
              {keyVal.map((each) => (
                <Box key={each.key}>
                  <Text textStyle="primary.secondaryText" m="0px">
                    {each.key}
                  </Text>
                  <Text textStyle="primary.large" color="#0087EE" m="0px">
                    {each.val}
                  </Text>
                </Box>
              ))}
            </Flex>
          )} */}
          {files && (
            <Flex
              justifyContent="space-between"
              gap="10px"
              alignItems="center"
              mt="1.75rem"
              overflowX="scroll"
              flexWrap="wrap"
            >
              {files
                .slice(0, showAllFiles ? files.length : 3)
                .map((each, index) => (
                  <Box cursor="pointer" key={index} flex="1 1 0px">
                    <File
                      key={index}
                      active={each.active}
                      date={each.date}
                      fileName={each.fileName}
                      fileSize={each.fileSize}
                      imgSrc={each.imgSrc}
                      thumbnail={each.thumbnail}
                      allowDownload={true}
                    />
                  </Box>
                ))}

              {files.length - 3 > 0 && !showAllFiles ? (
                <Text
                  textStyle="primary.bold"
                  color="main.500"
                  cursor="pointer"
                  onClick={() => setShowAllFiles(!showAllFiles)}
                >
                  +{files.length - 3} More
                </Text>
              ) : (
                ""
              )}
            </Flex>
          )}
        </Box>
        {/* <Flex justifySelf="flex-end">
          {commentCount ? (
            <Button
              variant="ghost"
              border="1px solid #50B848"
              bg="#E8FFF3"
              _hover={{ bg: "#E8FFF3" }}
              onClick={showComments}
            >
              <Flex alignItems="center" gap="4px">
                <FaRegCommentDots />
                <Text>{commentCount}</Text>
              </Flex>
            </Button>
          ) : (
            <ThemeButton onClick={showComments}>
              <Flex alignItems="center" gap="4px">
                <FaRegCommentDots />
                <Text>Add Comment</Text>
              </Flex>
            </ThemeButton>
          )}
        </Flex> */}
      </Flex>
    </Box>
  );
};
