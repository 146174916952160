import axios from "axios";

export const submitDoc = (data: any) => {
  return axios.put("/api/method/supplierv2.api.utils.submit_doc", data);
};

export const cancelDoc = (data: any) => {
  return axios.put("/api/method/supplierv2.api.utils.cancel_doc", data);
};

export const supplierUpdateNotificationService = (id: string, data: any) => {
  return axios.put("/api/resource/Notification Log" + id, data);
};
