import axios from "axios";

export const frappeMethodService = async (method: string, params: any) => {
  return await (
    await axios.post(
      process.env.REACT_APP_AUTH_LIVE_URL + `/api/method/${method}`,
      params
    )
  );
};
