import * as React from "react";
import {
  Box,
  
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { AiOutlinePaperClip } from "react-icons/ai";

export const ChatFileUpload =  (props: any) => {
    const [files, setFiles] = React.useState<File[]>([]);

    const onDrop = React.useCallback(
      (acceptedFiles: any) => {
        props?.updatedFile(acceptedFiles);
        setFiles(acceptedFiles);
      },
      [props]
    );
    const {
        getRootProps,
        getInputProps,
        open,
       
      } = useDropzone({
        onDrop,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        multiple: props.isMultiple,
      });
    return(
        <Box {...getRootProps()}>
              <AiOutlinePaperClip  size="1.5rem" onClick={open}/>
              
        </Box>
    )
}