import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ImFolderOpen } from "react-icons/im";
import { useSelector } from "react-redux";
import {SubTitleText} from "@karkhanaui/react";

export const NoData: React.FC<any> = ({ children, ...rest }) => {
  const [pageLoading, apiLoading] = useSelector((state: any) => [
    state.pageLoading,
    state.apiLoading,
  ]);
  const [isLoading, setIsLoading] = useState(true);

  function delayStateChange() {
    // Delyed state change; because "No data" is visible for an instant just after loading has finished
    // This gives react time to render the required UI
    function setState() {
      setIsLoading(apiLoading || pageLoading.loading);
    }

    setTimeout(setState, 0);
  }

  useEffect(delayStateChange, [apiLoading, pageLoading.loading]);

  return isLoading ? null : (
    <Flex
      justify="center"
      direction="column"
      align="center"
      w={"100%"}
      h={"100%"}
      {...rest}
    >
      <ImFolderOpen size={60} color="lightgray" />
      <SubTitleText textAlign={"center"}>{children}</SubTitleText>
    </Flex>
  );
};


