import {  Stack, Image, Text,Box} from '@chakra-ui/react'
import * as React from 'react'
import {BsDownload} from "react-icons/bs"

interface FileProps {
	imgSrc: string,
	fileName: string,
	date?: string,
	fileSize?: string,
	allowDownload?: boolean
}

export const FilePreviewCard =  (props: FileProps) => {

	return (
		<Stack bg="#fff" borderRadius="6px">
			<Image src={props.imgSrc} alt={props.fileName} maxHeight="300px" borderRadius="6px"></Image>

			<Stack alignItems="center" direction="row" p="20px">
				<Image src={props.imgSrc} w="52px" mr="15px" />
				<Stack>
					<Stack direction="row" alignItems="center">
						<Text m="5px" textStyle="primary.contentTitle">{props.fileName}</Text>
						{props.fileSize && <Text textStyle="primary.secondaryText">{props.fileSize}</Text>}
					</Stack>
					{props.date && <Text textStyle="primary.secondaryText">{props.date}</Text>}
				</Stack>
				<Box  style={{marginLeft: "auto"}} p="5px" borderRadius="6px">
					{props.allowDownload && <BsDownload size="22px" />}
				</Box>
			</Stack>
		</Stack>
	)
}
