import * as React from 'react'
import { Text, Box, Stack, CircularProgress, Center } from "@chakra-ui/react";
import { sortedIndex } from 'lodash';

interface timelineData {
    data: any[];
  }
export const ActivityTimelineCard =  ({data}:timelineData) =>{
    const [actualTime,setActualTime]=React.useState("");
    const [INDEX,setIndex]=React.useState();

const tellTime=(item:any,index:any)=>{
    setActualTime(item.date+" "+item.time)
    setIndex(index);
    
}
const callTime=(item:any)=>{
    let dd = new Date();
    let d1 = item.date.split('/');
    var s = d1[2]+"-"+d1[1]+"-"+d1[0];
    var dg=new Date(`${s} ${item.time}`);
    var diff=dd.getTime()-dg.getTime();
    return (friendlyTimeDiff(diff));

}
const friendlyTimeDiff =( timeDifferenceMilliseconds:number)=> {
    let diffSeconds = timeDifferenceMilliseconds / 1000;
    let diffMinutes = timeDifferenceMilliseconds / (60 * 1000);
    let diffHours = timeDifferenceMilliseconds / (60 * 60 * 1000);
    let diffDays = timeDifferenceMilliseconds / (60 * 60 * 1000 * 24);
    let diffWeeks = timeDifferenceMilliseconds / (60 * 60 * 1000 * 24 * 7);
    let diffMonths =  (timeDifferenceMilliseconds / (60 * 60 * 1000 * 24 * 30.41666666));
    let diffYears = timeDifferenceMilliseconds / (60 * 60 * 1000 * 24 * 365);

    if (diffSeconds < 1) {
        return "less than a second";
    } else if (diffMinutes < 1) {
        return Math.round(diffSeconds) + " seconds";
    } else if (diffHours < 1) {
        return Math.round(diffMinutes) + " minutes";
    } else if (diffDays < 1) {
        return Math.round(diffHours) + " hours";
    } else if (diffWeeks < 1) {
        return Math.round(diffDays) + " days";
    } else if (diffMonths < 1) {
        return Math.round(diffWeeks) + " weeks";
    } else if (diffYears < 1) {
        return Math.round(diffMonths) + " months";
    } else {
        return Math.round(diffYears) + " years";
    }
}
    return(
       
            <Box bg="white" borderRadius="lg" width="md" overflowY="scroll" height="40rem" position="relative" zIndex={0}>
              
              <Stack m="2rem" position="absolute">
                {data &&
                  data.map((item,index) => (
                    <Box key={index}>
                      <Stack isInline>
                      
                        <Box  display = "flex" minHeight="40px" gap="1.5rem" >
                          {/* <Text textStyle="primary.secondaryText" fontSize="12px" mt="0px">
                            {item.date}
                         
                          </Text> */}
                          <Box display="flex" flexDirection="column" alignItems="center">
                          <CircularProgress value={100} size="20px"   thickness="20px"/>
                         
                            <Box width="2px" bg="gray" height="100%"></Box>
                          </Box>
                        
                          <Text onMouseEnter={()=>tellTime(item,index)} onMouseLeave={()=>setActualTime("")} textStyle="primary.extraLarge" fontSize="15px" mt="0px" >
                            {item.title} 
                            <div style={{fontSize:"13px",color:"GrayText"}}>
                            {callTime(item)} ago 
                            </div>
                            
                            {INDEX==index? <div style={{fontSize:"10px"}}>{actualTime}</div>:""}
                           
                          </Text>
                          
                        </Box>
                      </Stack>
                    </Box>
                  ))}
              </Stack> 
               
            </Box>
        
    )




};
