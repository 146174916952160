import * as React from "react";
import {
  getCustomerUpdateService,
  getQuotationService,
  normalDate,
  getQuoteService,
  SET_MESSAGE,
  ERROR_MSG,
  formatErrorMessage,
  AppDispatch,
} from "@karkhanaui/react";

import { useDispatch } from "react-redux";
import { Box, Flex, Text } from "@chakra-ui/react";

interface notification {
  creation: string;
  subject: string;
  document_type: string;
  document_name: string;
  read: number;
  name: string;
}

interface notificationProps {
  notificationList?: notification[];
  navigate: Function;
}

export const NotificationCard = ({
  notificationList,
  navigate,
}: notificationProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const getLink = async (notification: notification) => {
    let link = "";
    switch (notification.document_type) {
      case "Opportunity":
        link = `/instantQuote/newQuote/${notification.document_name}`;
        break;
      case "Quotation":
        try {
          let quotation: any = await getQuotationService(
            notification.document_name
          );
          link = `/instantQuote/newQuote/${quotation.data?.data.opportunity}`;
        } catch (error) {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              case: ERROR_MSG,
              data: "OOpsie Error " + formatErrorMessage(error),
            },
          });
        }
        break;
      case "Sales Order":
        link = `/orders/${notification.document_name}/orderSummary`;
        break;
      case "Customer Update":
        try {
          let update: any = await getCustomerUpdateService(
            notification.document_name
          );
          let opportunity: any = await getQuoteService(
            update.data.data?.opportunity
          );
          link = `/orders/${opportunity.data?.data.sales_order}/orderSummary`;
        } catch (error) {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              case: ERROR_MSG,
              data: formatErrorMessage(error),
            },
          });
        }

        break;
    }

    return link;
  };

  return (
    <Box zIndex={99999999}>
      <Text
        textStyle="primary.title"
        fontFamily={"Rubik"}
        fontSize="14px"
        padding=".5rem"
      >
        Notifications
      </Text>
      <hr />
      <Box
        mt="1rem"
        maxHeight="45vh"
        overflowY="scroll"
        width="100%"
        zIndex={99999999}
      >
        {notificationList?.map((item: notification, index: number) => (
          <Box key={index} width="100%" cursor="pointer">
            <Flex
              gap=".5rem"
              _hover={{ bg: "rgba(0, 135, 238, 0.2)" }}
              padding=".5rem"
              bg={item.read ? "" : "#F7F7F7"}
              onClick={async () => navigate(await getLink(item), item.name)}
              width="100%"
            >
              <Box width="100%">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text textStyle="primary.secondaryText" fontSize=".75rem">
                    {normalDate(item?.creation)}
                  </Text>
                  <Text textStyle="primary.secondaryText" fontSize=".75rem">
                    {item?.creation.substring(10, 16)}
                  </Text>
                </Flex>
                <Box width="100%">
                  <Text
                    textStyle="primary.text"
                    fontWeight={item.read ? "" : "semibold"}
                  >
                    {item.subject.replace(/<[^>]*>/g, "")}
                  </Text>
                </Box>
              </Box>
            </Flex>
            <hr />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
