import { Text, Box, Flex } from "@chakra-ui/react";
import * as React from "react";

interface DetailsProps {
  details: {
    key: string;
    value: string;
  }[];
}

export const Details = ({ details }: DetailsProps) => {
  return (
    <Flex
      flexWrap="wrap"
      p="10px"
      border="1px solid"
      borderColor="main.40"
      borderRadius="4px"
      bg="#fff"
    >
      {details &&
        details.map((each) => (
          <Box w="50%" key={each.key} mb="12px">
            <Text textStyle="primary.text">{each.key}:</Text>
            <Text textStyle="primary.secondaryText">{each.value}</Text>
          </Box>
        ))}
    </Flex>
  );
};
