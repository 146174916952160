import * as React from "react";
import { Box, Flex, Text, Link } from "@chakra-ui/react";
import { DarkButton } from "@karkhanaui/button";
import { useDropzone } from "react-dropzone";
import { BiDownload } from "react-icons/bi";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { BsFileEarmarkArrowUpFill } from "react-icons/bs";

const bi = `repeating-linear-gradient(0deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(90deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(180deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px), repeating-linear-gradient(270deg, #333333, #333333 9px, transparent 9px, transparent 22px, #333333 22px);`;
const bs = `2px 100%, 100% 2px, 2px 100% , 100% 2px;`;
const bp = `0 0, 0 0, 100% 0, 0 100%;`;

export const UploadDocument = (props: any) => {
  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      props.updateFile(acceptedFiles);
    },
    [props]
  );
  const { getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: props.isMultiple,
  });
  return (
    <Box margin="10px" padding="4%" bg="#fff" height="100%">
      <Flex alignItems="center" flexDirection="column" py="5rem">
        <DarkButton
          onClick={open}
          variant="ghost"
          border="1px solid #0087EE"
          color="#0087EE"
          leftIcon={<BsFileEarmarkExcelFill style={{ color: "green" }} />}
          rightIcon={<BiDownload />}
        >
          Download the excel template{" "}
        </DarkButton>
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems="center"
        justifyContent="space-between"
        gap="2rem"
        py="5rem"
        borderRadius={"5px"}
        backgroundImage={bi}
        backgroundSize={bs}
        backgroundPosition={bp}
        backgroundRepeat="no-repeat"
      >
        <Flex lineHeight="35px" alignItems="center" flexDirection="column">
          <BsFileEarmarkArrowUpFill
            size="52px"
            style={{ color: "dodgerblue" }}
          />
          <Text textStyle="primary.text"> Drag and Drop files to upload </Text>
          <Text textStyle="primary.thin" mt=".1rem">
            OR
          </Text>
          <input {...getInputProps({})} />
          <DarkButton
            onClick={props?.onClick}
            variant="ghost"
            border="1px solid #0087EE"
            color="#0087EE"
            fontFamily="Rubik"
          >
            {isDragActive ? props?.title : "Select BOM file to upload"}
          </DarkButton>
        </Flex>
        <Flex alignItems="center" flexDirection="column"></Flex>
      </Flex>
    </Box>
  );
};
