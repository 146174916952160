import * as React from "react";
import { Tr, Td, Text, Flex, Image, Box, Stack } from "@chakra-ui/react";
import { Button, Chip } from "@karkhanaui/react";
import { BsGear, BsTruck, BsBagCheck } from "react-icons/bs";
import { IconType } from "react-icons";

interface OrderSummaryTable {
  itemName?: string;
  quantity: number;
  metadata: {
    key: string;
    value: string;
  }[];
  image: string;
  chips: {
    key: string;
    value: string;
    action: string;
  }[];
  check?: boolean;
  rfqId?: string;
  status: {
    manufacturing: string;
    inTransit: string;
    delivered: string;
  };
  lastUpdate: string;
  callTrackUpdate: any;
}

interface StatusProps {
  Icon: IconType;
  quantity: string;
  tag: string;
}

const Status = ({ Icon, quantity, tag }: StatusProps) => {
  return (
    <Flex mr="20px">
      <Flex flexDirection="column">
        <Stack alignItems="center" direction="row" spacing="6px">
          <Icon color="#0087EE" size="18px"></Icon>
          <Text textStyle="primary.large">{quantity}</Text>
        </Stack>
        <Text textStyle="primary.text" mt="6px">
          {tag}
        </Text>
      </Flex>
    </Flex>
  );
};

export const OrderSummaryTable = (props: OrderSummaryTable) => {
  return (
    <Tr bg="white" mt="10px">
      <Td borderTopLeftRadius="6px" borderBottomLeftRadius="6px" valign="top">
        <input type="checkbox" checked={props.check} />
      </Td>
      <Td valign="top">
        <Flex flexDirection="column" alignItems="center" gap="10px">
          <Box width="65px" objectFit="contain">
            <Image src={props.image} w="100%" borderRadius="5px" />
          </Box>
          {props.chips.map((each) => (
            <Chip title={each.key} count={each.value} key={each.key} />
          ))}
        </Flex>
      </Td>
      <Td valign="top">
        {props.metadata.map((each) => (
          <Flex alignItems="center" key={each.key}>
            <Text my="2px" textStyle="primary.text">
              {each.key}&nbsp;
            </Text>
            {each.value === "Configure" ? (
              <Text textStyle="primary.link">
                <b>{each.value}</b>
              </Text>
            ) : (
              <Text my="2px" textStyle="primary.secondaryText">
                <b>{each.value}</b>
              </Text>
            )}
          </Flex>
        ))}
      </Td>
      <Td valign="top">
        <Text textStyle="primary.text">{props.quantity}</Text>
      </Td>
      <Td valign="top">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          gap="4.5rem"
        >
          <Flex>
            <Status
              Icon={BsGear}
              quantity={props.status.manufacturing}
              tag="Manufacturing"
            />
            <Status
              Icon={BsTruck}
              quantity={props.status.inTransit}
              tag="In Transit"
            />
            <Status
              Icon={BsBagCheck}
              quantity={props.status.delivered}
              tag="Delivered"
            />
          </Flex>

          <Text textStyle="primary.text" display="flex" alignItems="center">
            Last Update{" "}
            <Text ml="5px" textStyle="primary.secondaryText">
              {props.lastUpdate}
            </Text>
          </Text>
        </Flex>
      </Td>
      {/* <Td borderTopRightRadius="6px" borderBottomRightRadius="6px" valign="top">
        <Button
          variant="ghost"
          border="1px solid black"
          _hover={{ bg: "#fff" }}
          children="Track Update"
          onClick={() => props.callTrackUpdate(props.itemName)}
        />
      </Td> */}
    </Tr>
  );
};
