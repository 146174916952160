import * as React from "react";
import { useEffect } from "react";
import Select from 'react-select';
import {Box, Text} from '@chakra-ui/react'
import { ErrorMessage, useField } from "formik";


export const EnhaancedDropdown = ({
  label,
  values,
  placeholder,
  disabled,
  isMandatory,
  defaultValue,
  ...props
}: any) => {
//   const [field, meta] = useField(props);
  return (
    <Box>
      <Text textStyle="primary.text" sx={{ marginBottom: "0.3rem" }}>
        {label} {isMandatory && <span style={{ color: "red" }}>*</span>}
      </Text>
      <Select
        // {...field}
        {...props}
        // isInvalid={meta.touched && meta.error}
        variant="filled"
        defaultValue={defaultValue}
        disabled={disabled}
        options={values}
        isMandatory={true}
        
      >
        
      </Select>
      {/* <ErrorMessage component="div" name={field.name} className="error" /> */}
    </Box>
  );
};
