import axios from "axios";

export const getAllSalesOrderService = async ({
  filters,
  limit_start,
}: {
  filters: any[];
  limit_start: number;
}) => {
  return await (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL +
        '/api/resource/Sales Order?fields=["*"]&order_by=modified%20desc',
      {
        params: {
          ...(filters && { filters: JSON.stringify(filters) }),
          ...(limit_start && {
            limit_start: JSON.stringify(limit_start),
            order_by: "modified desc",
          }),
        },
      }
    )
  );
};

export const getSalesOrder = (salesOrder: string) => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      `/api/resource/Sales Order/${salesOrder}`
  );
};
