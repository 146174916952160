import * as React from "react";
import { Box, Flex, Text, Button } from "@chakra-ui/react";

interface deleteUserProps {
  onCloseFunction: Function;
  onConfirmFunction: Function;
  deleteButtonLoading: boolean;
}
export const DeleteUser = ({
  onCloseFunction,
  onConfirmFunction,
  deleteButtonLoading,
}: deleteUserProps) => {
  return (
    <Box>
      <Text>
        Are you sure you want to delete this user ?<br></br>
        <br></br>
        On deleting, the user will lose access to all the RFQ/Order information.
      </Text>
      <Flex float="right" gap="1.5rem" mt="2rem">
        <Button onClick={() => onCloseFunction()}>No</Button>
        <Button
          colorScheme="red"
          isLoading={deleteButtonLoading}
          onClick={() => {
            onConfirmFunction();
          }}
        >
          Yes
        </Button>
      </Flex>
    </Box>
  );
};
