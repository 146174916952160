import * as React from "react";
import {
  Box,
  Flex,
  Text,
  Link,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Button, DarkButton, ThemeButton } from "@karkhanaui/button";
import { useDropzone } from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";

export const UploadFile = (props: any) => {
  const [files, setFiles] = React.useState<File[]>([]);

  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      props.updatedFile(acceptedFiles);
      setFiles(acceptedFiles);
    },
    [props]
  );
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: props.isMultiple,
  });

  return (
    <Box
      p="10px"
      bg="#fff"
      borderWidth="1px"
      borderStyle="dashed"
      borderColor="#0087EE"
      borderRadius={4}
      backgroundColor="rgba(0, 135, 238, 0.1)"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDir={{base: "column", md: props.column ? props.column : "row"}}
        gap="1.5rem"
        p="1rem"
        {...getRootProps()}
      >
        {/* <Image src={props.img} height="50px" width="50px"/> */}
        <Text textStyle="primary.header" fontFamily="Rubik" fontSize={16} color="gray">
          Drag & Drop files to upload
        </Text>
        <Text textStyle="primary.header" fontFamily="Rubik" fontSize={16} color="gray">
          OR
        </Text>
        <input {...getInputProps({})} />
        
        <Button
          children="Select files to upload"
          variant="ghost"
          border="1px solid #0087EE"
          _hover={{backgroundColor: '#0087ee10'}}
          color="#0087EE"
          onClick={open}
         
        />
      </Flex>
    </Box>
  );
};
