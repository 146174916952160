import{
CUSTOMER_FETCH_BEGIN,
CUSTOMER_FETCH_SUCCESS,
CUSTOMER_FETCH_FAILED,
SET_MESSAGE
}from './types'
import {getUserDetailsServiceProcure,
    formatErrorMessage,
  setMessage

} from '@karkhanaui/react'

export const getUserDetailsProcure = ()=>(dispatch:any)=>{
    dispatch({ type: CUSTOMER_FETCH_BEGIN });
  return getUserDetailsServiceProcure()
    .then((response: any) => {
      dispatch({
        type: CUSTOMER_FETCH_SUCCESS,
        payload: { customer: response.data?.message },
      });

      return Promise.resolve(response);
    })
    .catch((error) => {
      const message = formatErrorMessage(error);
      dispatch({
        type: CUSTOMER_FETCH_FAILED,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { case: CUSTOMER_FETCH_FAILED, data: message },
      });
      dispatch(setMessage(""));
      return Promise.reject(error);
    });
};
