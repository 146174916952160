import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_MESSAGE,
  SUCCESS_MSG,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  formatErrorMessage,
  authRegisterService,
  authLogoutService,
  authLoginService,
  history,
  setPageLoading,
  changePassword,
  ERROR_MSG,
} from "@karkhanaui/react";

export const register = (data: any) => (dispatch: any) => {
  return authRegisterService(data).then(
    (response: any) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { case: REGISTER_SUCCESS, data: response.data.message },
      });

      if (response.data.message === "User already exists") {
        history.push("/login");
      }
      return Promise.resolve(response);
    },
    (error) => {
      const message = formatErrorMessage(error);

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch(setPageLoading({ loading: false, loadingText: "" }));

      dispatch({
        type: SET_MESSAGE,
        payload: { case: REGISTER_FAIL, data: message },
      });

      return Promise.reject(error);
    }
  );
};

export const login =
  (username: string, password: string) => (dispatch: any) => {
    return authLoginService(username, password).then(
      (data: any) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data.data },
        });
        if (data.message === "No App")
          dispatch({
            type: SET_MESSAGE,
            payload: { case: LOGIN_SUCCESS, data: "Logged In !" },
          });
        history.push("/instantQuote");
        return Promise.resolve(data);
      },
      (error: any) => {
        const message = formatErrorMessage(error);

        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: { case: LOGIN_FAIL, data: message },
        });

        return Promise.reject(error);
      }
    );
  };

export const logout = () => (dispatch: any) => {
  return authLogoutService()
    .then((data: any) => {
      dispatch({
        type: LOGOUT,
      });
      localStorage.clear();
      sessionStorage.clear();

      window.location.reload();
      history.push("/login");
    })
    .catch((error) => {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          case: ERROR_MSG,
          data: "Failed to log out " + formatErrorMessage(error),
        },
      });
    });
};

export const changeUserPassword = (dataIn: any) => (dispatch: any) => {
  return changePassword(dataIn).then(
    (data) => {
      dispatch(setPageLoading({ loading: false, loadingText: "" }));
      const successMessage = "Password changed successfully";
      dispatch({
        type: SET_MESSAGE,
        payload: { case: SUCCESS_MSG, data: successMessage },
      });
      return Promise.resolve(data);
    },
    (error) => {
      let message: any;
      if (error.response.status === 403) {
        message = "Incorrect current password";
      } else {
        message = formatErrorMessage(error);
      }

      dispatch(setPageLoading({ loading: false, loadingText: "" }));
      dispatch({
        type: SET_MESSAGE,
        payload: { case: ERROR_MSG, data: message },
      });
      return Promise.reject(message);
    }
  );
};
