import axios from "axios";

export const createContractService = (data: any) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Contract",
    data
  );
};

export const updateContractService = (data: any) => {
  return axios.put(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Contract/" + data.name,
    data.fields
  );
};
