export const dateSearchFilter =  (searchQuery:string) => {
	let a = searchQuery.split('/');
      let s ="";
      if(a[2]!=undefined &&a[2].length==2){
        s=s+'20'+a[2];
      }
      else{
      s=s+a[2];
      }
      s=s+"-";
      if(a[1]!=undefined && a[1].length==1){
        s=s+'0'+a[1];
      }
      else{
      s=s+a[1];
      }
   
      s=s+"-";
      
      if(a[0]!=undefined && a[0].length==1){
        s=s+"0"+a[0];
      }
      else{
      s=s+a[0];
      }
      return s;
}
