export const getFileType = (fileName: string) => {
  let filetype = "";
  switch (fileName?.split(".")?.pop()?.toUpperCase()) {
    case "PDF":
      filetype = "pdf";
      break;

    case "JPG":
    case "JPEG":
    case "PNG":
    case "SVG":
      filetype = "image";
      break;
    case "XLS":
    case "XLSX":
      filetype = "spreadSheet";
      break;
    case "DXF":
      filetype = "DXF_FILE"
      break;
    case "STEP":
    case "STP":
    case "IGES":
    case "IGS":
    case "SLDPRT":
    case "STL":
    case "OBJ":
    case "SLDDRW":
    case "X_T":
      filetype = "CAD_File";
      break;
    case "DOC":
    case "DOCX":
      filetype = "word";
      break;
    case "PPT":
    case "PPTX":
      filetype = "presentation";
      break;
    case "MP4":
    case "MKV":
    case "WEBM":
    case "OGG":
      filetype = "video";
    default:
      break;
  }

  return filetype;
};
