import * as React from "react";
// import "./Timeline.css";


import { Text, Box, Stack, CircularProgress, Center } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {NoDataAvailable} from '@karkhanaui/react';


interface timelineData {
  data: any[];
  color?: any;
  zeroScreenComponent?:any;
}

export const TimelineCard = ({ data, color, zeroScreenComponent= <NoDataAvailable /> }: timelineData) => {
  const [dataEvents, setDataEvents] = useState([]); // to get dynamic data from api

  useEffect(() => {});
  return (
    <Box bg="white" borderRadius="lg" width="100%" height="100%" padding="1rem" zIndex={0} boxShadow="md" overflowY="scroll">
      <Text textStyle="primary.header" fontFamily="Rubik, sans-serif" fontSize="16px" top="1rem">
        Recent activities
      </Text>
      <Stack my="1rem" >
        {(!data || data.length <=0) && zeroScreenComponent}

        {data && data.length > 0 &&  data.map((item) => (
            <Box key={item.id}>
              <Stack isInline w="100%">
              
                <Box display = "flex" gap="1rem" w="100%">
                  <Box maxWidth="30%" width="30%">
                  <Text textStyle="primary.secondaryText" fontSize="12px" mt="0px" >
                    {(item.creation)?.slice(0,10)}
                  </Text>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center">
                  <CircularProgress value={100} size="18px"  thickness={"15px"} color={color? color.fillColor: 'blue.500'}/>
                 
                    <Box width="2px" bg="gray" height="100%" minHeight="20px"></Box>
                  </Box>
                  <Box width="50%">
                  <Text textStyle="primary.extraLarge" fontSize="12px" mt="0px" lineHeight="20px" >
                    {item.subject}
                  </Text>
                  </Box>
                </Box>
              </Stack>
            </Box>
          ))}
      </Stack> 
       
    </Box>

  )
  
};
