import * as React from "react";
import { Box, Flex, Text, Image, Grid, GridItem } from "@chakra-ui/react";

interface imgProps {
  src: string;
  name: string;
}
interface galleryProps {
  images: imgProps[];
}
export const ImageGallery = ({ images }: galleryProps) => {
  return (
    <Box maxHeight="60vh" overflowY="scroll" margin=".5rem">
      <Grid
        templateColumns={{
          xs: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap="2rem"
      >
        {images?.map((item: any, index: number) => (
          <GridItem>
            <Flex
              key={index}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap=".5rem"
            >
              <Image
                src={item.src}
                alt={item.name}
                width="12.5rem"
                height="12.5rem"
                borderRadius=".5rem"
                cursor="grab"
              />
              <Text textStyle="primary.bold" wordBreak="break-all">
                {index + 1}. &nbsp;{item.name}
              </Text>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
