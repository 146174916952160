import * as React from 'react'
import * as Yup from "yup";
import{Box, Flex, Input,Text} from '@chakra-ui/react'
import { useForm } from "react-hook-form"

import {
    ThemeButton,
    yupEmail,
    NoDataAvailable,
    RenderTextField,
    register,
  } from "@karkhanaui/react";
  import {yupResolver} from '@hookform/resolvers/yup'
  import { RiDeleteBin6Line } from "react-icons/ri";
  interface sharedWithProps {
    name: string;
    email: string;
    img: string;
  }
  interface shareCardProps {
    sharedWith?: sharedWithProps[];
    duplicateUser: any;
    showAddUserConfirmation: Function;
    showDeleteUserConfirmation: Function;
  }
export const ShareCardProcure =  ({
    sharedWith,
    duplicateUser,
    showAddUserConfirmation,
    showDeleteUserConfirmation,
  }: shareCardProps) => {
   
    
    const basicValidation = Yup.object().shape({
        email: yupEmail,
      });
      const resolver  =  yupResolver(basicValidation)
      const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm({resolver})
      const share = async (event: any) => {
        let index: any = sharedWith?.findIndex(
          (user: any) => user.email === event.email
        );
        if (index < 0) {
          showAddUserConfirmation(event.email);
        } else {
          duplicateUser();
        }
      };
     
      
    return(
            
        <Box mb="2.5rem">
            <form onSubmit={handleSubmit(share)}>
            <Flex justifyContent={"space-between"} gap="1em" >
                <Flex flexDir="column" gap="0.6em" width="90%">
                <Input
                
                 type="email"
                 {...register("email" ,{ required: true, pattern : /^[^\s@]+@[^\s@]+\.[^\s@]+$/})}
                 
                 />
                  {errors.email?.type === 'required' && <span style={{color:"red"}}>Email is required</span>}
                </Flex>
                
                 <ThemeButton bg="#0087EE" color="#fff" children="Share" type="submit"/>
            </Flex>
            </form>
           
              
     
      </Box>
    )
  }
  
