import * as React from "react";
import { Text, Flex, Link } from "@chakra-ui/react";
// import { Link, useNavigate } from "react-router-dom";

interface ChipProps {
  title: string;
  count: string;
}

export const Chip = ({ title, count }: ChipProps) => {
  if (count.length > 1) {
    count = "9+";
  }
  return (
    <Link href="/hit">
      <Flex
        alignItems="center"
        bg="main.100"
        borderRadius="4px"
        padding="3px"
        w="max-content"
        gap="3px"
      >
        <Text textStyle="primary.small">{title}</Text>
        <Text
          textStyle="primary.small"
          fontWeight="bold"
          color="#fff"
          bg="main.500"
          padding="3px"
          w="1.1rem"
          h="1.1rem"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          {count}
        </Text>
      </Flex>
    </Link>
  );
};
