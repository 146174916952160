import * as React from "react";
import { Flex, Image, useDisclosure, Text, Tooltip } from "@chakra-ui/react";
import { BasicDialog, ViewFile, getFileType } from "@karkhanaui/react";
import { FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa";
import { BsGear, BsFileEarmarkImage } from "react-icons/bs";
interface SlideProps {
  images: {
    image: any;
    subtitle: string;
    threeDFile?: any;
  }[];
  alternativeImg: any;
}

export const Slide = ({ images, alternativeImg }: SlideProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showFile, setShowFile] = React.useState<any>();

  const getImage = (item: any) => {
    switch (getFileType(item?.subtitle)) {
      case "pdf":
        return (
          <FaFilePdf
            opacity="0.2"
            cursor="pointer"
            size="5rem"
            onClick={() => window.open(item?.image)}
          />
        );
        break;
      case "image":
        let imageThumbnail: any;
        if (item.image.substr(-9) !== "undefined") {
          imageThumbnail = (
            <Image
              onClick={() => {
                setShowFile(item);
                onOpen();
              }}
              src={item.image}
              alt={item.subtitle?.substring(0, 8)}
              width="5rem"
              height="5rem"
              borderRadius="4px"
              cursor="pointer"
            />
          );
        } else {
          imageThumbnail = (
            <BsFileEarmarkImage
              size="5rem"
              onClick={() => {
                setShowFile(item);
                onOpen();
              }}
              cursor="pointer"
            />
          );
        }
        return imageThumbnail;
      case "spreadSheet":
        return (
          <FaFileExcel
            opacity="0.2"
            cursor="pointer"
            size="5rem"
            onClick={() => window.open(item?.image)}
          />
        );
      case "word":
        return (
          <FaFileWord
            opacity="0.2"
            cursor="pointer"
            size="5rem"
            onClick={() => window.open(item?.image)}
          />
        );
      case "CAD_File":
        let CadFileThumbnail: any;
        // if (item.image.substr(-9) !== "undefined") {
        //   CadFileThumbnail = (
        //     <Image
        //       onClick={() => {
        //         setShowFile(item);
        //         onOpen();
        //       }}
        //       src={item.image}
        //       alt={item.subtitle?.substring(0, 8)}
        //       width="5rem"
        //       height="5rem"
        //       borderRadius="4px"
        //       cursor="pointer"
        //     />
        //   );
        // } else {
        CadFileThumbnail = (
          <BsGear
            cursor="pointer"
            size="5rem"
            onClick={() => {
              setShowFile(item);
              onOpen();
            }}
          />
        );

        return CadFileThumbnail;

      default:
        return (
          <Image
            onClick={() => {
              setShowFile(item);
              onOpen();
            }}
            src={
              item?.image?.substr(-9) !== "undefined"
                ? item.image
                : alternativeImg
            }
            alt={item.subtitle?.substring(0, 8)}
            width="5rem"
            height="5rem"
            borderRadius="4px"
            cursor="pointer"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = { alternativeImg };
            }}
          />
        );
        break;
    }
  };

  return (
    <>
      <Flex gap="10px">
        {images &&
          images.map((each, index) => (
            <Tooltip label={each.subtitle} key={index}>
              <Flex
                key={index}
                alignItems="center"
                flexDirection="column"
                flexShrink={0}
              >
                {getImage(each)}
                <Text textStyle="primary.text">
                  {each.subtitle?.substring(0, 12)}
                </Text>
              </Flex>
            </Tooltip>
          ))}
      </Flex>
      <BasicDialog
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
        showCloseButton={true}
        header={showFile?.subtitle}
        content={
          <Flex alignItems="center" justifyContent="center">
            <ViewFile
              file_name={showFile?.subtitle}
              file_url={
                showFile?.threeDFile !== ""
                  ? showFile?.threeDFile
                  : showFile?.image
              }
            />
          </Flex>
        }
      />
    </>
  );
};
