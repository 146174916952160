import * as React from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
interface loaderProps {
  pageLoading: any;
  pageLoaderURL: string;
}
export const PageLoading = ({ pageLoading, pageLoaderURL }: loaderProps) => {
  return (
    pageLoading?.loading && (
      <Flex
        justifyContent="center"
        alignItems="center"
        position="fixed"
        bg="rgba(0,0,0,0.5)"
        inset={0}
        zIndex={9999}
      >
        <Flex bg="white" alignItems="center" py={2} px={3} borderRadius="5px">
          <Image src={pageLoaderURL} w="3rem" h="3rem" mr={2} />
          <Text fontSize="sm">{pageLoading?.loadingText}</Text>
        </Flex>
      </Flex>
    )
  );
};
