import { extendTheme } from "@chakra-ui/react";
import "@fontsource/rubik";
import "@fontsource/ubuntu";
import "@fontsource/roboto";

const _theme = {
  colors: {
    main: {
      // "100": "rgba(0, 135, 238, 1)",
      // "500": "rgba(0, 135, 238, 1)",
      // "50": "rgba(0, 135, 238, .5)",
      // "40": "rgba(0, 135, 238, .4)",
      // "30": "rgba(0, 135, 238, .3)",
      // "20": "rgba(0, 135, 238, .2)",
      // "10": "rgba(0, 135, 238, .1)",
      dark: "rgba(0, 102, 180, 1)",

      "50": "#E5F4FF",
      "100": "#B8E0FF",
      "200": "#8ACCFF",
      "300": "#5CB8FF",
      "400": "#2EA5FF",
      "500": "#0091FF",
      "600": "#0074CC",
      "700": "#005799",
      "800": "#003A66",
      "900": "#001D33",
    },
    button: {
      500: "rgba(0, 102, 180, 1)",
    },
    blue: {
      "50": "#E5F4FF",
      "100": "#B8E0FF",
      "200": "#8ACCFF",
      "300": "#5CB8FF",
      "400": "#2EA5FF",
      "500": "#0091FF",
      "600": "#0074CC",
      "700": "#005799",
      "800": "#003A66",
      "900": "#001D33",
    },
    dark: {
      "100": "rgba(0,0,0,1)",
      "500": "rgba(0,0,0,1)",
      "90": "rgba(0, 0, 0, .9)",
      "80": "rgba(0, 0, 0, .8)",
      "70": "rgba(0, 0, 0, .7)",
      "60": "rgba(0, 0, 0, .6)",
      "50": "rgba(0, 0, 0, .5)",
      "40": "rgba(0, 0, 0, .4)",
      "30": "rgba(0, 0, 0, .3)",
      "20": "rgba(0, 0, 0, .2)",
      "10": "rgba(0, 0, 0, .1)",
    },
    danger: {
      "100": "rgba(239, 111, 108, 1)",
      "50": "rgba(239, 111, 108, .5)",
      "10": "rgba(239, 111, 108, .1)",
    },
    success: {
      "100": "rgba(80, 184, 72, 1)",
      "50": "rgba(80, 184, 72, .5)",
      "10": "rgba(80, 184, 72, .1)",
    },
    hold: {
      "100": "rgba(235, 167, 0, 1)",
      "50": "rgba(235, 167, 0, .5)",
      "10": "rgba(235, 167, 0, .1)",
    },
    bg: "rgba(243, 246, 249, 1)",
    stroke: "rgba(186, 188, 190, 1)",
  },
  fonts: {
    heading: `Ubuntu, sans-serif`,
    body: `Rubik, sans-serif`,
    mono: `Rubik, sans-serif`,
  },
  textStyles: {
    primary: {
      header: {
        fontSize: "xl",
        fontWeight: "semibold",
        fontFamily: "Ubuntu, sans-serif",
      },
      settingsTitle: {
        fontSize: "xl",
        fontWeight: "bold",
        fontFamily: "Roboto, sans-serif",
      },
      title: {
        fontSize: "2xl",
        fontWeight: "bold",
        fontFamily: "Rubik, Ubuntu, sans-serif",
      },
      poster: {
        fontSize: "4xl",
        fontWeight: "bold",
        fontFamily: "Rubik, Ubuntu, sans-serif",
      },
      contentTitle: {
        fontSize: "lg",
        fontWeight: "bold",
        fontFamily: "Ubuntu, sans-serif",
      },
      success: {
        fontSize: "sm",
        fontWeight: "medium",
        fontFamily: "Ubuntu, sans-serif",
        color: "success.100",
      },
      info: {
        fontSize: "sm",
        fontWeight: "medium",
        fontFamily: "Ubuntu, sans-serif",
        color: "hold.100",
      },
      danger: {
        fontSize: "sm",
        fontWeight: "medium",
        fontFamily: "Ubuntu, sans-serif",
        color: "danger.100",
      },
      bold: {
        fontSize: "sm",
        fontWeight: "bold",
        fontFamily: "Rubik, sans-serif",
      },
      text: {
        fontSize: "sm",
        fontWeight: "medium",
        fontFamily: "Rubik, sans-serif",
      },
      thin: {
        fontSize: "sm",
        fontWeight: "light",
        fontFamily: "Rubik, sans-serif",
      },
      secondaryText: {
        fontSize: "sm",
        fontWeight: "medium",
        color: "gray",
        fontFamily: "Rubik, sans-serif",
      },
      largeSecondaryText: {
        fontSize: "lg",
        fontWeight: "medium",
        color: "gray",
        fontFamily: "Rubik, sans-serif",
      },
      small: {
        fontSize: "xs",
        fontWeight: "medium",
        fontFamily: "Rubik, sans-serif",
      },
      smallTitle: {
        fontSize: "xs",
        fontWeight: "bold",
        fontFamily: "Rubik, sans-serif",
      },
      extraSmall: {
        fontSize: "0.625rem ",
        fontWeight: "medium",
        fontFamily: "Rubik, sans-serif",
      },
      large: {
        fontSize: "lg",
        fontWeight: "medium",
        fontFamily: "Rubik, sans-serif",
      },
      extraLarge: {
        fontSize: "2xl",
        fontWeight: "medium",
        fontFamily: "Rubik, sans-serif",
      },
      paragraph: {
        fontSize: "md",
        fontWeight: "normal",
        fontFamily: "Rubik, sans-serif",
      },
      link: {
        fontSize: "sm",
        fontWeight: "medium",
        color: "#3E97FF",
        fontFamily: "Rubik, sans-serif",
      },
    },
  },
  breakpoints: {
    xs: "1em",
    sm: "30em", //480px
    md: "52em", //768px
    lg: "62em", //992px
    xl: "80em", //1280px
    "2xl": "96em", //1536px
  },
};

export const theme = extendTheme(_theme);
