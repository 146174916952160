import axios from 'axios'


export const getCountriesService =  async() => {
	return axios.get('https://address.karkhana.live/countries')
}

export const getStatesService =  async(countryCode:String) => {
	return axios.get(`https://address.karkhana.live/stateS/${countryCode}`)
}

export const getCityService =  async(countryCode:String,stateCode:String) => {
	return axios.get(`https://address.karkhana.live/cities/${countryCode}/${stateCode}`)
}


