import * as React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";

interface headerProp {
  title: any;
  sortable: boolean;
}
interface bodyProp {
  key: any;
  value?: any;
}
interface tableProps {
  tableHeaderList: headerProp[];
  tableRowList: bodyProp[];
}

const tableBodyRow = (item: any) => {
  let data: any = [];
  for (const property in item) {
    data.push(
      <Td
        borderRight="1px solid rgba(0, 135, 238, 0.2)"
        paddingX="5px"
        textAlign="center"
      >
        {item[property]}
      </Td>
    );
  }
  return data;
};

export const GeneralTable = ({ tableHeaderList, tableRowList }: tableProps) => {
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sortedData, setSortedData] = React.useState(tableRowList);
  const navigate = useNavigate();

  // sorting
  const handleSort = (key: string) => {
    let sortedList = [...tableRowList];
    if (sortOrder === "desc") {
      setSortOrder("asc");
      sortedList.sort((a, b) => (a[key] < b[key] ? -1 : 1));
    } else {
      setSortOrder("desc");
      sortedList.sort((a, b) => (a[key] > b[key] ? -1 : 1));
    }
    setSortedData(sortedList);
  };

  return (
    <TableContainer overflow="scroll" borderRadius="12px 12px 0px 0px ">
      <Table>
        <Thead bg="rgba(0, 135, 238, 0.1)">
          <Tr>
            {tableHeaderList.map((item: any) => (
              <Td
                key={item.title}
                borderRight="1px solid rgba(0, 135, 238, 0.2)"
                paddingX="5px"
              >
                <Flex alignItems="center" justifyContent="space-around">
                  <Text textStyle="primary.paragraph" fontWeight="bold">
                    {item.title}
                  </Text>
                  {/* TODO:implement sorting
                   {item.sortable && (
                    <Flex flexDirection="column">
                      <GoTriangleUp color="#0087EE" cursor="pointer"  onClick={() => handleSort(item.title)}/>
                      <GoTriangleDown color="#0087EE" cursor="pointer"  onClick={() => handleSort(item.title)}/>
                    </Flex>
                  )}  */}
                </Flex>
              </Td>
            ))}
          </Tr>
        </Thead>
        {tableRowList && (
          <Tbody bg="#fff">
            {tableRowList?.map((item, index) => (
              <Tr key={index}>{tableBodyRow(item)}</Tr>
            ))}
          </Tbody>
        )}
      </Table>
    </TableContainer>
  );
};
