import * as React from "react";
import { Button as Btn, ButtonProps } from "@chakra-ui/react";

export const DarkButton = ({ children, ...args }: ButtonProps) => (
	<Btn {...args}  cursor={"pointer"} colorScheme="main">
		{children}
	</Btn>
);

export const Button = ({ children, ...args }: ButtonProps) => (
	<Btn {...args} cursor={"pointer"} colorScheme="dark">
		{children}
	</Btn>
);

export const ThemeButton = ({ children, ...args }: ButtonProps) => (
	<Btn {...args} border="0px" cursor={"pointer"} colorScheme="button">
		{children}
	</Btn>
);
