import * as React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  Text,
  Flex,
} from "@chakra-ui/react";
import { ThemeButton } from "@karkhanaui/react";

export const SideDrawer: React.FC<any> = ({ children, ...args }) => {
  const sizes = ["xs", "sm", "md"];
  return (
    <Drawer
      isOpen={args.isOpen}
      placement="right"
      onClose={args.onClose}
      size={sizes}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader pb="0px">{args.title}</DrawerHeader>
        {args.headingSubContent && (
          <Text textStyle="primary.secondaryText" pl="1.5rem" pt="0px">
            {args.headingSubContent}
          </Text>
        )}
        <Divider mt="1.25rem" />

        <DrawerBody>{children}</DrawerBody>

        <DrawerFooter>
          {args.FooterContent ? (
            args.FooterContent
          ) : (
            <Flex gap="1.5rem" alignItems="center">
              {args.discardButton && (
                <Text
                  textStyle="primary.secondaryText"
                  fontWeight="bold"
                  onClick={args.onClose}
                  cursor="pointer"
                >
                  {args.discardButton}
                </Text>
              )}
              {args.buttonBody && (
                <ThemeButton
                  children={args.buttonBody}
                  onClick={args.onSubmit}
                  isDisabled={args.isSaveButtonLoading}
                  isLoading={args.isSaveButtonLoading}
                />
              )}
            </Flex>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
