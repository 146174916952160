import axios from "axios";

export const getAllItemService = async ({
  fields,
  filters,
  limit_start,
}: {
  fields?: any[];
  filters?: any[];
  start?: any[];
  limit_start?: number;
}) => {
  return await await axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL +
      `/api/resource/Item?order_by=modified%20desc`,
    {
      params: {
        fields: JSON.stringify(["*"]),
        ...(filters && { filters: JSON.stringify(filters) }),
        ...(fields && { fields: JSON.stringify(fields ? fields : "*") }),
        ...(limit_start && { limit_start: JSON.stringify(limit_start) }),
        order_by: "modified desc",
      },
    }
  );
};

export const getItemDFMFeedbackService = (data: any) => {
  return axios.get(process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/DFM", {
    params: data.params,
  });
};

export const getItemDetailsService = (data: any) => {
  return axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Item/" + data
  );
};

export const getItemAttachedFilesService = (data: any) => {
  return axios.get(process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/File", {
    params: data,
  });
};

export const itemDataProcure = () => {
  return axios.get("/api/resource/Item?fields=[%22*%22]&start=0&limit=10000");
};

export const itemDetailsUpdateService = (itemId: string, data: any) => {
  return axios.put(
    process.env.REACT_APP_AUTH_LIVE_URL + `/api/resource/Item/${itemId}`,
    data
  );
};
