import axios from "axios";

export const getQuotationService = async (quoteID: string) => {
  return await axios.get(
    process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Quotation/" + quoteID
  );
};

export const getQuotationList = (data: any) => {
  return axios.get("/api/resource/Quotation", { params: data });
};
