var objToday = new Date();
const months = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);
const weekday = new Array(
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
);

export const dateMonthDay =
  objToday.getDate() +
  " " +
  months[objToday.getMonth()] +
  ", " +
  weekday[objToday.getDay()];

export const normalDate = (date: Date) => {
  const objDate = new Date(date);

  const dt =
    objDate.getDate() < 10 ? `0${objDate.getDate()}` : objDate.getDate();
  const mon =
    objDate.getMonth() + 1 < 10
      ? `0${objDate.getMonth() + 1}`
      : objDate.getMonth() + 1;
  return dt + "/" + mon + "/" + objDate.getFullYear();
};

export const YYYY_MM_DD = (date: Date) => {
  const objDate = new Date(date);

  const dt =
    objDate.getDate() < 10 ? `0${objDate.getDate()}` : objDate.getDate();
  const mon =
    objDate.getMonth() + 1 < 10
      ? `0${objDate.getMonth() + 1}`
      : objDate.getMonth() + 1;
  return objDate.getFullYear() + "/" + mon + "/" + dt;
};
