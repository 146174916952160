import {
  RFQ_FILE_UPLOAD_BEGIN,
  RFQ_FILE_UPLOAD_SUCCESS,
  RFQ_FILE_UPLOAD_FAILED,
  RFQ_CREATION_BEGIN,
  RFQ_CREATION_SUCCESS,
  RFQ_CREATION_FAILED,
  RFQ_FETCH_BEGIN,
  RFQ_FETCH_SUCCESS,
  RFQ_FETCH_FAILED,
  QUOTE_FETCH_BEGIN,
  QUOTE_FETCH_SUCCESS,
  QUOTE_FETCH_FAILED,
} from "../actions/types";

const partSave = {
  part: [],
  loading: false,
  error: null,
};

const rfqSave = {
  rfq: {},
  loading: false,
  error: null,
};

const quote = {
  quote: {},
  loading: false,
  error: null,
};

export const savePart = (state = partSave, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case RFQ_FILE_UPLOAD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RFQ_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        part: [...partSave.part, payload?.part],
      };
    case RFQ_FILE_UPLOAD_FAILED:
      return {
        ...state,
        loading: false,
        error: payload?.error,
        part: [],
      };
    default:
      return state;
  }
};

export const saveRFQ = (state = rfqSave, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case RFQ_CREATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RFQ_CREATION_SUCCESS:
      return {
        ...state,
        loading: false,
        rfq: payload?.rfq,
      };
    case RFQ_CREATION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload?.error,
        rfq: {},
      };
    default:
      return state;
  }
};

export const getQuote = (state = quote, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case QUOTE_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case QUOTE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: payload.quote,
      };
    case QUOTE_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: payload?.error,
        quote: {},
      };
    default:
      return state;
  }
};
