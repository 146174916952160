import {
  Box,
  Flex,
  Text,
  Img,
  Grid,
  GridItem,
  Input,
  Hide,
  Show,
  TabList,
  Tab,
  TabPanels,
  Tabs,
  TabIndicator,
  TabPanel,
  Button,
  HStack,
  Divider,
  VStack,
  Circle,
  Tag,
  TagLabel,
} from "@chakra-ui/react";

import {
  ThemeButton,
  register,
  get_doc,
  GenericTables,
  NoDataAvailable,
  supplierRecordsServiceService,
  ERROR_MSG,
  SET_MESSAGE,
  formatErrorMessage,
  getItemAttachedFilesService,
  AppDispatch,
  frappeMethodService,
  setPageLoading,
} from "@karkhanaui/react";
import React, { useState, useEffect } from "react";
import quotations from "../../img/quotations.svg";
import rfq from "../../img/rfqSent.svg";
import Right from "../../img/Right.svg";
import { BsArrowRightCircle, BsFileEarmarkPdf, BsGlobe2 } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";
import { HiLocationMarker } from "react-icons/hi";
import { MdAlternateEmail } from "react-icons/md";
import { FormDataContext } from "../Rfq/FormDataProvider";

import { CalendarIcon } from "@chakra-ui/icons";
import { useParams, useNavigate } from "react-router-dom";
import RfqList from "./RfqList";
import axios from "axios";
import { AiOutlineArrowRight } from "react-icons/ai";
import { NDAUploadCard } from "./NDAUploadCard";
import { useDispatch } from "react-redux";
import AddressCardSupplier from "./AddressCardSupplier";
import AICard from "../../components/AICard";
export const Supplier_Records: React.FC<any> = () => {
  const [store, setStore] = React.useState<any[]>([]);
  const [supplierEmail, setSupplierEmail] = React.useState<any>("");
  const [attachedFile, setAttachedFiles] = React.useState<any>();
  const [ cardDetails, setCardDetails] =  React.useState<any>()
  const params = useParams();
  const [details, setDetails] = React.useState<any>();
  const mail = params.smail;
  const sMailId = params.sMailId;
  const navigate = useNavigate();
  const [uploadData, setUploadData] = React.useState<any>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const deleteFunction = async () => {
    dispatch(
      setPageLoading({
        loading: true,
        loadingText: "Deleting file.....",
      })
    );

    await axios
      .post("/api/method/procurement.api.nda.remove_attachment_nda", {
        payload: {
          file_id: attachedFile.name,
          docname: uploadData?.name,
        },
      })
      .then((res) => {
        setUploadData(res.data.message);
        setDetails(res.data.message)
        dispatch(
          setPageLoading({
            loading: false,
            loadingText: "",
          })
        );
        getUploadDocData();
        window.location.reload();
        //  setIsOpen(false)
      })
      .catch((err) => {
        dispatch(
          setPageLoading({
            loading: false,
            loadingText: "",
          })
        );
        dispatch({
          type: SET_MESSAGE,
          payload: {
            case: ERROR_MSG,
            data: formatErrorMessage(err),
          },
        });
      });
  };
  const getAttachedFiles = async () => {
    let params = {
      filters: JSON.stringify([
        ["attached_to_doctype", "=", "Supplier Access"],
        ["attached_to_name", "in", uploadData?.name],
      ]),
      fields: JSON.stringify(["*"]),
      limit: 0,
    };
    try {
      let res = await getItemAttachedFilesService(params);
      if (res.status === 200) {
        setAttachedFiles(res.data.data[0]);
      }
    } catch (error) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          case: ERROR_MSG,
          data: formatErrorMessage(error),
        },
      });
    }
  };
  const getValue = async () => {
    await supplierRecordsServiceService(mail)
      .then((res: any) => {
       
        setDetails(res.data.message);
        setSupplierEmail(details?.email);
      })
      .catch((err: any) => {});
  };

  const tableData = async () => {
    await get_doc({
      doc: "RFQ Supplier",
      fields: ["*"],
      filters: [["supplier", "=", sMailId]],
    })
      .then((res: any) => {
        setStore(res.data.data);
      })
      .catch((err: any) => {});
  };
  const getUploadDocData = async () => {
    await get_doc({
      doc: "Supplier Access",
      fields: ["*"],
      filters: [["supplier_id", "=", mail]],
    })
      .then((res: any) => {
     
        setCardDetails(res.data.data[0])
        setUploadData(res.data.data[0]);
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    getValue();
    tableData();
    getUploadDocData();
  }, []);

  React.useEffect(() => {
    getAttachedFiles();
  }, [uploadData]);

  const columns = [
    {
      Header: "RFQ Name",
      accessor: "rfq_name",
    },
    {
      Header: "RFQ ID",
      accessor: "rfq",
    },
    {
      Header: "Created On",
      accessor: "creation",
      Cell: (row) => {
        return <Text>{row.value.slice(0, 10)}</Text>;
      },
    },
    {
      Header: "Expired On",
      accessor: "rfq_expiry_date",
    },
    {
      Header: "",
      accessor: "arrows",
      Cell: (i) => {
        return (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            bg="#0087ee10"
          >
            <AiOutlineArrowRight
              size={20}
              cursor={"pointer"}
              onClick={() => navigate(`/rfqpreview/${i.row.original.rfq}`)}
            />
          </Box>
          //   <Img src={Right} alt="" cursor="pointer" width="32px" height="32px" onClick={()=>{navigate(`/rfqpreview/${i.row.original.rfq}`)
          // console.log("working")
          // }}/>
        );
      },
    },
  ];
  return (
    <Box>
      <Box m ="1em">
      {/* <AICard name = ""  isSupplierProfile = {true}/> */}
      </Box>
    <Box
      p={{ base: "0", md: "1em" }}
      display="flex"
      flexDir={{ base: "column", md: "row" }}
      gap={{ base: "0", md: "1em" }}
     
    >
      {/* <Box>
            <Button
            children="Back"
            color={"#0087ee"}
            onClick={() => navigate(-1)}
            textDecoration={"underline"} />
          </Box> */}

      <Hide below="md">
       
        <Box
          width="50%"
          height="auto"
          bg="#fff"
          borderRadius="md"
          padding="2rem 1rem"
        >
          {/*  */}
          <SupplierInformation details={details} tileDetails={cardDetails} />
        </Box>
        <Box display="flex" flexDir={"column"} width="100%" gap="1em">
          <Flex flexDir={"row"} gap="1rem">
            <Box
              width="100%"
              height="298px"
              bg="#fff"
              borderRadius="md"
              display="flex"
              padding="1.5rem"
              alignItems="center"
              justifyContent={"center"}
            >
              <SupplierStatCards
                icon={rfq}
                stat={
                  details?.total_rfq_sent === null ? 0 : details?.total_rfq_sent
                }
                color={"rgba(181, 181, 195, 1)"}
                statTitle={"RFQ's sent"}
              />
              <SupplierStatCards
                icon={quotations}
                stat={
                  details?.total_po_sent === null ? 0 : details?.total_po_sent
                }
                color={"rgba(181, 181, 195, 1)"}
                statTitle={"Quotes received"}
              />
            </Box>
            <Box bg="#fff" width={"100%"} height="298px" boxShadow={"md"}>
              <NDAUploadCard
                deleteFunction={deleteFunction}
                getUploadDocData={getUploadDocData}
                uploadData={uploadData}
                file={attachedFile}
                status={uploadData?.nda_status}
                supplier_email={uploadData?.supplier}
                setUploadData={setUploadData}
                supplierName={details?.supplier_name}
              />
            </Box>
          </Flex>
          <Tabs
            width="100%"
            height="auto"
            bg="#fff"
            borderRadius="md"
            flexGrow={1}
          >
            <TabList>
              <Tab>RFQ's</Tab>
              <Tab>Address</Tab>
              
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box>
                  <Text textStyle="primary.header" m="1em" fontFamily="Rubik">
                    Live RFQs
                  </Text>
                  <Box m="1em">
                    {store.length > 0 ? (
                      <GenericTables
                        column={columns || []}
                        data={store || []}
                        isShowSearch={false}
                        renderSerialNo={true}
                      />
                    ) : (
                      <NoDataAvailable />
                    )}
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel bg="transparent">
                {details?.address.map((addr, index) => {
                  // console.log({addr})
                  return (
                    <Box key={index} mb="1em" overflow={"scroll"}>
                      <AddressCardSupplier content={addr} />
                    </Box>
                  );
                })}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Hide>
      <Show below="md">
        <Flex width="100%" p="0.725rem 1rem" gap="1.5rem">
          <SupplierStatCards
            icon={rfq}
            stat={
              details?.total_rfq_sent === null ? 0 : details?.total_rfq_sent
            }
            color={"rgba(181, 181, 195, 1)"}
            statTitle={"RFQ's sent"}
          />
          <SupplierStatCards
            icon={quotations}
            stat={details?.total_po_sent === null ? 0 : details?.total_po_sent}
            color={"rgba(181, 181, 195, 1)"}
            statTitle={"Quotations received"}
          />
        </Flex>
        <Tabs position="relative" variant="unstyled" w="100%">
          <TabList
            padding="0.5rem"
            bg="#fff"
            mt="1px"
            boxShadow="0px 2px 4px 0px #00000014, 0px 0px 6px 0px #00000005"
          >
            <Tab>Supplier Information</Tab>
            <Tab>Live RFQs</Tab>
            <Tab>NDA</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels mt="1rem" padding="0.5rem">
            <TabPanel bg="#fff" p="10px" borderRadius="10px">
              <Box>
                <SupplierInformation details={details} tileDetails={cardDetails} />
              </Box>
            </TabPanel>
            <TabPanel p="1rem 0">
              {store.length > 0 ? (
                store.map((item) => {
                  return (
                    <LiveRFQCards
                      cardData={item}
                      handleViewRFQ={() => navigate(`/rfqpreview/${item.rfq}`)}
                    />
                  );
                })
              ) : (
                <NoDataAvailable />
              )}
            </TabPanel>
            <TabPanel>
              <Box bg="#fff" width={"100%"} height="40vh" boxShadow={"md"}>

            <NDAUploadCard
                deleteFunction={deleteFunction}
                getUploadDocData={getUploadDocData}
                uploadData={uploadData}
                file={attachedFile}
                status={uploadData?.nda_status}
                supplier_email={uploadData?.supplier}
                setUploadData={setUploadData}
                supplierName={details?.supplier_name}
              />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Show>
    </Box>
    </Box>
  );
};
const supplierTypeTile = (details: any)=>{
  
  let arr = [];
  details?.manufacturing === 1 ? arr.push("Manufacturing") : null
  details?.oem === 1 ? arr.push("OEM") : null
  details?.raw_material === 1 ? arr.push("Raw Material") : null
  details?.finishing_services === 1 ? arr.push("Finishing Services") : null

  const getColorForItem = (item) => {
    switch (item) {
      case 'Manufacturing':
        return '#0087ee20';
      case 'OEM':
        return '#a975ff20';
      case 'Raw Material':
        return '#fdb91320';
      case 'Finishing Services':
        return '#ffdfba';
      default:
        return 'gray'; 
    }
  };
  return(
    <Box display = "flex" flexDir={"row"} gap="0.3rem" flexWrap={"wrap"}>

      {arr.map((item, i)=>(
        <Tag key="i" size="sm" style={{ backgroundColor: getColorForItem(item) }} variant={"subtle"}><Text fontSize="11px" >{item}</Text></Tag>
      ))}
    </Box>
  )
}

const SupplierInformation = ({ details , tileDetails}:any) => {
  return (
    <>
      <Flex flexDir="row" gap="1.5em" alignItems="center" mb="1rem">
        <Circle
          size="60px"
          bg="rgba(80, 184, 72, 0.1)"
          position="relative"
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            top="auto"
            bottom="auto"
            color="rgba(80, 184, 72, 1)"
            fontStyle="primary.header"
            position="absolute"
            fontSize="34px"
          >
            {details?.supplier_company_name === null
              ? "K"
              : details?.supplier_company_name?.charAt(0).toUpperCase()}
          </Text>
        </Circle>

        <Flex flexDir="column" gap="0.5rem">
          <Text
            textStyle="primary.text"
            fontSize="18px"
            fontWeight="bold"
            color="#000"
          >
            {details?.supplier_company_name === null
              ? "Karkhana"
              : details?.supplier_company_name}
          </Text>
          <Box display="flex" flexDir="row" gap="0.5em" color="#5E6278">
            <MdAlternateEmail size={20} />
            <Hide below="md">
              <Text textStyle={"primary.text"}>{details?.email}</Text>
            </Hide>
            <Show below="md">
              <Text textStyle={"primary.text"}>
                {details?.email.length > 20
                  ? details?.email.substring(0, 18) + "..."
                  : details?.email}
              </Text>
            </Show>
          </Box>
        </Flex>
      </Flex>
      <Grid templateColumns="1fr" gap="1em 0em" mt="1rem">
        {/* company name */}
        <GridItem>
          <Text
            color="#3F4254"
            textStyle="primary.text"
            fontSize="15px"
            fontWeight="semibold"
            mt="0.6em"
            width="auto"
          >
            Supplier Company Name
          </Text>
        </GridItem>
        <GridItem>
          <Input
            disabled
            _disabled={{ color: "#000" }}
            value={details?.supplier_company_name}
            padding="13px 16px"
            bg="rgba(243, 246, 249, 1)"
            title={
              details?.supplier_company_name === null ||
              details?.supplier_company_name.length <= 15
                ? null
                : details?.supplier_company_name
            }
          />
        </GridItem>

        {/* company type */}
        <GridItem>
          <Text
            color="#3F4254"
            textStyle="primary.text"
            fontSize="15px"
            fontWeight="semibold"
            mt="0.6em"
            width="auto"
          >
            Supplier Company Type
          </Text>
        </GridItem>
        <GridItem>
          <Flex
            flexDir="row"
            flexWrap="wrap" 
            _disabled={{ color: "#000" }}
            borderRadius="lg"
            border="1px solid #00000010"
            padding="13px 16px"
            bg="rgba(243, 246, 249, 1)"
            gap="8px" 
          >
         {supplierTypeTile(tileDetails)}
          </Flex>
        </GridItem>

        {/* supplier name */}
        <GridItem>
          <Text
            color="#3F4254"
            textStyle="primary.text"
            fontSize="15px"
            fontWeight="semibold"
            mt="0.6em"
          >
            Supplier Name
          </Text>
        </GridItem>
        <GridItem>
          <Input
            disabled
            _disabled={{ color: "#000" }}
            value={details?.supplier_name}
            padding="13px 16px"
            bg="rgba(243, 246, 249, 1)"
            title={
              details?.supplier_email === null ||
              details?.supplier_email.length <= 15
                ? null
                : details?.supplier_email
            }
          />
        </GridItem>

        {/* email */}
        <GridItem>
          <Text
            color="#3F4254"
            textStyle="primary.text"
            fontSize="15px"
            fontWeight="semibold"
            mt="0.6em"
          >
            Email
          </Text>
        </GridItem>
        <GridItem>
          <Input
            disabled
            value={details?.email}
            _disabled={{ color: "#000" }}
            padding="13px 16px"
            bg="rgba(243, 246, 249, 1)"
          />
        </GridItem>

        {/* contact */}
        <GridItem>
          <Text
            color="#3F4254"
            textStyle="primary.text"
            fontSize="15px"
            fontWeight="semibold"
            mt="0.6em"
          >
            Contact
          </Text>
        </GridItem>
        <GridItem>
          <Input
            disabled
            _disabled={{ color: "#000" }}
            value={`+${details?.phone}`}
            padding="13px 16px"
            bg="rgba(243, 246, 249, 1)"
          />
        </GridItem>
      </Grid>
    </>
  );
};

const LiveRFQCards = ({ cardData, handleViewRFQ }) => {
  return (
    <Box borderRadius="lg" bg="#fff" padding="1rem" mb="1rem" boxShadow="lg">
      <Flex justifyContent="space-between">
        <Box>
          <Text fontSize="16px" fontWeight="semibold">
            {cardData.rfq_name}
          </Text>
          <Text fontSize="14px">RFQ ID: {cardData.rfq}</Text>
        </Box>
        <Box>
          <Button
            variant="link"
            color="#0087ee"
            mb="auto"
            onClick={handleViewRFQ}
          >
            View RFQ&nbsp;
            <BsArrowRightCircle />
          </Button>
        </Box>
      </Flex>
      <Divider my="0.875rem" />
      <Flex gap="1.5rem">
        <VStack fontSize="14px" gap="0" alignItems="left">
          <Text fontWeight="semibold" color="#0087ee">
            Created On
          </Text>
          <Text>{cardData.creation.slice(0, 10)}</Text>
        </VStack>
        <VStack fontSize="14px" gap="0" alignItems="left">
          <Text fontWeight="semibold" color="#0087ee">
            Expired On
          </Text>
          <Text>{cardData.rfq_expiry_date}</Text>
        </VStack>
      </Flex>
    </Box>
  );
};

const SupplierStatCards = ({ icon, stat, color, statTitle }) => {
  return (
    <Flex
      p="1em"
      flexDir={"column"}
      w={{ base: "155px", md: "auto" }}
      padding="0.5rem"
      alignItems="center"
      bg={{ base: "#fff", md: "transparent" }}
      borderRadius={{ base: "lg", md: "none" }}
      boxShadow={{ base: "lg", md: "none" }}
    >
      <Img src={icon} width="45px" height="45px" />

      <Text fontWeight={"bold"} fontStyle={"Rubik"} fontSize={"20px"}>
        {stat}
      </Text>
      <Text color={color} fontStyle={"Rubik"} ml="1em" fontSize={"15px"}>
        {statTitle}
      </Text>
    </Flex>
  );
};

const TagElement = ({ tagText, tagColor, tagBackgroundColor }: any) => {
  return (
    <Tag
      size={"sm"}
      variant={"subtle"}
      bgColor={tagBackgroundColor !== undefined ? tagBackgroundColor : "white"}
      mr="0.3rem"
      border={"black"}
      color={tagColor !== undefined ? tagColor : "black"}
      minWidth="auto"
    >
      <TagLabel whiteSpace="pre">{tagText}</TagLabel>
    </Tag>
  );
};
