import * as React from "react";
import { Flex, Text, Tooltip, Box } from "@chakra-ui/react";
import { Thumbnail } from "@karkhanaui/react";
import { TiDelete } from "react-icons/ti";
import { BsDownload } from "react-icons/bs";

export interface files {
  files: {
    subtitle: string;
    file: string;
    isPrivateFile?: boolean;
    path: string;
    name: string;
    file_url: string;
    file_name: string;
  }[];
  alternativeImg?: any;
  onClickFunction?: Function;
  deleteIcon?: boolean;
  deleteFunction?: Function;
  acceptedFiles?: any[];
  hideDownloadIcon?: boolean;
  colorTheme?: string;
  currentFile?: number;
}
export const FilesSlider = ({
  files,
  alternativeImg,
  onClickFunction,
  deleteIcon = false,
  deleteFunction,
  acceptedFiles,
  hideDownloadIcon,
  colorTheme,
  currentFile,
}: files) => {
  const activeConfig = {
    active: {
      border:
        colorTheme === "green" ? "2px solid #4BA18A" : "2px solid #0087EE",
      bg: colorTheme === "green" ? "#E2F1EE" : "rgba(0, 135, 238, 0.2)",
    },
    inactive: {
      border: "1px solid rgba(0, 0, 0, 0.40)",
      bg: "#F1F1F1",
    },
  };

  return (
    <Flex gap=".25rem" overflowX="auto">
      {files &&
        files.map((each, index) => (
          <Tooltip
            key={index}
            label={
              (each?.subtitle && each?.subtitle) ||
              (each?.file_name && each?.file_name) ||
              (each.name && each?.name)
            }
          >
            <Box>
              {deleteIcon && (
                <Box
                  float="right"
                  cursor="pointer"
                  transform="translate(-1rem, -.25rem)"
                  onClick={() => deleteFunction(each)}
                >
                  <TiDelete size="1.4rem" />
                </Box>
              )}
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                flexShrink={0}
                onClick={() => onClickFunction(each)}
                cursor="pointer"
                gap=".3rem"
              >
                <Box
                padding=".75rem"
                  border={
                    currentFile !== undefined && index == currentFile
                      ? activeConfig.active.border
                      : activeConfig.inactive.border
                  }
                  bg={
                    currentFile !== undefined && index == currentFile
                      ? activeConfig.active.bg
                      : activeConfig.inactive.bg
                  }
                  borderRadius=".75rem"
                >
                  <Thumbnail
                    fileUrl={each.file || each.path || each.file_url}
                    alternativeImg={alternativeImg}
                    isPrivateFile={each.isPrivateFile}
                  />
                </Box>
                <Text
                  textStyle="primary.text"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap=".15rem"
                  fontWeight={
                    currentFile !== undefined && index == currentFile
                      ? "normal"
                      : ""
                  }
                >
                  {(each?.subtitle && each?.subtitle.substring(0, 8)) ||
                    (each?.file_name && each?.file_name?.substring(0, 8)) ||
                    (each.name && each?.name?.substring(0, 8))}
                  {!hideDownloadIcon && (
                    <BsDownload
                      size="1.2rem"
                      onClick={() => window.open(each?.file, "_blank")}
                    />
                  )}
                </Text>
              </Flex>
            </Box>
          </Tooltip>
        ))}
    </Flex>
  );
};
