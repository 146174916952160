import { useDispatch } from "react-redux";
import {setPageLoading } from "@karkhanaui/react";


export async function loadingWaitWrapper(funcArray:any, dispatch:any) {
	
	dispatch(setPageLoading({ loading: true, loadingText: "Loading" }));
  
	try {
	  return await Promise.all(funcArray.map((fn:any) => fn()));
	} catch (e) {
	  console.log(e);
	} finally {
	  dispatch(setPageLoading({ loading: false, loadingText: "" }));
	}
  }