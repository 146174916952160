import {
  RFQ_FILE_UPLOAD_BEGIN,
  RFQ_FILE_UPLOAD_SUCCESS,
  RFQ_FILE_UPLOAD_FAILED,
  SET_MESSAGE,
  RFQ_CREATION_BEGIN,
  RFQ_CREATION_SUCCESS,
  RFQ_CREATION_FAILED,
  QUOTE_FETCH_BEGIN,
  QUOTE_FETCH_SUCCESS,
  QUOTE_FETCH_FAILED,
} from "./types";
import {
  uploadFileService,
  AppDispatch,
  formatErrorMessage,
  saveRFQService,
  getQuoteService,
} from "@karkhanaui/react";

export const SavePart = (data: FormData) => (dispatch: AppDispatch) => {
  dispatch({ type: RFQ_FILE_UPLOAD_BEGIN });
  return uploadFileService(data).then(
    (res) => {
      dispatch({
        type: RFQ_FILE_UPLOAD_SUCCESS,
        payload: { part: res?.data.message.item },
      });

      return Promise.resolve(res);
    },
    (error) => {
      const message = formatErrorMessage(error);
      dispatch({
        type: RFQ_FILE_UPLOAD_FAILED,
        payload: { error: message },
      });

      return Promise.reject(message);
    }
  );
};

export const SaveRFQ = (data: any) => (dispatch: AppDispatch) => {
  dispatch({ type: RFQ_CREATION_BEGIN });
  return saveRFQService(data).then(
    (res: any) => {
      dispatch({
        type: RFQ_CREATION_SUCCESS,
        payload: { rfq: res.data.data },
      });
      return Promise.resolve(res);
    },
    (error: any) => {
      const message = formatErrorMessage(error);

      dispatch({
        type: RFQ_CREATION_FAILED,
        payload: { error: message },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    }
  );
};

export const GetQuote = (quoteID: any) => (dispatch: AppDispatch) => {
  dispatch({ type: QUOTE_FETCH_BEGIN });
  return getQuoteService(quoteID).then(
    (res: any) => {
      dispatch({
        type: QUOTE_FETCH_SUCCESS,
        payload: { quote: res.data.data },
      });
      return Promise.resolve(res);
    },
    (error: any) => {
      const message = formatErrorMessage(error);

      dispatch({
        type: QUOTE_FETCH_FAILED,
        error: message,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { case: QUOTE_FETCH_FAILED, data: message },
      });

      return Promise.reject(message);
    }
  );
};
