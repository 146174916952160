import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_MESSAGE,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  formatErrorMessage,
  authRegisterServiceProcurement,
  authLogoutServiceProcurement,
  authLoginServiceProcurement,
  history,
  setPageLoading,
  ERROR_MSG,
} from "@karkhanaui/react";

export const registerProcure = (data: any, email:any) => (dispatch: any) => {
  return authRegisterServiceProcurement(data).then(
    (response: any) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          case: REGISTER_SUCCESS,
          data: `The OTP has been sent to your ${email}`,
        },
      });

      // dispatch(setMessage(""));

      if (response.data.message === "User already exists") {
        history.push("/login");
      }
      return Promise.resolve(response);
    },
    (error) => {
      const message = formatErrorMessage(error);

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch(setPageLoading({ loading: false, loadingText: "" }));

      dispatch({
        type: SET_MESSAGE,
        payload: { case: ERROR_MSG, data: message },
      });

      return Promise.reject(error);
    }
  );
};

export const loginProcure =
  (username: string, password: string) => (dispatch: any) => {
    return authLoginServiceProcurement(username, password).then(
      (data: any) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data.data },
        });
        localStorage.setItem("procure", username);
        if (data.data.message == "Logged In")
          // dispatch({
          //   type: SET_MESSAGE,
          //   payload: { case: LOGIN_SUCCESS, data: "Logged In !" },
          // });
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: {
        //     case: SUCCESS_MSG,
        //     data: "Logged In !",
        //   },
        // });
        // dispatch(setMessage(""));
        history.push("/home");
        return Promise.resolve(data);
      },

      // (error: any) => {
      //   const message = formatErrorMessage(error);

      //   dispatch({
      //     type: LOGIN_FAIL,
      //   });

      //   dispatch({
      //     type: SET_MESSAGE,
      //     payload: { case: LOGIN_FAIL, data: message },
      //   });
      //   dispatch(setMessage(""));

      //   return Promise.reject();
      // }
      (error: any) => {
        let message = "";
        const status = error?.response?.status;
        if (status < 500 && status >= 400) {
          message = "Wrong email or password";
        } else if (status >= 500) {
          message = "Permission deined.";
        } else {
          message = formatErrorMessage(error);
        }

        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch(setPageLoading({ loading: false, loadingText: "" }));
        dispatch({
          type: SET_MESSAGE,
          payload: { case: ERROR_MSG, data: message },
        });
      }
    );
  };

export const logoutProcure = () => (dispatch: any) => {
  return authLogoutServiceProcurement()
    .then((data: any) => {
      dispatch({
        type: LOGOUT,
      });
      localStorage.clear();
      sessionStorage.clear();

      window.location.reload();
      history.push("/login");
    })
    .catch((error) => {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          case: ERROR_MSG,
          data: "Failed to log out " + formatErrorMessage(error),
        },
      });
    });
};
