import { Box, Text, Flex } from '@chakra-ui/react'
import * as React from 'react'
import {IconType} from 'react-icons'

export interface TagProps  {
	type: "info" | "success" | "danger"
	message: string,
}

const types = {
	info : {
		textStyle:"primary.info",
		color: "hold.10",
		iconColor: "#FDB913"
	},
	success: {
		textStyle:"primary.success",
		color: "success.10",
		iconColor: "#50B848"
	},
	danger: {
		textStyle:"primary.danger",
		color: "danger.10",
		iconColor: "#EF6F6C"
	}
}

export const Tag =  ({type, message}: TagProps) => {


	return (<Box bg={types[type].color} p="4px" w="max-content" borderRadius="5px" borderColor={types[type].iconColor}>
			<Text textStyle={types[type].textStyle} my="2px">{message}</Text>
	</Box>)
}
