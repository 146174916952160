import * as React from "react";
import {
  Box,
  Flex,
  Stack,
  ListItem,
  UnorderedList,
  Text,
  Link,
  Image,
} from "@chakra-ui/react";
import { ThemeButton } from "@karkhanaui/react";
import { AiFillCaretRight } from "react-icons/ai";

interface cardDetails {
  heading: string;
  image: string;
  list: string[];
  path: string;
}

export const DashboardTourCard = ({
  heading,
  image,
  list,
  path,
}: cardDetails) => {
  return (
    // // <Box bg="white" borderRadius="lg" width="570px" height="201px" position="relative">
    //   <Flex justifyContent="space-between" position="relative" borderRadius= "lg" bg= "#fff" w ="570px" h = "201px">
    //     <Box width="60%" height="150px">
    //       <Text textStyle="primary.header" fontWeight="bold" fontSize="16px">
    //         {heading}
    //       </Text>
    //       <UnorderedList>
    //         {
    //           list.map((item) => (
    //             <ListItem key={item} textStyle="primary.text" fontSize="12px" ml="0px" >
    //               {item}
    //             </ListItem>
    //           ))}
    //       </UnorderedList>

    //       <Stack direction="column" gap="2px" ml="20px">
    //         <Link textStyle="primary.bold" color="#0087EE" href={path}>
    //           <AiFillCaretRight />
    //           Take a Tour
    //         </Link>
    //         <ThemeButton
    //           bg="#0087EE"
    //           width = "164px" height = "32px"
    //           children="Get Instant Quote"
    //         />
    //       </Stack>
    //     </Box>
    //    <Box width = "40%" >
    //    {/* <Image  src={image} width="100%"/> */}
    //    </Box>

    //   </Flex>
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      height="auto"
      width="45%"
      bg="#fff"
      borderRadius="lg"
      boxShadow="lg"
    >
      <Box
        width="60%"
        height="100%"
        display={"flex"}
        flexDirection="column"
        alignItems={"left"}
        gap="0.5rem"
        p={"1em"}
      >
        <Text textStyle="primary.heading" fontWeight="bold" fontSize="16px">{heading}</Text>
        <UnorderedList ml="0.7em">
          {list?.map((item) => (
            <ListItem textStyle="primary.text" fontSize="12px" ml="1em">
              {item}
            </ListItem>
          ))}
        </UnorderedList>
        <Stack direction="row" alignItems="center" width="100%">
          <AiFillCaretRight color="#0087EE" />
          <Link textStyle="primary.bold" color="#0087EE" href={path}>
            Take a Tour
          </Link>
        </Stack>
        <ThemeButton
          bg="#0087EE"
          width="164px"
          height="38px"
          p="0.5rem"
          children="Get Instant Quote"
        />
      </Box>
      <Box width="40%" position="relative">
        <Image src={image} width="100%"  height="auto" position="absolute" bottom="0px"/>
      </Box>
    </Flex>
  );
};
