import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_MESSAGE,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  UPDATE_SIGNUP_STATE,
  formatErrorMessage,
  authRegisterServiceSupplier,
  authLoginServiceSupplier,
  authLogoutServiceSupplier,
  history,
  updateOnboardStatus,
  setPageLoading,
  ERROR_MSG,
} from "@karkhanaui/react";

export const registerSupplier = (dataIn: any) => (dispatch: any) => {
  return authRegisterServiceSupplier(dataIn).then(
    (data: any) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch({
        type: UPDATE_SIGNUP_STATE,
        payload: {
          signUpLoading: true,
          signUpEmail: dataIn.data.authparams.supplier_email,
          signUpPwd: dataIn.data.authparams.password,
        },
      });
      return Promise.resolve(data);
    },
    (error: any) => {
      const message = formatErrorMessage(error);
      dispatch({
        type: REGISTER_FAIL,
      });
      dispatch(setPageLoading({ loading: false, loadingText: "" }));
      dispatch({
        type: SET_MESSAGE,
        payload: { case: ERROR_MSG, data: message },
      });
      return Promise.reject(message);
    }
  );
};

export const loginSupplier =
  (username: string, password: string, rfqUUD?: string, soUUID?: string) =>
  (dispatch: any) => {
    return authLoginServiceSupplier(username, password, rfqUUD, soUUID).then(
      (data: any) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data.data },
        });
        if (data.message === "No App")
          dispatch({
            type: SET_MESSAGE,
            payload: { case: LOGIN_SUCCESS, data: "Logged In !" },
          });
        return Promise.resolve(data);
      },
      (error: any) => {
        const message = formatErrorMessage(error);

        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: { case: LOGIN_FAIL, data: message },
        });

        return Promise.reject();
      }
    );
  };

export const logoutSupplier = () => (dispatch: any) => {
  return authLogoutServiceSupplier()
    .then((data: any) => {
      dispatch({
        type: LOGOUT,
      });
      localStorage.clear();
      sessionStorage.clear();

      window.location.reload();
      history.push("/login");
    })
    .catch((error: any) => {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          case: ERROR_MSG,
          data: "Failed to log out " + formatErrorMessage(error),
        },
      });
    });
};
