import React, { FC } from "react";

type InputType = "text" | "date" | "checkbox" | "select" | "number";
type InputLyout = "column-break" | "section-break";
interface FormProps {
  label: string;
  type: InputType;
  value: string | boolean | number;
  options?: string[];
  onChange: (value: string | boolean | number) => void;
  formArray: any[];
}
export const GenericForm = ({
  label,
  type,
  value,
  options,
  onChange,
  formArray,
}: FormProps) => {
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (type === "checkbox") {
      onChange(e.target.checked);
    } else if (type === "select") {
      onChange(e.target.value);
    } else {
      onChange(e.target.value);
    }
  };

  const renderInput = () => {
    switch (type) {
      case "text":
      case "date":
      case "number":
        return (
          <input
            type={type}
            value={value as string}
            onChange={handleInputChange}
          />
        );
      case "checkbox":
        return (
          <input
            type="checkbox"
            checked={value as boolean}
            onChange={handleInputChange}
          />
        );
      case "select":
        return (
          <select value={value as string} onChange={handleInputChange}>
            {options?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };
  return <div></div>;
};
