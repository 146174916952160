import * as React from "react";
import { Box, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { DarkButton } from "@karkhanaui/react";

interface supplierCard {
  heading: string;
  list: string[];
  img: string;
}
export const SupplierLibraryCard = ({ heading, list, img }: supplierCard) => {
  return (
    <Flex flexDirection="column" bg="#fff" w="18.775rem" h="27.5rem" alignItems="center">
      <Text textStyle="primary.header" fontSize="18">
        {heading}
      </Text>
      <UnorderedList>
        {list &&
          list.map((item, index) => (
            <ListItem key={index}>
              <Text textStyle="primary.paragraph" fontSize="13">
                {" "}
                {item}
              </Text>
            </ListItem>
          ))}
      </UnorderedList>
      <DarkButton children="GO TO SUPPLIER LIBRARY" />
    </Flex>
  );
};
