import axios from "axios";

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

export const authRegisterServiceSupplier = (data: any) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/supplierv2.api.auth.create_supplier_signup",
    data
  );
};

export const authLoginServiceSupplier = (
  username: string,
  password: string,
  rfqUUID?: string,
  soUUID?: string
) => {
  return axios.post(
    process.env.REACT_APP_AUTH_LIVE_URL +
      "/api/method/supplierv2.api.auth.login",
    JSON.stringify({
      payload: {
        usr: username,
        pwd: password,
        rfq_uuid: rfqUUID,
        po_uuid: soUUID,
      },
    }),
    config
  );
};

export const authLogoutServiceSupplier = () => {
  return axios
    .post(process.env.REACT_APP_AUTH_LIVE_URL + "/api/method/logout")
    .then((response) => {
      return response.data;
    });
};

export const fetchSupplierData = (data: string) => {
  return axios.get(`/api/resource/Supplier/${data}`);
};

export const updateSupplierData = (data) => {
  return axios.put(
    "/api/resource/Supplier/" + localStorage.getItem("user_email"),
    data
  );
};

export const logout2 = () => {
  return axios
    .post("/api/method/logout")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};

export const testPassword = (data) => {
  return axios.post(
    "/api/method/frappe.core.doctype.user.user.test_password_strength",
    data
  );
};

export const changePassword = (data) => {
  return axios.post(
    `/api/method/supplierv2.api.auth.change_password?user=${data.user}&current_password=${data.current_password}&new_password=${data.new_password}`
  );
};

export const resetPasswordRequestSupplier = (
  email: string,
  rfq_uuid?: string,
  SO_UUID?: string
) => {
  if (rfq_uuid) {
    return axios.post(
      process.env.REACT_APP_AUTH_LIVE_URL +
        `/api/method/supplierv2.api.auth.reset_password?email=${email}&rfq_uuid=${rfq_uuid}`
    );
  } else if (SO_UUID) {
    return axios.post(
      process.env.REACT_APP_AUTH_LIVE_URL +
        `/api/method/supplierv2.api.auth.reset_password?email=${email}&po_uuid=${SO_UUID}`
    );
  } else {
    return axios.post(
      process.env.REACT_APP_AUTH_LIVE_URL +
        `/api/method/supplierv2.api.auth.reset_password?email=${email}`
    );
  }
};

export const supplierResetPasswordService = (key: string, pwd: string) => {
  return axios.post(
    `/api/method/supplierv2.api.auth.set_new_password?reset_key=${key}&new_password=${pwd}`
  );
};
