import { Box, Skeleton, Stack, Text, useTheme } from "@chakra-ui/react";
import { isNull } from "lodash";
import * as React from "react";
import { IconType } from "react-icons";

interface OrderStatusCardProps {
  title: string;
  subTitle: string;
  Icon: IconType;
  iconColor: string;
}

export const OrderStatusCard = ({
  title,
  subTitle,
  Icon,
  iconColor,
}: OrderStatusCardProps) => {
  const theme = useTheme();

  return (
    <Stack
      bg="#fff"
      p="20px"
      direction="row"
      alignItems="center"
      borderRadius="8px"
    >
      <Stack>
        {title !== undefined && <Text textStyle="primary.title">{title}</Text>}
        {title == undefined && <Skeleton height="20px" />}
        <Text textStyle="primary.secondaryText">{subTitle}</Text>
      </Stack>
      <Box
        style={{ marginLeft: "auto" }}
        bg={`${iconColor}.100`}
        p="20px"
        borderRadius="6px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon color={iconColor} size="32px" />
      </Box>
    </Stack>
  );
};
