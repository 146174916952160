import axios from "axios";

export const trackUpdateService = async (params: any) => {
  return (
    await axios.get(
      process.env.REACT_APP_AUTH_LIVE_URL + "/api/resource/Item Updates",
      {
        params: {
          filters: JSON.stringify(params.filters),
          fields: params.fields ? JSON.stringify(params.fields) : '"*"',
        },
      }
    )
  );
};
