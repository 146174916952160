import * as React from "react";
import {
  Flex,
  Box,
  Text,
  Input,
  Select,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ThemeButton,
  AppDispatch,
  SET_MESSAGE,
  ERROR_MSG,
  formatErrorMessage,
} from "@karkhanaui/react";
import { BsPaperclip } from "react-icons/bs";
import {
  writeToUsService,
  getAllQuote,
  getAllSalesOrderService,
} from "@karkhanaui/react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
// import "./help.css"
interface orderDetails {
  // quoteId: any[];
  // orderId: any[];
}

export const WriteToUs = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [tableRowList, setTableRowList] = React.useState([]);
  const [tableRowList2, setTableRowList2] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [orderList, setOrderList] = React.useState([]);
  const [quoteList, setQuoteList] = React.useState([]);
  const onDrop = React.useCallback((acceptedFiles: any) => {
    setFileName(acceptedFiles[0].path);
    // props.updateFile(acceptedFiles, "Karkhana");
  }, []);
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: props.isMultiple,
  });
  const transformToTableData = ({ data }: { data: any }) => {
    // TODO: Create Owner Image
    return data?.map((each: any) => ({
      quoteName: (
        <Text
          width="100px"
          m="auto"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign="center"
        >
          {" "}
          {each.opportunity_nick_name
            ? each.opportunity_nick_name
            : each.name}{" "}
        </Text>
      ),
    }));
  };
  const transformToTableData2 = ({ data }: { data: any }) => {
    // TODO: Create Owner Image
    return data?.map((each: any) => ({
      Name: (
        <Text
          width="100px"
          m="auto"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign="center"
        >
          {" "}
          {each.name}{" "}
        </Text>
      ),
    }));
  };
  React.useEffect(() => {
    getAllQuote({})
      .then((s) => {
        setQuoteList(s.data.data);
        setTableRowList(transformToTableData(s.data));
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            case: ERROR_MSG,
            data: formatErrorMessage(error),
          },
        });
      });
    getAllSalesOrderService({
      filters: [],
      limit_start: 1,
    })
      .then((s) => {
        setOrderList(s.data.data);
        setTableRowList2(transformToTableData2(s.data));
      })
      .catch((error) => {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            case: ERROR_MSG,
            data: formatErrorMessage(error),
          },
        });
      });
  }, []);
  const [subText, setSubText] = React.useState("");
  const [messageText, setMessageText] = React.useState("");
  const [orderId, setOrderId] = React.useState("");
  const [quoteId, setQuoteId] = React.useState("");
  const [submitted, setSubmitted] = React.useState(true);
  // const {open} = useDropzone();
  const { onClose } = useDisclosure();
  const submit = async (value: any) => {
    if (subText != "" && messageText != "") {
      await writeToUsService({
        subject: subText,
        order_id: orderId,
        quote_id: quoteId,
        messages: messageText,
      })
        .then((res) => {
          toast.success("Successfully submitted!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSubmitted(true);
          const timestamp = Date.now();
          props.submit({
            subText,
            messageText,
            quoteId,
            orderId,
            submitted,
            timestamp,
          });
          props.click();
        })
        .catch((err) => {
          setSubmitted(false);

          dispatch({
            type: SET_MESSAGE,
            payload: {
              case: ERROR_MSG,
              data: "Error submitting the message" + formatErrorMessage(err),
            },
          });
        });
    } else {
      await dispatch({
        type: SET_MESSAGE,
        payload: {
          case: ERROR_MSG,
          data: "Please fill the mandatory fields!!",
        },
      });
    }
  };

  return (
    <Flex
      m="8px"
      padding="2rem"
      // height="669px"
      flexDirection="column"
      bg="#fff"
      borderRadius="lg"
    >
      <Text textStyle="primary.header">Write to us</Text>
      <Box mt="0.5rem">
        <Text display="flex" textStyle="primary.header" fontSize="16px">
          Subject {<div style={{ color: "red" }}>*</div>}
        </Text>
        <Input
          outline="none"
          variant="filled"
          value={subText}
          onChange={(e) => setSubText(e.currentTarget.value)}
        />
        <Flex flexDirection="row" gap="8rem" mt="2rem">
          <Box>
            <Text textStyle="primary.header" fontSize="16px">
              Quote Id
            </Text>
            {tableRowList && (
              <Select
                minHeight="2.5rem"
                display="flex"
                placeholder="Quote id"
                position="relative"
                backgroundColor="#edf2f7"
                width=" 12rem"
                onChange={(e: any) => setQuoteId(e.target.value)}
              >
                {quoteList.map((item: any, index: number) => {
                  return (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
            )}

            {/* <Input
              outline="none"
              variant="filled"
              value={quoteId}
              onChange={(e) => setQuoteId(e.currentTarget.value)}
              p ={3}
              
              
            /> */}
          </Box>
          <Box>
            <Text textStyle="primary.header" fontSize="16px">
              Order Id
            </Text>
            {tableRowList2 && (
              <Select
                minHeight="2.5rem"
                display="flex"
                placeholder="Order id"
                position="relative"
                backgroundColor="#edf2f7"
                onChange={(e: any) => setOrderId(e.target.value)}
              >
                {orderList.map((item: any, index: number) => {
                  return (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
            )}
            {/* <Input
              outline="none"
              variant="filled"
              value={orderId}
              onChange={(e) => setOrderId(e.currentTarget.value)}
              
            /> */}
          </Box>
        </Flex>
        <Box mt="2rem">
          <Text display="flex" textStyle="primary.header" fontSize="16px">
            Messages {<div style={{ color: "red" }}>*</div>}
          </Text>
          <Textarea
            resize="none"
            height="179px"
            placeholder=" "
            variant="filled"
            size="lg"
            value={messageText}
            onChange={(e) => setMessageText(e.currentTarget.value)}
          />
        </Box>
        <Flex flexDirection="row" mt="2rem" justifyContent="space-between">
          <ThemeButton
            leftIcon={<BsPaperclip />}
            children="Attach files"
            variant="filled"
            border="none"
            bg="#fff"
            onClick={open}
          />

          <input {...getInputProps({})} />

          <div
            style={{
              fontSize: " 13px",
              margin: "auto",
              marginLeft: "2%",
              width: "-webkit-fill-available",
            }}
          >
            {fileName}
          </div>
          {/* </Flex>
          </Flex> */}

          {/* <Button
              children="Discard"
              bg="#fff"
              color="gray"
              border="none"
              mr="1rem"
            /> */}
          <ThemeButton width="30%" children="Send to us" onClick={submit} />
        </Flex>
      </Box>
      <ToastContainer />
    </Flex>
  );
};
