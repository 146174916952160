import * as React from "react";
import { Box, Text, Flex, Image } from "@chakra-ui/react";

interface fileAnalyzingProps {
  imgURL: string;
}
export const FileAnalyzing = ({ imgURL }: fileAnalyzingProps) => {
  return (
    <Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gap="1rem"
        alignItems="center"
        marginBottom="2.75rem"
      >
        <Image src={imgURL} alt="Analyzing" width="300px" height="300px" />

        <Text textStyle="primary.title" color="#0087EE" mt="1.5rem">
          Analyzing Files
        </Text>
        <Text textStyle="primary.large" textAlign="center">
          We are going through your files and generating the prices accordingly
        </Text>
      </Flex>
    </Box>
  );
};
