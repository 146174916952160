import * as React from "react";
import * as OV from "online-3d-viewer";
import { Box } from "@chakra-ui/react";

interface ThreedViewerProps {
  modelURLs: string[];
  externalLibLocation?: string;
}

export const ThreedViewer = ({
  modelURLs,
  externalLibLocation,
}: ThreedViewerProps) => {
  React.useEffect(() => {
    const el = document.getElementById("threed-viewer");
    if (el) {
      el.innerHTML = "";
      const viewer = new OV.EmbeddedViewer(el, {});
      OV.SetExternalLibLocation(externalLibLocation || "libs");
      viewer.LoadModelFromUrlList(modelURLs);
    }
  }, [modelURLs]);

  return <Box id="threed-viewer" height="100%" width="100%"></Box>;
};
