import axios from "axios";

export const supplierGetOpportunityService = (data: any) => {
  return axios.get("/api/resource/Opportunity", { params: data.params });
};

export const supplierGetSingleOpportunityService = (opportunityId: string) => {
  return axios.get(`/api/resource/Opportunity/${opportunityId}`);
};

export const supplierUpdateOpportunityService = (
  opportunityId: string,
  params: any
) => {
  return axios.put(`/api/resource/Opportunity/${opportunityId}`, params);
};
