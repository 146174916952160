
import axios from 'axios';

export const shareRfqTeamService =  async(emails:any, rfqId:any) => {
	return  axios.get(process.env.REACT_APP_AUTH_LIVE_URL + 
		`/procurement.api.document_sharing.share_rfq`,{
			params : {
				payload : {
					email_list : emails,
					rfq_id : rfqId
				}
			}
		}
			
		)
}

