import axios from "axios";

export const uploadRegularFileServiceProcure = async (params: any) => {
	const data = new FormData();
  
	for (var key in params) {
	  data.append(key, params[key]);
	}
  
	return (
	  await axios.post(
		process.env.REACT_APP_AUTH_LIVE_URL + "/api/method/upload_file",
		data
	  )
	);
  };