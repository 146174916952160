import { combineReducers } from "redux";
import { auth } from "./auth";
import { authProcure } from "./authProcurement";
import { authSupplier, signUpState } from "./authSupplier";
import { message, apiLoading, pageLoading } from "./messages";
import { savePart, saveRFQ, getQuote } from "./quote";
import { getUser } from "./user";

export default combineReducers({
  auth,
  message,
  apiLoading,
  pageLoading,
  savePart,
  saveRFQ,
  getUser,
  getQuote,
  authProcure,
  authSupplier,
  signUpState,
});
