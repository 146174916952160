import * as React from "react";
import {Box,Flex, useTheme} from '@chakra-ui/react';

export const ColorBox = ({colors}: {colors: string[]}) => {

	const theme = useTheme()

	return (
	<Flex m="20px">
		{
		colors?.map(each => { return <Box
			w="80px"
			h="80px" 
			borderRadius="20px"  
			mx="10px"
			key={each}
			bg={each} /> })
		}
	</Flex>
	)
}
