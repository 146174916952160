import React, { useEffect } from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface paginationProps {
  count: number;
  start: number;
  pageCount: number;
  pageLength: number;
  onPageChange: any;
}
export const Pagination = ({
  count,
  pageLength,
  start,
  pageCount,
  onPageChange,
}: paginationProps) => {
  let no_of_pages = Array(pageCount).fill(0);
  const [curPage, setCurPage] = React.useState<number>(1);

  useEffect(() => {
    onPageChange(curPage);
  }, [curPage]);

  const changePage = (action: string) => {
    if (action === "next") {
      if (curPage < pageCount) {
        setCurPage(curPage + 1);
      }
    } else {
      if (curPage > 1) {
        setCurPage(curPage - 1);
      }
    }
  };

  return (
    <Box width="100%">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="14px"
        gap="1rem"
        flexWrap="wrap"
      >
        <Text textStyle="primary.bold">
          Showing {start + 1} to{" "}
          {start + pageLength + 1 > count ? count : start + pageLength} of{" "}
          {count} entries
        </Text>
        <Flex>
          <Flex
            bg="#fff"
            borderRadius="6px"
            width="28px"
            height="28px"
            alignItems="center"
            justifyContent="center"
            boxShadow="md"
            mr="5px"
            cursor="pointer"
            onClick={() => changePage("prev")}
          >
            <FiChevronLeft />
          </Flex>
          <Flex width="165px" overflowX="scroll">
            {no_of_pages.map((_: any, index: number) => {
              return (
                <Flex
                  key={index}
                  bg={index + 1 === curPage ? "#00A3FF" : ""}
                  borderRadius="6px"
                  minWidth="28px"
                  height="28px"
                  alignItems="center"
                  justifyContent="center"
                  mr="5px"
                  cursor="pointer"
                  onClick={() => setCurPage(index + 1)}
                >
                  <Text
                    textStyle="primary.secondaryText"
                    fontSize="md"
                    color={index + 1 === curPage ? "#fff" : ""}
                  >
                    {index + 1}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          <Flex
            bg="#fff"
            borderRadius="6px"
            width="28px"
            height="28px"
            alignItems="center"
            justifyContent="center"
            boxShadow="md"
            cursor="pointer"
            onClick={() => changePage("next")}
          >
            <FiChevronRight />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
