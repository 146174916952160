import * as React from "react";
import { Grid, GridItem, Box, Text, Flex } from "@chakra-ui/react";
import { Button } from "@karkhanaui/button";

export const AddressCard = ({
  title,
  data,
  editAddress,
  deleteAddressPrompt,
}: any) => {
  return (
    <Box border="1px dashed #E4E6EF" borderRadius="6px">
      <Box margin="25px">
        <Text textStyle="primary.contentTitle" fontWeight="extrabold">
          {title}
        </Text>
        <Box mt="14px">
          <Grid templateColumns={{ md: "repeat(1,1fr)", lg: "repeat(3,1fr)" }}>
            <GridItem colSpan={{ lg: 2 }}>
              <Box mb="1rem">
                <Text textStyle="primary.largeSecondaryText">{data}</Text>
              </Box>
            </GridItem>
            <GridItem>
              <Flex gap="1rem" alignItems="center" justifyContent="flex-end">
                <Text
                  textStyle="primary.secondaryText"
                  cursor="pointer"
                  padding="5px"
                  fontWeight="extrabold"
                  onClick={deleteAddressPrompt}
                >
                  Delete
                </Text>
                <Button children="Edit" onClick={editAddress} colorScheme="dark" />
              </Flex>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
