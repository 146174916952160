import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { getFileType } from "@karkhanaui/react";
interface fileProps {
  file_name: string;
  file_url: any;
}
export const ViewFile = ({ file_name, file_url }: fileProps) => {
  function renderFiles() {
    switch (getFileType(file_name)) {
      case "pdf":
        return (
          <iframe
            srcDoc={file_url}
            width="100%"
            height="500"
            title={file_name}
          />
        );

      case "image":
        return <Image src={file_url} width="100%" height="auto" />;

      case "spreadSheet":
        return (
          <iframe
            srcDoc={file_url}
            width="100%"
            height="500"
            title={file_name}
          />
        );

      case "CAD_File":
        return (
          <iframe
            srcDoc={file_url}
            title={file_name}
            width="100%"
            height="100%"
          />
        );
      default:
        break;
    }
  }
  return <Box>{renderFiles()}</Box>;
};
