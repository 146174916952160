import { lazy } from "react";
import ContractsMain from "../pages/Contracts/ContractsMain";
import { Supplier_Records } from "../pages/suppliers/Supplier_Records";
const Home = lazy(() => import("../pages/Home/Home"));
const PurchaseOrder = lazy(
	() => import("../pages/PurchaseOrder/PurchaseMainTable")
);
const TrackOrder = lazy(() => import("../pages/PurchaseOrder/TrackOrder"));
const BuyerAnalytics = lazy(() => import("../pages/analytics/BuyerAnalytics"));
const SupplierAnalytics = lazy(
	() => import("../pages/analytics/SupplierAnalytics")
);
const AddPurchaseOrder = lazy(
	() => import("../pages/PurchaseOrder/AddPurchaseOrder")
);

const PurchaseSummary = lazy(
	() => import("../pages/PurchaseOrder/PurchaseOrderSummary")
);

const ItemsMain = lazy(() => import("../pages/ItemsLibrary/ItemsMain"));
const Supplier = lazy(() => import("../pages/suppliers/SupplierTable"));
const AddSupplier = lazy(() => import("../pages/suppliers/Supplier_form"));
const ItemFormPreview = lazy(
	() => import("../pages/ItemsLibrary/ItemFormPreview")
);
const ComingSoonnC = lazy(() => import("../pages/Contracts/ComingSoon"));
const File = lazy(() => import("../pages/Contracts/File"));
const Contracts = lazy(() => import("../pages/Contracts/ContractsTable"));
const NDA = lazy(() => import("../pages/quote/NDA"));
const RfqMainTable = lazy(() => import("../pages/Rfq/RfqMainTable"));
const RfqPreview = lazy(() => import("../pages/Rfq/RfqPreview"));
const CompareQuotes = lazy(() => import("../pages/Rfq/CompareQuotes"));
const AddSupplierItems = lazy(() => import("../pages/Rfq/AddSuppliersItems"));
const AddDetails = lazy(() => import("../pages/Rfq/AddDetails"));
const AddRFQDetails = lazy(() => import("../pages/Rfq/AddRFQDetails"));
const Review_Submit = lazy(() => import("../pages/Rfq/Review&Submit"));
const SupplierDetails = lazy(() => import("../pages/Rfq/AddSuppliersItems"));
const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const InstantQuote = lazy(() => import("../pages/instantQuote/InstantQuote"));
const Help = lazy(() => import("../pages/help/Help"));
const Profile = lazy(() => import("../pages/Settings/MainSettings"));
const RfqMain = lazy(
	() => import("../pages/instantQuote/NewAutoQuote/RfqMain")
);
const PartConfigure = lazy(
	() => import("../pages/instantQuote/ConfigurePart/PartConfigure")
);
const Checkout = lazy(() => import("../pages/instantQuote/Checkout/Checkout"));
const Orders = lazy(() => import("../pages/orders/Orders"));
const OrderSummary = lazy(() => import("../pages/orders/OrderSummary"));
const Quotes = lazy(() => import("../pages/Rfq/QuoteMain"));
const ItemsLibrary = lazy(() => import("../pages/quote/ItemsLibrary"));
const NotFound = lazy(() => import("../pages/error/NotFound"));
const ShareRfq = lazy(() => import("../pages/Rfq/ShareRfq/ShareRfq"));
const SupplierAI = lazy(() => import("../pages/SupplierAI/SupplierAI"));

const RoutesList: Array<{
	label: string;
	link: string;
	type: "public" | "private";
	component: typeof ForgotPassword;
}> = [
	//access pages
	{
		label: "Login",
		link: "/login",
		type: "public",
		component: Login,
	},
	{
		label: "Register",
		link: "/register",
		type: "public",
		component: Register,
	},
	{
		label: "ForgotPassword",
		link: "/forgotPassword",
		type: "public",
		component: ForgotPassword,
	},
	{
		label: "ResetPassword",
		link: "/resetPassword/:changePasswordKey",
		type: "public",
		component: ResetPassword,
	},
	// {
	// 	label: "ResetPassword",
	// 	link: "/resetPassword/:changePasswordKey",
	// 	type: "public",
	// 	component: ResetPassword,
	// },
	//InstantQuote
	{
		label: "InstantQuote",
		link: "/instantQuote",
		type: "private",
		component: InstantQuote,
	},
	//RFQ
	{
		label: "RFQ",
		link: "/instantQuote/newQuote/:rfq_id",
		type: "private",
		component: RfqMain,
	},
	{
		label: "Part Configure",
		link: "/instantQuote/newQuote/:rfq_id/configure/:part_Id",
		type: "private",
		component: PartConfigure,
	},
	{
		label: "Checkout",
		link: "/instantQuote/newQuote/:rfq_id/checkout",
		type: "private",
		component: Checkout,
	},
	//Quotes
	{
		label: "Quotes",
		link: "/rfqpreview/:rfq_id/quotes/:supplier_id",
		type: "private",
		component: Quotes,
	},
	{
		label: "Items Library",
		link: "/itemsLibrary",
		type: "private",
		component: ItemsLibrary,
	},
	// Orders
	{
		label: "Orders",
		link: "/orders",
		type: "private",
		component: Orders,
	},
	{
		label: "Order Summary",
		link: "/orders/:order_id/orderSummary",
		type: "private",
		component: OrderSummary,
	},
	{
		label: "Profile",
		link: "/profile",
		type: "private",
		component: Profile,
	},

	//Error
	{
		label: "Error",
		link: "/notFound",
		type: "private",
		component: NotFound,
	},
	{
		label: "Help",
		link: "/help",
		type: "private",
		component: Help,
	},
	{
		label: "Home",
		link: "/home",
		type: "private",
		component: Home,
	},
	{
		label: "CreateContract",
		link: "/createcontract",
		type: "private",
		component: File,
	},
	{
		label: "Contract",
		link: "/contract",
		type: "private",
		component: ContractsMain,
	},
	// procurement Items-library
	{
		label: "ItemsLibrary",
		link: "/items",
		type: "private",
		component: ItemsMain,
	},

	{
		label: "Rfq",
		link: "/rfqmain",
		type: "private",
		component: RfqMainTable,
	},
	{
		label: "RfqPreview",
		link: "/rfqpreview/:rfqId",
		type: "private",
		component: RfqPreview,
	},
	{
		label: "RfqDetails",
		link: "/addrfqdetails/:id",
		type: "private",
		component: AddRFQDetails,
	},
	{
		label: "Review and Submit",
		link: "/review&submit/:rfqId",
		type: "private",
		component: Review_Submit,
	},
	{
		label: "Supplier",
		link: "/supplier",
		type: "private",
		component: Supplier,
	},
	{
		label: "AddSupplier",
		link: "/addsupplier",
		type: "private",
		component: AddSupplier,
	},
	// {
	// 	label: "PurchaseTable",
	// 	link: "/purchaseorder",
	// 	type: "private",
	// 	component: PurchaseOrder,
	// },
	{
		label: "PurchaseOrder",
		link: "/addpurchase/:id",
		type: "private",
		component: AddPurchaseOrder,
	},
	{
		label: "PurchaseOrder",
		link: "/createpo/:rfqid/:supplierid",
		type: "private",
		component: AddPurchaseOrder,
	},

	// {
	// 	label: "PurchaseOrderPage",
	// 	link: "/purchasepage",
	// 	type: "private",
	// 	component: PurchaseOrderPage,
	// },
	{
		label: "PurchaseOrder",
		link: "/purchasepage",
		type: "private",
		component: PurchaseOrder,
	},
	{
		label: "CompareQuotes",
		link: "/compareQuotes/:rfqId",
		type: "private",
		component: CompareQuotes,
	},
	{
		label: "AddDetails",
		link: "/addDetails/:rfqId",
		type: "private",
		component: AddDetails,
	},
	{
		label: "ShareRfq",
		link: "/shareRfq/:rfqId",
		type: "private",
		component: ShareRfq,
	},
	{
		label: "AddSupplierItems",
		link: "/selectsupplier",
		type: "private",
		component: AddSupplierItems,
	},
	{
		label: "Suppliers",
		link: "/supplierrecords/:smail/:sMailId",
		type: "private",
		component: Supplier_Records,
	},
	{
		label: "ItemFormPreview",
		link: "/item/:itemId",
		type: "private",
		component: ItemFormPreview,
	},
	{
		label: "ComingSoon",
		link: "/comingsoonC",
		type: "private",
		component: ComingSoonnC,
	},
	{
		label: "TrackOrder",
		link: "/trackorder/:id",
		type: "private",
		component: TrackOrder,
	},
	{
		label: "PurchaseSummary",
		link: "/purchasesummary/:id",
		type: "private",
		component: PurchaseSummary,
	},
	{
		label: "Analytics",
		link: "/buyerAnalytics",
		type: "private",
		component: BuyerAnalytics,
	},
	{
		label: "Analytics",
		link: "/supplierAnalytics",
		type: "private",
		component: SupplierAnalytics,
	},
	{
		label: "SupplierSage AI",
		link: "/rfq-matching/:rfq-id?/:item-id?/:gen-query?",
		type: "private",
		component: SupplierAI,
	},
];

export default RoutesList;
