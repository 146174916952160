import * as React from "react";
import { Box, HStack, Image, Text, Flex, Spacer, color } from "@chakra-ui/react";
import { TbClipboardText  } from 'react-icons/tb';
import { BsPeople, BsFileText, BsClipboard } from 'react-icons/bs';

interface overview {
  cardData: Array<any>;
  heading: string;
  subHeading: string;
  cardType?:Array<any>;
  color?:any;
}

export const OverviewCard = ({ cardData, heading, subHeading, cardType, color }: overview) => {
  const cards = [{title: "RFQs Sent", icon: <BsFileText size="100%" color="rgba(0, 135, 238, 1)"/>}, {title: "Quote Received", icon : <BsClipboard size="100%" color="rgba(0, 135, 238, 1)"/>}, {title: "Total Suppliers", icon : <BsPeople size="100%" color="rgba(0, 135, 238, 1)"/>}]
  return (
    <HStack
      borderRadius="lg"
      minW="0"
      width="100%"
      height="100%"
      bg="white"
      spacing="1rem"
      boxShadow="md"
      padding="2em 1.5em"
      overflowX="auto"
      overflowY="hidden"
      fontFamily="rubik"
    >
      <Flex justifyContent={"space-between"} width="100%" gap="1rem" alignItems="flex-start">
        <Flex flexDirection="column" ml="0" justifyContent="start" height="100%">
          <Text
            textAlign="left"
            fontWeight="semibold"
            fontSize="1rem"
            color={ color ? color.fontColor : "#0087EE" }
            mt="0"
          >
            {heading}
          </Text>
          <Text
            textAlign="left"
            textStyle="primary.thin"
            fontSize="0.875rem"
            color="black"
            mt="0"
          >
            {subHeading}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" gap="1em">
          {cardData && cardData.map((item, index) => (
              <Box key={index} width="7.5rem" height="7.5rem" position="relative" padding="0.5em 0.8em" display="flex" flexDirection="column" justifyContent="center" alignItems="start" borderRadius="lg" bgColor={ color ? color.bgColor : "rgba(0, 135, 238, 0.1)" }>
                <Text fontSize="0.75rem" marginTop="1.625rem">{cardType? cardType[index].title : cards[index].title}</Text>
                <Text fontSize="2.215rem" margin="0" fontWeight="600" color={ color ? color.fontColor : "blue.500" } >{item.value}</Text>
                <Box position="absolute" top="-1.375rem" left="0.938rem" bgColor="white" width="2.813rem" padding="0.5em" height="2.813rem" border={color ? color.bgColor : "0.5px solid rgba(0, 135, 238, 0.6)"} borderRadius="lg">
                  {cardType? cardType[index].icon : cards[index].icon}
                </Box>
              </Box>
            ))}
        </Flex>
      </Flex>
    </HStack>
  );
};
