import * as React from "react";
import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { ThemeButton, Button } from "@karkhanaui/react";
import { BiDownload } from "react-icons/bi";
import Karkhana from "../../../images/karkhana-logo-1.png";
import kio from "../../../images/k.svg";
interface confirmOrderFields {
  title: string;
  orderId?: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  type: string;
  primaryButtonFunction: any;
  secondaryButtonFunction: any;
}
export const ConfirmOrder = ({
  title,
  orderId,
  primaryButtonText,
  secondaryButtonText,
  type,
  primaryButtonFunction,
  secondaryButtonFunction,
}: confirmOrderFields) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mb="1.5rem"
    >
      <Image
        src={Karkhana}
        width="12.75rem"
        mt="2.5rem"
        alt="Karkhana logo"
        height="auto"
      />
      <Image src={kio} width="13.75rem" height="13.75rem" mt="2rem" />

      <Text textStyle="primary.header" mt="2rem" textAlign="center">
        {title}
      </Text>
      {type === "ordered with PO" && (
        <Text mt="10px">Will update the Order Id soon!</Text>
      )}
      {orderId && (
        <Flex alignItems="center" mt="10px">
          <Text textStyle="primary.bold">
            {type === "ordered with card" ? "Order " : "RFQ "}ID: &nbsp;
          </Text>
          <Text textStyle="primary.text">{orderId}</Text>
        </Flex>
      )}
      <ThemeButton mt="1.5rem" onClick={primaryButtonFunction} width="13.75rem">
        <Flex alignItems="center" justifyContent="center" gap="10px">
          {type !== "ordered with PO" && <BiDownload size="20px" />}
          {primaryButtonText}
        </Flex>
      </ThemeButton>
      <Button
        width="13.75rem"
        children={secondaryButtonText}
        mt="1rem"
        variant="ghost"
        border="1px solid #0087EE"
        color="#0087EE"
        _hover={{ bg: "#fff" }}
        onClick={secondaryButtonFunction}
      />
    </Flex>
  );
};
