import {
  Flex,
  Image,
  Box,
  FlexProps,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Divider,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { GrHomeRounded } from "react-icons/gr";
import {
  BsFolder2Open,
  BsBell,
  BsFillPersonFill,
  BsFileText,
  BsFiles,
  BsFileEarmarkMedical,
  BsCardHeading,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { BiShare, BiHelpCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  AppDispatch,
  PageLoading,
  NotificationCard,
  getUserDetails,
  frappeMethodService,
  ERROR_MSG,
  formatErrorMessage,
  SET_MESSAGE,
  BasicDialog,
} from "@karkhanaui/react";
import { BsSearch } from "react-icons/bs";
interface IconTextProps extends FlexProps {
  Icon: typeof GrHomeRounded;
  text: string;
  active?: boolean;
  link: string;
}

const IconText = ({
  Icon,
  text,
  link,
  active = false,
  ...rest
}: IconTextProps) => {
  if (active) {
    return (
      <Flex
        justifyContent="start"
        alignItems="center"
        {...rest}
        my="5px"
        borderRadius={"10px"}
        bg={"#000"}
        as={Link}
        to={link}
      >
        <Icon size="28px" color="#fff" />
        <Box ml="11px">
          <Text textStyle="primary.text" color="#fff" fontWeight={"600"}>
            {text}
          </Text>
        </Box>
      </Flex>
    );
  }
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      {...rest}
      my="5px"
      borderRadius={"10px"}
      as={Link}
      to={link}
    >
      <Icon size="28px" />
      <Box ml="11px">
        <Text textStyle="primary.text">{text}</Text>
      </Box>
    </Flex>
  );
};

export const AppShell = ({
  children: Component,
  imageURL,
  pageLoaderURL,
  notifications,
  bread,
  navigateToDoc,
}: {
  children?: React.LazyExoticComponent<React.FC<any>>;
  imageURL: string;
  pageLoaderURL: string;
  notifications: any[];
  bread: any;
  navigateToDoc: Function;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  let pathname = useLocation()?.pathname.split("/");
  const navigate = useNavigate();
  const [pageLoadingData, userData] = useSelector((state: any) => [
    state.pageLoading,
    state.getUser.customer,
  ]);

  const [activeTab, setActiveTab] = React.useState<String>("/" + pathname[1]);

  const [searchValue, setSearchvalue] = React.useState<any>("");
  const [searchContent, setSearchContent] = React.useState<any>([]);
  const [showSearch, setShowSearch] = React.useState<any>(false);

  useEffect(() => {
    setActiveTabBackground();
  }, [pathname[1]]);

  const setActiveTabBackground = () => {
    setActiveTab("/" + pathname[1]);
  };

  useEffect(() => {
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: window.location.pathname,
    });
  }, [window.location]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const menuItems = [
    {
      icon: BsFileText,
      text: "Instant Quote",
      link: "/instantQuote",
    },
    {
      icon: BsFiles,
      text: "Quotes",
      link: "/quotes",
    },
    {
      icon: BsFileEarmarkMedical,
      text: "Orders",
      link: "/orders",
    },
    {
      icon: BsFolder2Open,
      text: "Items Library",
      link: "/itemsLibrary",
    },
    {
      icon: BsCardHeading,
      text: "Contracts",
      link: "/contracts",
    },
    {
      icon: BiHelpCircle,
      text: "Help",
      link: "/help",
    },
  ];

  const handleSignout = () => {
    dispatch(logout());
  };

  const callGlobalSearch = async () => {
    let data: any = {
      search: searchValue,
    };
    await frappeMethodService(
      "customer_portal_v2.api.global_search.global_search",
      data
    ).then(
      (res: any) => {
        let arr = res.data?.message.filter((el: any) =>
          ["Opportunity", "Sales order"].includes(el.doctype)
        );
        setSearchContent(arr);
        return Promise.resolve(res);
      },
      (error) => {
        const message = "Search failed. " + formatErrorMessage(error);
        dispatch({
          type: SET_MESSAGE,
          payload: {
            case: ERROR_MSG,
            data: message,
          },
        });
        return Promise.reject(message);
      }
    );
  };

  const redirectFn = ({ name, type }: any) => {
    setShowSearch(false);
    if (type === "Opportunity") navigate(`/instantQuote/newQuote/${name}`);
    else navigate(`/orders/${name}/orderSummary`);
  };

  const listContent = (type: any) => {
    return (
      <Box>
        {searchContent?.filter((el: any) => el.doctype === type).length > 0 && (
          <Text fontSize="md" fontWeight="500">
            {type}
          </Text>
        )}
        <Box ml={5} mt={2}>
          {searchContent
            ?.filter((el: any) => el.doctype === type)
            ?.map((el: any, i: any) => {
              return (
                <Text
                  key={i}
                  fontSize="xs"
                  lineHeight={7}
                  onClick={() => redirectFn({ name: el.name, type })}
                  cursor="pointer"
                  _hover={{
                    textDecoration: "underline",
                    color: "blue",
                  }}
                >
                  {el.name}
                </Text>
              );
            })}
        </Box>
      </Box>
    );
  };

  return (
    <Flex>
      <Flex
        w="20%"
        flexShrink="0"
        flexDirection="column"
        h="calc(100vh)"
        justifyContent="space-between"
      >
        <Flex
          flexDirection="column"
          w="80%"
          mx="auto"
          mt="10px"
          justifyContent="space-between"
        >
          <Image w="100%" src={imageURL} mb="6.8125rem" mt=".8rem" />
          {menuItems.map((item) => {
            return (
              <IconText
                key={item.text}
                Icon={item.icon}
                text={item.text}
                padding="14.5px"
                active={activeTab === item.link ? true : false}
                link={item.link}
                onClick={() => setActiveTab(item.link)}
              />
            );
          })}
        </Flex>
      </Flex>{" "}
      {/* Sidebar */}
      <Flex direction="column" w="80%">
        <Box h="65px">
          <Flex
            h="100%"
            alignItems="center"
            justifyContent="space-between"
            padding="10px"
          >
            {bread}
            <Flex direction="row" justifyContent="center" alignItems="center">
              <Stack spacing="6" direction="row" mr="60px">
                <BsSearch
                  size="20px"
                  cursor="pointer"
                  onClick={() => setShowSearch(true)}
                />
                <Popover isLazy size="4xl" variant="responsive">
                  <PopoverTrigger>
                    <span>
                      <BsBell size="20px" cursor="pointer" />
                    </span>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody p="0px">
                      <NotificationCard
                        notificationList={notifications}
                        navigate={navigateToDoc}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Stack>
              <Box>
                <Stack direction="row" alignItems="center">
                  <Box textAlign={"right"}>
                    <Text textStyle="primary.smallTitle">
                      Hi, {userData.customer}
                    </Text>
                  </Box>
                  <Menu isLazy={true}>
                    <MenuButton transition="all 0.2s">
                      {/* <Image
                        src="https://picsum.photos/50/50"
                        alt="profile image"
                        rounded="full"
                        w="36px"
                        h="36px"
                      /> */}
                      <BsFillPersonFill
                        size={30}
                        color="#babcbe"
                        cursor="pointer"
                      />
                    </MenuButton>
                    <MenuList zIndex={91000}>
                      <MenuItem
                        icon={<IoPersonOutline />}
                        onClick={() => {
                          setActiveTab("");
                          navigate("/profile");
                        }}
                      >
                        <Text textStyle="primary.smallTitle">My Profile</Text>
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem icon={<BiShare />} onClick={handleSignout}>
                        <Text textStyle="primary.smallTitle">Sign Out</Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>
              </Box>
            </Flex>
          </Flex>
        </Box>
        {/* dialog  starts*/}
        <BasicDialog
          isOpen={showSearch}
          onClose={() => setShowSearch(false)}
          showCloseButton={true}
          header="Search"
          content={
            <Box mb={10}>
              <Input
                placeholder="Search"
                fontSize="sm"
                value={searchValue}
                onChange={(e) => setSearchvalue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    callGlobalSearch();
                  }
                }}
              />

              <Box maxH="50vh" overflowY="auto" mt={2}>
                {searchContent.length === 0 ? (
                  <Text> Modify search</Text>
                ) : (
                  <Tabs
                    mt={15}
                    isLazy
                    isFitted
                    index={activeTab}
                    onChange={(index) => setActiveTab(index)}
                    colorScheme="kio"
                    orientation="vertical"
                  >
                    <TabList border="none" h="15vh">
                      {["All Results", "Opportunity", "Sales order"].map(
                        (el, i) => {
                          return (
                            <Tab
                              key={i}
                              fontSize={{ xs: "xs", lg: "md" }}
                              _focus={{ boxShadow: "none" }}
                              _selected={{
                                bg: "#CAE9F5",
                                color: "#1E3F66",
                                borderRadius: "6px",
                              }}
                              justifyContent="flex-start"
                              mb={2}
                            >
                              {el}
                            </Tab>
                          );
                        }
                      )}
                    </TabList>
                    <TabPanels>
                      <TabPanel key={0}>
                        {listContent("Opportunity")}
                        <Divider my={2} />
                        {listContent("Sales order")}
                      </TabPanel>
                      <TabPanel key={1}>{listContent("Opportunity")}</TabPanel>
                      <TabPanel key={2}>{listContent("Sales order")}</TabPanel>
                    </TabPanels>
                  </Tabs>
                )}
              </Box>
            </Box>
          }
        />
        {/* dialog ends */}
        <Flex
          bg="bg"
          flexGrow="1"
          // padding="1rem"
          height="60vh"
          overflowY="scroll"
        >
          {!!Component && <Component />}
        </Flex>
      </Flex>
      {pageLoadingData?.loading && (
        <PageLoading
          pageLoading={pageLoadingData}
          pageLoaderURL={pageLoaderURL}
        />
      )}
    </Flex>
  );
};
