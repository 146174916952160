import axios from 'axios'

export const updateQuoteStatusService =  (quoteID: string, data: any) => {

	return axios.put(
	  process.env.REACT_APP_AUTH_LIVE_URL +
		"/api/resource/Supplier%20Quotation/" +
		quoteID,
	  data
	);
}

export const createManualQuotation = (data:any)=>{
	return axios.post(
		process.env.REACT_APP_AUTH_LIVE_URL +
		"/api/resource/Supplier%20Quotation/",
		data
	);
}

